$baseurl: "";
.nordicos-text {
    font-family: "Quintessential", cursive !important;
}

.history-test-seminary-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 5px;
    flex-wrap: wrap;
    position: relative;

    .texts-container {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .title-container {
            display: flex;
            align-items: center;
            gap: 10px;

            img {
                width: 0.875rem;
                height: 0.875rem;
            }

            h3 {
                font-size: 0.875rem !important;
                font-weight: 500 !important;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;

                img {
                    cursor: pointer;
                }

                // span title styles for name should be here
                .quiz-name {
                    font-weight: 500 !important;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-width: 200px;
                }
            }

            span {
                font-size: 0.75rem !important;
                font-weight: 300 !important;
                margin: 0;
            }
        }

        .info-test-row {
            p {
                margin: 0px;
                font-size: 0.875rem !important;
                font-weight: 300 !important;

                .in-progress {
                    color: #fe9a12;
                }

                .completed {
                    color: #02a377;
                }
            }
        }

        .details {
            p {
                color: #ca5050;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    .buttons-container {
        display: flex;
        gap: 10px;
        align-items: center;

        button {
            padding: 7px;
            font-size: 0.75rem !important;
            height: auto;
            width: 125px;
            max-width: 125px;
        }

        .primary-btn {
            background: linear-gradient(90.31deg, #ee1c34 0.26%, #500b28 99.73%);
            color: #fff;
        }

        .secondary-btn {
            background-color: white;
            color: #ca5050;
            border: 1px solid #ca5050;
        }
    }

    .block-card-quiz {
        position: absolute;
        width: 100%;
        height: calc(100% + 5px);
        background-color: #000000a1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 5px;
        padding: 0px 10px;

        .block-quiz-title {
            font-size: 1rem !important;
            color: white;
            font-weight: 400 !important;
            margin: 0;
        }

        button {
            padding: 7px;
            font-size: 0.75rem !important;
            height: auto;
            width: 150px;
            max-width: 150px;
        }

        .primary-btn {
            background: linear-gradient(90.31deg, #ee1c34 0.26%, #500b28 99.73%);
            color: #fff;
        }

        img {
            filter: invert(96%) sepia(96%) saturate(0%) hue-rotate(57deg) brightness(104%) contrast(107%);
        }
    }
}

.details-seminary-vocabulary-modal {
    .modal-dialog {
        max-width: 750px;
        .modal-content {
            border-radius: 10px;

            .modal-header {
                flex-direction: column-reverse;
                align-items: flex-end;
                gap: 15px;
                @media (max-width: 1000px) {
                    gap: 10px;
                }
                .header-container {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;

                    .title {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;

                        div {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            gap: 10px;

                            input {
                                width: 260px;
                                border: none;
                                font-size: 1.5rem;
                                font-weight: bolder;
                            }

                            h2 {
                                font-size: 1.5rem !important;
                                font-weight: bolder !important;
                                margin: 0;
                            }
                        }
                        p {
                            font-size: 0.875rem !important;
                            margin: 0;
                        }
                    }

                    .result {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: flex-start;
                        margin-left: auto;

                        h2 {
                            font-size: 2rem !important;
                            color: #ca5050;
                            margin: 0;
                            font-weight: bolder !important;
                        }

                        p {
                            font-size: 0.875rem !important;
                            font-weight: bolder !important;
                            margin: 0;
                        }
                    }
                }
            }

            .modal-body {
                display: flex;
                flex-direction: column;
                gap: 20px;

                .title {
                    h2 {
                        font-size: 1rem !important;
                        font-weight: bolder !important;
                        margin: 0;
                    }
                }

                .history-progress-word-row {
                    h3 {
                        font-size: 0.875rem !important;
                        font-weight: 400 !important;
                        margin: 0;
                    }

                    .words-progress-bar-container {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        gap: 10px;

                        p {
                            margin: 0;
                            font-size: 0.875rem !important;
                            font-weight: 400 !important;
                        }

                        .words-progress-bar {
                            background: #ffffff;
                            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
                            border-radius: 100px;
                            height: 10px;
                            flex: 1;

                            .words-progress {
                                background: linear-gradient(90.31deg, #ee1c34 0.26%, #500b28 99.73%);
                                border-radius: 100px;
                                height: 100%;
                                width: 50%;
                            }
                        }
                    }
                }

                .words-to-learn-container {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .title {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;

                        .title-container {
                        }

                        .see-more-controller {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            img {
                                width: 1.25rem;
                                height: 1.25rem;
                                cursor: pointer;
                            }

                            .left-arrow {
                                transform: rotate(180deg);
                                filter: invert(69%) sepia(0%) saturate(1149%) hue-rotate(171deg) brightness(87%)
                                    contrast(83%);
                                cursor: initial;
                            }

                            p {
                                margin: 0;
                                font-size: 0.875rem !important;
                            }
                        }
                    }

                    .words-container {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 20px;
                        margin-bottom: auto;
                        flex-direction: column;
                        align-items: center;

                        .word-container {
                            display: flex;
                            border-radius: 10px;
                            padding: 15px;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;
                            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);

                            .text-container {
                                display: flex;
                                width: 100%;
                            }

                            p {
                                margin: 0;
                                font-size: 1.5rem !important;
                                margin: 0;
                                font-weight: 400 !important;
                                color: #00000080;

                                span {
                                    color: #ca5050;
                                    margin-right: 10px;
                                }
                            }

                            .word-to-study-container,
                            .answer-word-container {
                                max-width: 390px;
                            }

                            .word-to-study-container {
                                flex: 1;
                                border-right: 2px solid #00000080;
                                margin-right: 30px;
                            }

                            .answer-word-container {
                                flex: 1;
                            }

                            .listen-word-container {
                                img {
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }

            .modal-footer {
                button {
                    border: 1px solid #ca5050;
                    color: #ca5050;
                    background-color: #ffffff;
                    padding: 10px 20px;
                    border-radius: 10px;
                }
            }
        }
    }
}

@media (max-width: 550px) {
    .details-seminary-vocabulary-modal {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    .words-to-learn-container {
                        .words-container {
                            .word-container {
                                // flex-direction: column;
                                // gap: 10px;

                                p {
                                    text-align: center;
                                    font-size: 1rem !important;
                                }

                                .word-to-study-container {
                                    border: none;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .history-test-seminary-card {
        flex-direction: column;
        gap: 10px;
        margin: auto;
        width: 100%;

        .texts-container {
            width: 100%;
        }

        .buttons-container {
            width: 100%;
            justify-content: space-between;

            .primary-btn {
                margin-left: auto;
            }
        }
    }
}
