$baseurl: "";
.videos-upload-main-container {
    .header {
        h2 {
            font-size: 2rem;
        }
    }

    .fs-12 {
        font-size: 0.75rem !important;
    }

    .upload-form-container {
        form {
            * {
                margin: 0;
            }

            display: flex;
            flex-direction: column;
            gap: 20px;

            .file-upload-container {
                label {
                    border-radius: 10px;
                    cursor: pointer;
                    text-align: center;
                    width: 100%;
                    max-width: none;
                }

                input {
                    opacity: 0;
                    position: absolute;
                    z-index: -1000;
                }
            }

            .loading-upload-container {
                p,
                h3 {
                    font-size: 1rem !important;
                }

                h3 {
                    color: #ca5050;
                }
            }
        }
    }

    .uploaded-videos-actions-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;

        .refresh-btn {
            padding: 0;
            margin: 0;
            width: 3rem;
            height: 3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);

            img {
                width: 1.5rem;
            }
        }

        .refresh-btn:active {
            box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.25);
        }
    }

    .uploaded-videos-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-top: 40px;

        .video-card {
            position: relative;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
            margin-bottom: 20px;
            flex-wrap: wrap;
            .upload-date {
                font-size: 0.9rem !important;
                margin-bottom: 0 !important;
                text-align: end;
            }
            h3 {
                font-size: 1.25rem !important;
                font-weight: bolder !important;
            }

            .top-content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .video-gear {
                    width: 3vw;
                    margin-right: 1vw;
                }
                .video-status-container {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    margin-bottom: 5px;
                    margin-left: auto;
                    h3 {
                        margin: 0;
                    }

                    .status-info-container {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 10px;

                        h3 {
                            font-size: 1rem !important;
                            font-weight: normal !important;
                        }

                        .status-color {
                            width: 1rem;
                            height: 1rem;
                            border-radius: 1rem;
                        }
                    }
                    .action-btn {
                        min-width: auto;
                        padding: 5px;
                    }

                    .convertir-btn {
                        font-size: 1rem !important;
                        width: 100%;
                        min-width: 120px;
                        min-height: auto;
                        padding: 5px;
                    }
                }
            }

            .footer {
                box-shadow: inset 0px 1px 10px rgba(0, 0, 0, 0.25);
                padding: 2px 5px;
                border-radius: 5px;

                h3 {
                    font-size: 1rem !important;
                    font-weight: normal !important;
                    margin: 0;
                }
            }
        }
    }
}
