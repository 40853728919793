$baseurl: "";
.vocabulary-card {
    width: 100%;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;

    .vocabulary-card-main-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        cursor: pointer;

        .card-info-container {
            display: flex;
            align-items: center;
            gap: 10px;

            .image-container {
                width: 64px;
                min-width: 64px;
                height: 64px;
                position: relative;
                border-radius: 100px;
                overflow: hidden;
                box-shadow: rgba(50, 50, 93, 0.25) -5px 2px 4px 0px inset;

                img {
                    height: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .name-and-progress-container {
                h3 {
                    font-size: 1.5rem !important;
                    color: #ca5050;
                    margin: auto;
                }

                p {
                    font-size: 1rem !important;
                    margin: 0;
                }
            }
        }

        .percentage-container {
            .next-class-text,
            .percentage-of-seminary-text {
                font-size: 1rem !important;
                color: #ca5050;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 5px;
            }

            .percentage-of-seminary-text {
                justify-content: flex-end;
                padding: 10px;
            }
        }
    }

    .footer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0px;

        a {
            color: #ca5050;
            text-decoration: none;

            p {
                margin: 0;
                font-size: 0.875rem !important;
            }
        }
    }
}

.block {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #00000099;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 10px;
    padding: 0px 20px;

    .message-block-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
            text-align: center;
        }
    }

    .enroll-btn-container {
        button {
            padding: 10px 20px;
        }
    }

    .fill {
        width: 100%;
        max-width: 150px;
    }

    .fill2 {
        width: 33%;
        max-width: 307px;
    }

    p {
        color: white;
        font-size: 1rem !important;
        margin: 0;
        max-width: 310px;
    }
}

@media (max-width: 575px) {
    .vocabulary-card-block .block {
        .message-block-container {
            img {
                width: 32px;
                height: 32px;
            }
        }

        .enroll-btn-container {
            button {
                font-size: 1rem !important;
            }
        }

        .fill,
        .fill2 {
            width: auto;
        }
    }
}

@media (max-width: 991px) {
    .vocabulary-card {
        .vocabulary-card-main-info {
            .percentage-container {
                display: flex;
                flex-direction: column;
                align-items: center;

                h3 {
                    text-align: center;
                }
            }
        }

        .footer {
            padding: 10px 0px 20px 0px;
        }
    }
}
