$baseurl: "";

.new-card-form-component {
  display: grid;
//grid-template-columns: repeat(2, 1fr);
  gap: 50px;

 
  .stripe-elements-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .input-container {
      .input-element-parent {
        padding: 15px;
        border-radius: 5px;
        border: 1px solid #ced4da;
      }
      .is-invalid {
        padding: 15px;
        border-radius: 5px;
        border: 1px solid red;
      }
    }
    .card-complement {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
  }
  .floating-label-input {
    select {
      border-color: #c1bbbb;
    }
  }
  .svg-col {
    svg {
      width: 30px;
      height: 30px;
      color: #3483fa;
    }
  }
 
.cards-icons {
	display: flex;
	align-items: flex-end;
	justify-content: space-evenly;
	width: 50%;
	height: 30px;
	align-self: flex-end;
	margin-top: 30px;
}

.icon {
	width: 50px;
}

.regularButton {
    border: 1px solid #f5f5f5;
    color: #3754DE;
    box-shadow: 0px 4px 20px #00000010;
    background-color: transparent;
    padding-inline: 1.5rem;
    padding-block: .7rem;
    &:hover {
        background-color: #ca5050;
        color: #fff;
    }
}
}
