$baseurl: "";
@import "@theme/mixin.scss";

.home-lang-card {
  border-radius: 12px;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  transform: scale(1);
  width: 264px;
  transition: 0.25s ease transform;

  .content-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bottom-rectangle {
    overflow: hidden;
    width: 100%;
    max-height: 0px;
    flex: 1;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    transition: 0.25s ease max-height;

    span {
      font-size: 38px !important;
      font-family: "inter black";
      font-weight: bolder;
      text-transform: uppercase;
      font-size: 80px;
      font-weight: 700;
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      background-repeat: no-repeat;
      background-size: cover;
      background-size: calc(100% * 1.15);
      background-position: center bottom;
      position: relative;
      color: rgba(0, 0, 0, 0.5);
    }

    span.description {
      font-size: 14px !important;
    }
  }

  &:hover {
    transform: scale(1.15);
    box-shadow: 0 4px 20px #0000001f;
    .bottom-rectangle {
      max-height: 115px;
    }
  }

  @include respond-to(md) {
    width: 158px;
    box-shadow: 0 4px 20px #0000001f;

    &:hover {
      transform: none;
    }

    .content-container {

      svg {
        width: 80%;
      }
    }
    .bottom-rectangle {
      max-height: 115px;

      span {
        font-size: 20px !important;
      }

      span.description {
        font-size: 12px !important;
        text-align: center;
      }
    }
  }
}

.home-lang-card::before {
  content: "";
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -2;
}

.home-lang-card.english::before,
.home-lang-card.english span {
  background-image: url("./images/english-bg.png");
}
.home-lang-card.french::before,
.home-lang-card.french span {
  background-image: url("./images/french-bg.png");
}
.home-lang-card.german::before,
.home-lang-card.german span {
  background-image: url("./images/german-bg.png");
}
.home-lang-card.japanese::before,
.home-lang-card.japanese span {
  background-image: url("./images/japanese-bg.png");
}
.home-lang-card.russian::before,
.home-lang-card.russian span {
  background-image: url("./images/russian-bg.png");
}
.home-lang-card.italian::before,
.home-lang-card.italian span {
  background-image: url("./images/italian-bg.png");
}
.home-lang-card.chinese::before,
.home-lang-card.chinese span {
  background-image: url("./images/chinese-bg.png");
}
.home-lang-card.portuguese::before,
.home-lang-card.portuguese span {
  background-image: url("./images/portuguese-bg.png");
}

.home-lang-card:hover::after {
  content: "";
  background-color: #000000;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  opacity: 0.5;
}

.home-lang-card {
  @include respond-to(md) {
    &:after {
      content: "";
      background-color: #000000;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: -1;
      opacity: 0.5;
    }
  }
}
