$baseurl: "";
@import "theme/colors", "theme/variables";

.header__kids {
  background: $color-primary;
  padding: $spacing-2x 0 2px 0;
  color: $color-white !important;

  h1 {
    color: $color-white;
  }

  .description {
    margin-top: $spacing;
    p {
      font-size: 20px;
    }
  }
}

.section__kids {
  margin-top: $spacing-4x * 1.94 !important;
  margin-bottom: $spacing-4x !important;
  // .modules__navbar {
  //   margin-bottom: $spacing;
  //   .nav-link {
  //     margin-right: $spacing-2x;
  //   }
  // }
}
