$baseurl: "";
.pagination-component {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0px;

  > * {
    margin: 0px;
    padding: 0px;
  }

  button.pagination-action-btn {
    font-size: .875rem !important;
    padding: 5px 10px;
    background: linear-gradient(to right, #ee1c34, #500b28);
    color: #ffffff;

    &:disabled {
      background: linear-gradient(to right, #e87b8881, #57304078);
    }
  }
}