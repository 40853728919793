$baseurl: "";
@import "theme/colors", "theme/variables";

.header__testimonials {
  padding: $spacing-2x 0;
  background: $color-primary;
  color: $color-white !important;

  h1 {
    color: $color-white !important;
  }
  p {
    margin-top: $spacing;
  }
}

.react-multi-carousel-list {
  align-items: center;
  display: flex;
  overflow: hidden;
  position: relative;

  .react-multi-carousel-track {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    will-change: transform, transition;
  }
}
.carousel__container {
  margin-top: $spacing-4x !important;
  .section__two {
    margin-top: $spacing-2x;
    margin-bottom: $spacing-4x;
    h3 {
      font-weight: $font-weight-regular !important;
    }
    .card__1 {
      left: 200px;
    }
    .card__2 {
      left: 100px;
    }
    .card {
      margin-bottom: $spacing;
    }
    .image__container {
      position: absolute;
      bottom: 0;
    }
    .image__container-xs img {
      display: none;
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1144px) {
  .section__two {
    .row {
      .card__0,
      .card__1,
      .card__2 {
        left: 0;
      }
    }
  }
}

@media screen and (min-width: 350px) and (max-width: 992px) {
  .section__two {
    .row {
      .card__0,
      .card__1,
      .card__2 {
        width: auto !important;
        left: 0;
      }
    }
  }
}

@media screen and (max-width: 1320px) {
  .section__two {
    .row {
      .image__container {
        display: none;
      }
      .image__container-xs {
        width: 100%;
        img {
          display: block;
          float: right;
          clear: right;
        }
      }
    }
  }
}
