$baseurl: "";
@import "theme/colors", "theme/variables";

.redbox__container {
  background-color: #bc4e4e;
  width: 212px;
  height: 150px;
  color: #ffffff;
  text-align: center;
  position: absolute;
  border-radius: 14px;
  right: 0;
  z-index: -1;
  margin-right: 16px;
  margin-top: 10px;

  h3 {
    margin-top: $spacing-2x + 10px;
    color: $color-white;
  }
}

.redbox-sm__container {
  background-color: #bc4e4e;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  color: #ffffff;
  text-align: center;
  position: absolute;
  border-radius: 14px;
  z-index: -1;

  h3 {
    margin-top: $spacing-3x;
    color: $color-white;
  }
}
