.denied-reason-message-container {
    width: calc(100% - 65px);
    margin-left: auto;
    margin-top: 10px;
}

.buttons-container-request-card {
    width: calc(100% - 65px);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: auto;
    gap: 20px;
    flex-direction: column;
}

.buttons-container-request-card button {
    padding: 10px;
    width: auto;
    height: auto;
    font-size: 1rem !important;
    font-weight: 500;
    min-width: 90px;
    background-color: transparent;
    border: 2px solid #ca5050;
    color: #ca5050;
    
}

.buttons-container-request-card button.btn-accept-request {
    color: #02a377;
    border: 2px solid #02a377;
}
.buttons-container-request-card button.btn-archived-request {
    color: #C99B17;
    border: 2px solid #C99B17;
}
p.realizacion-date span{
    font-weight: 700 !important;
}
.see-profile-btn {
    margin: 0;
    padding: 0;
    padding: 10px 20px;
    background-color: white;
    border: 3px solid #2f84cf;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2f84cf;
    font-weight: 700;
}
p.m-0{
    font-weight: 700 !important;
}
.select-modalidad {
    padding: 5px;
    border-radius: 10px;
    width: 100%;
    max-width: 200px;
}

@media (max-width: 621px) {
    .buttons-container-request-card {
        width: calc(100% - 65px);
        display: flex;
        margin-left: auto;
    }
    .buttons-container-request-card button {
        padding: 10px;
        width: auto;
        height: auto;
        font-size: 1rem !important;
        font-weight: 500;
        min-width: 90px;
        background-color: transparent;
        border: 2px solid #ca5050;
        color: #ca5050;
    }
    .buttons-container-request-card button.btn-accept-request {
        color: #02a377;
        border: 2px solid #02a377;
        margin-left: 0px;
    }
    .see-profile-btn {
        margin: 0;
        padding: 0;
        padding: 35px 20px;
        background-color: white;
        border: 3px solid #2f84cf;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #2f84cf;
        font-weight: 700;
        flex-direction: column;
    }
}

@media (max-width: 501px) {
    .buttons-container-request-card {
        width: calc(100% - 65px);
        display: flex;
        flex-direction: column;
        margin-left: 25%;
        max-width: 180px;
    }
    .buttons-container-request-card button {
        padding: 10px;
        width: auto;
        height: auto;
        font-size: 1rem !important;
        font-weight: 500;
        min-width: 90px;
        background-color: transparent;
        border: 2px solid #ca5050;
        color: #ca5050;
        min-width: 180px;
    }
    .buttons-container-request-card button.btn-accept-request {
        color: #02a377;
        border: 2px solid #02a377;
        margin-left: 0;
    }
    .see-profile-btn {
        justify-content: center;
        align-items: flex-end;
        margin: 0;
        padding: 0;
        padding: 35px 20px;
        background-color: white;
        border: 3px solid #2f84cf;
        display: flex;
        align-items: center;
        color: #2f84cf;
        font-weight: 700;
        flex-direction: column-reverse;
        margin-left: 25%;
        width: max-content;
    }

    .payment-history-card-first-section {
        flex-direction: column-reverse;
    }

    .payment-history-card-first-section .payment-history-left-content-card-container {
        display: flex;
        width: 100%;
        justify-content: center;
        flex-direction: column;
    }
}
.highlighted {
    border: 2px solid #ca5050;
}

