$baseurl: "";
.word-container-word-row-card {
	display: flex;
	border-radius: 10px;
	padding: 15px;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
	gap: 10px;

	* {
		font-family: "Montserrat" !important;
	}

	.text-container {
		justify-content: space-between;
		display: flex;
		width: 100%;
	}

	p {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		font-size: 1.5rem !important;
		margin: 0;
		font-weight: 400 !important;
		color: #00000080;
		text-align: center;

		br {
			display: none;
		}

		span {
			color: #ca5050;
			display: flex;
			//gap: 10px;
		}
	}

	.word-to-study-container,
	.answer-word-container {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.word-to-study-container {
		flex: 1;
		border-right: 2px solid #00000080;
		margin-right: 30px;
		p span{
			align-items: center;
			flex-wrap: wrap;
			justify-content: center;
			white-space: pre-wrap !important;
			margin-bottom:0;
		}
	}

	.answer-word-container {
		flex: 1;
	}

	.listen-word-container {
		width: min-content;
		.wrench{
			width:1.5rem;
		}
		img {
			cursor: pointer;
			width:min-content;
		}
	}
}

@media (max-width: 500px) {
	.word-container-word-row-card {
		padding: 15px 5px;
		.word-to-study-container {
			margin: 0px;
		}
		p {
			span {
				margin: 0;
			}
			font-size: 1rem !important;
			flex-wrap: wrap;
			white-space: wrap;
		}
	}
}
