$baseurl: "";
.experience-form-container {

    .row {
        margin: 0;
    }
    .radio-containers {
        .form-check {
            padding-left: 0px;
            display: flex !important;
            width: fit-content;
            align-items: center;
            gap: 5px;

            input[type="radio"] {
                position: initial;
                margin: 0;
                padding: 0;
                transform: none;
            }
            label {
                position: initial;
            }
        }
    }

    .radio-containers.row-container {
        display: flex;
        justify-content: space-around;
        width: 100%;
        gap: 18px;

        .form-check {
            flex-direction: column-reverse;
        }
    }

    .time-of-study-row-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        width: 100%;

        .input-container {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;

            label {
                margin: 0;
                min-width: auto!important;
            }

            input {
                flex: 2;
                text-align: center;
                max-width: 80px;
            }
        }
    }
}
