$baseurl: "";
.rcm-dialog {
	min-width: 320px;
	.rcm-body {
		.form-grid {
			display: grid;
			row-gap: 15px;
			p > span {
				font-weight: bold;
			}
			.input-group {
				display: flex;
				flex-direction: column;
				label {
					margin-bottom: 0;
				}
			}
			textarea {
				min-height: 5rem;
				max-height: 20rem;
				padding: 5px;
				border-radius: 5px;
				resize: vertical;
			}
			.input-file-group{
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				
				.input-file{
					display: flex;
					//justify-content: flex-start;
					gap: 33px;
					justify-content: center;
				}
			}

			@media (max-width: 600px) {
				textarea {
					max-width: 20rem;
				}
				input {
					max-width: 20rem;
				}
			}

			.buttons-container {
				display: flex;
				justify-content: space-evenly;
				button {
					max-width: 50px;
					padding: 0;
					max-width: fit-content;
					padding: 0 15px 0 15px;
				}
				.send-btn {
					background: linear-gradient(90.31deg, #ee1c34 0.26%, #500b28 99.73%);
					color: white;
				}
			}
		}
	}
}
