$baseurl: "";
@import 'theme/colors', 'theme/variables';

.card__item {
  height: 80%;
  margin-bottom: $spacing-2x;
  display: flex;
  padding: $spacing;
  .first__col {
    margin-top: auto;
    margin-bottom: auto;
  }
}
