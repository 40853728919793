$baseurl: "";
@import "theme/colors", "theme/variables";

.header__red {
  button {
    width: 100%;
    height: 100%;
    border-radius: 0px !important;
    text-transform: uppercase;
    cursor: default !important;
    span {
      font-size: $font-size-h1 - 10px !important;
      font-weight: $font-weight-medium;
    }
  }
}
