$baseurl: "";
.table{
    .head{
        .date{
            width: 120px;
            div{
                cursor:pointer;
            }
            
            
        }
        .seminary{
            text-align:center;
            width: 130px;
            div{
                cursor:pointer;
            }
        }
        .state{
            text-align: center;
            div{
                cursor:pointer;
            }
        }
        .word{
            white-space: pre;
            div{
                cursor:pointer;
            }
        }
        .comment{
            text-align: center;
        }
        .language{
            text-align:center;
            div{
                cursor:pointer;
            }
        }
    }
    .body{
        tr{
            border:none;

            .date{
            }
            .seminary{
                text-align: center;
            }
            .check{
                text-align:center;
                display:flex;
                justify-content: space-evenly;
                align-items: center;
                input{
                    scale: 1.5;
                }
                .trash-icon:hover{
                    cursor:pointer;
                }
            }
            
            .word{
                width:1px;
                white-space:nowrap;
            }
            .language{
                white-space:nowrap;
                text-align: center;
                img {
                    width: 20px;
                }
            }
           
            .actions {
                img, svg {
                    cursor: pointer;
                }
                img {
                    margin-right: 10px;
                }
            }
        }
        tr:hover {background-color: rgb(233, 233, 233);}

    
    }
}