$baseurl: "";
@import "reset", "colors", "variables", "typography", "fontface";
@import "_variables.scss";
@import "~bootstrap/scss/bootstrap";

.container {
  width: 100%;
  //max-width: 1600px !important;
  max-width: 100% !important;
  padding: 0 54px;
  margin: 0 auto;

  &.component-mainsection {
    padding-top: 45px;
  }

  @media (max-width: 600px) {
    padding-left: 15px !important;
    padding-right: 15px !important;

    &.component-mainsection {
      padding-top: 20px;
    }
  }
}

.patillas-font {
  font-family: "Times New Roman", Times, serif !important;
}

.goback-btn {
  cursor: pointer;
  width: fit-content;
  padding: 0px;
  height: auto;
  font-size: 1rem !important;
  padding: 5px 20px;
  background-color: #ffffff;
  color: #ca5050;
  border-radius: 10px;
  border: 1px solid #ca5050;
  margin-bottom: 20px;
}

.action-btn {
  padding: 0px;
  height: auto;
  font-size: 1rem !important;
  padding: 10px;
  width: 100%;
  max-width: 200px;
  min-width: 200px;
  color: #ffffff;
}

.linear-bg {
  background: linear-gradient(to right, #ee1c34, #500b28);

  &:disabled {
    background: linear-gradient(to right, #e87b8881, #57304078);
  }
}

.secondary-btn-bg {
  background-color: #ffffff;
  border: 1px solid #bc4e4e;
  color: #bc4e4e;
}
.disabled-btn {
  background: none !important;
  background-color: #c7c7c7 !important;
}

.words-progress-bar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  .words-progress-bar {
    background: #ffffff;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    height: 10px;
    flex: 1;

    .words-progress {
      background: linear-gradient(90.31deg, #ee1c34 0.26%, #500b28 99.73%);
      border-radius: 100px;
      height: 100%;
      width: 50%;
    }
  }

  .dominated {
    .words-progress {
      background: linear-gradient(to right, #efc900, #d6a21e);
    }
  }
}

section {
  margin-top: $spacing;
}

button {
  border: 0;
  border-radius: 7px !important;
  font-size: 22px !important;
  padding: 0px 55px;
  cursor: pointer;
  transition: all 0.3s ease 0s;

  &:disabled {
    cursor: default;
  }
  &.secondary {
    background: $color-primary;
    color: white;
    height: 55px;
    font-weight: $font-weight-medium;
  }
  &.tertiary {
    background: $color-white;
    color: $color-secondary;
    border: 1px solid $color-secondary;
    height: 55px !important;
    font-weight: $font-weight-medium;
  }
}

.button2 {
  padding: 0px 12px !important;
  border: none;
  outline: none;
  transform: translate(0);
  //background-image: linear-gradient(45deg, $color-left, $color-right);
  box-shadow: 0 22px 44px rgba(gray, 0.1);
  transition: box-shadow 0.25s;
  .text {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(45deg, $color-left, $color-right);
  }
  &:after {
    content: "";
    border-radius: 7px;
    position: absolute;
    margin: 1px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    background: white;
  }
  &:hover {
    //background-image: linear-gradient(-45deg, $color-left, $color-right);
    box-shadow: 0 12px 24px rgba(gray, 0.1);
    .text {
      background-image: linear-gradient(-45deg, $color-left, $color-right);
    }
  }
}

a {
  &.primary {
    //color: $color-primary;
    background: $color-primary;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    &:active {
      background: red;
    }
  }

  &.secondary {
    background: $color-primary;
    color: white;
  }
}

// @media (min-width: 1200px) {
//   .container,
//   .container-lg,
//   .container-md,
//   .container-sm,
//   .container-xl {
//     max-width: 1400px !important;
//   }
// }

.wave__container {
  display: inline-block;
  position: absolute;
  width: 100%;
  padding-bottom: 60%;
  vertical-align: middle;
  overflow: hidden;
  top: -4px;
  left: 0;

  img {
    display: inline-block;
    position: absolute;
    top: 150px;
    width: 100%;
    left: 0;
    z-index: -1000 !important;
  }
}

.wave {
  width: 100%;
  position: absolute;
  height: auto;
}

@media screen and (max-width: 992px) {
  .header__program,
  .header__testimonials,
  .header__FAQ,
  .header__contact,
  .header__enrollment,
  .header__kids {
    margin-top: 0px !important;
  }
}

/*WAVE CONTAINER MEDIA*/
// @media screen and (max-width: 571px) {
//   .wave__container {
//     img {
//       top: 148px;
//     }
//   }
// }

// @media screen and (max-width: 522px) {
//   .wave__container {
//     img {
//       top: 168px;
//     }
//   }
// }

// @media screen and (max-width: 430px) {
//   .wave__container {
//     padding-bottom: 73%;
//     img {
//       top: 193px;
//     }
//   }
//   .wave {
//     height: 7%;
//   }
// }

@media screen and (min-width: 300px) and (max-width: 474px) {
  .wave__container {
    padding-bottom: 76%;
    img {
      top: 177px;
      width: 102%;
    }
  }
}

@media screen and (min-width: 474px) and (max-width: 542px) {
  .wave__container {
    padding-bottom: 76%;
    img {
      top: 160px;
      width: 100%;
    }
  }
}

@media screen and (min-width: 542px) and (max-width: 650px) {
  .wave__container {
    padding-bottom: 76%;
    img {
      top: 148px;
      width: 100%;
    }
  }
}
