$baseurl: "";
#nameContactCard {
	color: #000000;
	font-weight: 700 !important;
	font-size: 1.375rem;
}

.text-payment-history-container .textContactCard {
	font-size: 1rem;
	color: #000000;
	font-weight: 500;
}

.text-payment-history-container .textContactCard.prefered {
	font-weight: 600 !important;
}

.resolved-text {
	color: #808080 !important;
}

.unread-eye {
	filter: invert(50%) sepia(0%) saturate(0%) hue-rotate(231deg) brightness(100%) contrast(84%);
}

.payment-history-card-container-box {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	.payment-history-left-content-card-container {
		flex-direction: column;
		gap: 0;
		.respond-read-container {
			display: flex;
			align-items: center;
            cursor: pointer;
            div > span {
                font-weight: 700;
                font-size: 1.2rem;
            }
            div > img {
                padding-right: 5px;
            }
		}
	}
	.answer-btn {
		margin-left: auto;
	}
}

@media (max-width: 631px) {
	.payment-history-card-container-box {
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
	}
	.text-payment-history-container .textContactCard {
		font-size: 1rem;
		color: #000000;
		font-weight: 500;
	}
	.payment-history-left-content-card-container {
		width: 100%;
		align-self: flex-end;
	}
}

.highlighted {
    border: 2px solid #ca5050;
}
