$baseurl: "";
.generations-subtitle-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
  
    img {
        scale: 1.8;
    }
    .green {
        filter: invert(59%) sepia(38%) saturate(2953%) hue-rotate(76deg) brightness(112%) contrast(121%);
    }
    .orange {
        filter: invert(70%) sepia(25%) saturate(4762%) hue-rotate(358deg) brightness(102%) contrast(108%);
    }
    .blue {
        filter: invert(9%) sepia(88%) saturate(7452%) hue-rotate(247deg) brightness(92%) contrast(148%);
    }
}

.regular-text-header {
    font-size: 1rem !important;
    font-weight: 700 !important;
}

.regular-text {
    font-size: 1rem !important;
    font-weight: 400 !important;
}

.text-bolder {
    font-weight: 800 !important;
    font-family: sans-serif !important;
}

.generations-subtitle-container div p {
    margin: 0;
}

.generations-subtitle-container div button {
    width: auto;
    height: auto;
    padding: 10px 20px;
    background: linear-gradient(to right, #ee1c34, #500b28);
    color: white;
    font-size: 1rem !important;
    font-weight: 500;
}

.emailGeneration {
    width: 190px !important;
    height: auto !important;
    font-size: 1rem !important;
    margin-left: 20px;
    margin-right: 20px;
    color: black !important;
    border-color: rgba(0, 0, 0, 0.329) !important;
}

#emailReport div {
    min-height: 50% !important;
}

#emailReport {
    min-height: 10%;
    left: -33.3%;
    top: 15%;
    width: 100%;
    height: 50%;
}

.generations-card {
    box-shadow: 1px 1px 10px 4px rgba(0, 0, 0, 0.2);
    padding: 25px 20px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.generations-card .generations-card-left h2 {
    font-size: 1.375rem;
    color: #ca5050;
    font-weight: 700;
    margin: 0;
}

.generations-card .generations-card-left p {
    margin: 0;
    font-size: 1rem;
    color: black;
    font-weight: 500;
}

.generations-card-right {
    display: flex;
    align-items: center;
    justify-content: center;
    h3 {
        margin-right: 20px;
        color: #b1b1b1;
        font-family: "Montserrat" !important;
    }
}

.generations-card .generations-card-right button,
.edit-gen-card div button {
    background-color: transparent;
    padding: 10px;
    width: 190px;
    min-width: 135px;
    height: 44px;
    border: 2px solid #ca5050;
    color: #ca5050;
    font-size: 1rem !important;
    font-weight: 500;
}

#emailHeader {
    top: 50px;
    position: relative;
}

.dismiss {
    margin-top: 50%;
    color: #ca5050;
    margin-left: 60%;
    border: 2px solid #ca5050;
    background-color: white;
}

.filteredEmail {
    font-size: 12px !important;
}

.new-gen-modal-input-row{
    margin-bottom: 20px;
}

.input-new-gen-container {
    padding: 10px;
    border: 2px solid rgba(0, 0, 0, 0.329);
    border-radius: 10px;

    select {
        width: 100%;
        border: none;
    }
}

.input-new-gen {
    width: 100%;
    border: none;
}

.setminheightmodal {
    min-height: 408px;
}

.setminheightmodalAddUser {
    min-height: 250px;
}

.edit-gen-card {
    box-shadow: 1px 1px 10px 4px rgba(0, 0, 0, 0.2);
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 30px;
    overflow-x: auto;
    max-height: 85vh !important;
}

.edit-gen-card-inputs-container {
    display: flex;
    gap: 40px;
    margin-bottom: 20px;
    align-items: flex-end;
}
.asueto-row{
    margin-bottom: 20px;
}

.edit-gen-card-inputs-container .inputs-containers {
    width: 30%;
}

.delete-payment-button-modal {
    background-color: #ca5050;
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 0.75rem;
    margin-left: 5px;
    cursor: pointer;
}

div.modal-dialog.student-modal {
    width: 40% !important;
    max-width: 450px !important;
    min-width: 300px !important;
    height: 100% !important;
    margin: 0 !important;
    margin-left: auto !important;
}

.student-modal .modal-content {
    min-height: 100vh;
}

.table-view-btns-container button.active {
    background-color: #ca5050;
    color: white;
}

.gen-search-by-name-input-container {
    border: 1px solid rgba(0, 0, 0, 0.394);
    border-radius: 10px;
    margin-right: 20px;
    width: 190px;
}

.gen-search-by-name-input-container input {
    border: none;
    height: 100%;
    width: 80%;
    border-radius: 10px;
    padding: 10px;
    min-width: 135px;
    margin-left: 15px;
    margin-right: 15px;
}

.gen-students-remaining-spaces-container {
    h3 {
        font-size: 1.5rem !important;
    }
}

.new-generation-modal {
    .modal-dialog {
        .modal-content {
            button {
                padding: 10px 20px;
                height: auto;
                border-radius: 10px;
                font-size: 1rem !important;
            }

            button.secondary-action {
                background-color: #ffffff;
                border: 1px solid #ca5050;
                color: #ca5050;
            }

            button.primary-action {
                background: linear-gradient(to right, #ee1c34, #500b28);
                color: #ffffff;
                border: 1px solid;
            }
            .modal-body {
                p {
                    font-size: 1rem !important;
                }

                .select-input-container {
                    width: 100%;
                    border-radius: 10px;
                    border: 1px solid rgba(0, 0, 0, 0.294);
                    select {
                        width: 100%;
                        padding: 10px 20px;
                        border-radius: 10px;
                        border: none;
                    }
                }
                .generation-type-selector-container {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    gap: 40px;
                    align-items: center;

                    .description {
                        margin: 0;
                    }

                    .types-container {
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                        .online-gen,
                        .in-person-gen {
                            box-shadow: 1px 1px 10px 4px rgba(0, 0, 0, 0.2);
                            border-radius: 10px;
                            padding: 20px;
                            width: 100%;
                            max-width: 250px;
                            cursor: pointer;

                            h3 {
                                text-align: center;
                                margin: 0;
                                font-size: 2rem !important;
                            }
                        }

                        .active {
                            background: linear-gradient(to right, #ee1c34, #500b28);
                            h3 {
                                color: #ffffff;
                            }
                        }
                    }
                }
            }

            .modal-footer {
                display: flex;
                width: 100%;
                justify-content: space-between;
            }
        }
    }
}

.new-students {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    .nuevo-ingreso {
        margin: 5px;
        color: #2d56cf;
    }
    .recursamiento {
        margin: 5px;
        color: #ca5050;
        &:hover {
            cursor: pointer;
        }
    }
    .recursamiento-active {
        margin: 5px;
        color: #ca5050;
        text-decoration: underline;
        &:hover {
            cursor: pointer;
        }
    }
}

@media (max-width: 521px) {
    .table-view-btns-container {
        flex-direction: column;
    }
 
}
