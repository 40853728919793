$baseurl: "";
.android-header-container {
    padding-top: 30px;
    display: flex;
    gap: 10px;
    padding: 0px 7px;

    .image-container {
    }

    .title-container {
        display: flex;
        flex-direction: column;
        gap: 5px;

        h2,
        h3 {
            margin: 0;
        }
    }
}

.android-second-container {
    .download-btn-container {
        margin-top: 20px;

        button {
            padding: 0;
            margin: 0;
            width: 100%;
            background: #00875f;
            color: white;
            font-size: 1rem !important;
        }
    }
}

.android-banner-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: auto;

    .line-divisor {
        width: 1px;
        height: 40px;
        background-color: #8d8f93;
    }

    .caracteristica-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h3 {
            text-align: center;
            margin: 0;
        }

        .small-text {
            color: #8d8f93;
            font-size: .75rem!important;
            font-family: "SF-Compact-Rounded-Regular"!important;
        }

        .big-text {
            color: #8d8f93;
            font-size: 1.25rem!important;
            font-family: "SF-Compact-Rounded-Regular"!important;
        }
    }
}

.counter-text {
    font-size: 1rem!important;
}

@media (min-width: 900px) {
    .android-header-container {

        .image-container {
            img {
                width: 131px!important;
            }
        }

        .title-container {
            width: 100%;
        }
    }

    
}

@media (min-width: 403px) {

    .android-header-container {
        padding-top: 30px;
        display: flex;
        gap: 10px;
        padding: 0px 7px;
        justify-content: center;

        .image-container {
        }

        .title-container {
            display: flex;
            flex-direction: column;
            gap: 5px;

            h2{
                text-align: center;
            }
            
            h3 {
                margin: 0;
                text-align: center;
            }
        }
    }
}