.payment-history-subtitle-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: flex-end;
}

.payment-history-subtitle-container-box{
    display: flex;
    align-items: center;
    margin: bottom 40px;
}

@media (max-width: 601px) {
    .payment-history-subtitle-container-box{
        display: flex;
        align-items: center;
        margin: bottom 40px;
        flex-direction: column;
    }
}