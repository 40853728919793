.dynamic-text-card {
    width: 32%;
    border-radius: 10px;
    height: 800px;
    padding: 20px;
    box-shadow: 0px 4px 20px 0px #00000040;
    display: none;
    min-width: 350px;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 15px;
    overflow: scroll;
}

.dynamic-text-card-mainbox{
    display:flex;
     justify-content: center;
     flex-wrap: wrap;
     align-content: space-around;

}

.show {
    display: block;
}

.dynamic-text-card ul {
    list-style: none;
    margin: 0;
}

.dynamic-text-card ul p {
    font-size: 1rem!important;
}

.dynamic-text-card ul p:hover {
    text-decoration: underline;
}

.dynamic-text-card ul li {
    padding: 10px 0;
    cursor: pointer;
    font-weight: 500;
    color: #808080;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.dynamic-text-card ul li.active, .dynamic-text-card ul li.active img {
    color: black;
    filter: none;
}

.dynamic-text-card ul li img {
    filter: invert(50%) sepia(0%) saturate(0%) hue-rotate(231deg) brightness(100%) contrast(84%);
}

.dynamic-text-card ul li:hover {
    text-decoration: underline;
}

.dynamic-text-card .editDynamicTextInputContainer {
    padding: 10px;
    border: 1px solid rgb(0 0 0 / 30%);
    border-radius: 10px;
    margin-bottom: 20px;
}

.dynamic-text-card .editDynamicTextInputContainer input {
    width: 100%;
    border: none;
    outline: none;
}

.btn-save-dynamic-copies {
    padding: 10px 20px;
    height: auto;
    color: #FFFFFF;
    font-weight: 500;
    border-radius: 10px;
    background: linear-gradient(to right, #ee1c34, #500b28);
    font-size: 1rem;
}

.file-nuestro-progama-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 4px 20px 0px #00000040;
    margin-bottom: 20px;
}
.label-dark {
	color: #000000;
}

.checkbox {
    width: 20px;
    height: 20px;
    border: 2px solid black;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .checkbox.checked {
    background: black;
    
  }
  
  
  
  