$baseurl: "";
.percentage-bar-container {
    display: flex;
    align-items: center;

    h3 {
        font-size: 1.25rem !important;
        margin: 0;
    }

    img {
        width: 1.25rem;
    }
    .bar-container {
        width: 100%;
        height: 10px;
        box-shadow: rgba(50, 50, 93, 0.25) -5px 2px 4px 0px inset;
        border-radius: 10px;
        margin: 0px 10px;
        width: 220px;

        .bar-progress {
            background: linear-gradient(to right, rgb(238, 28, 52), rgb(80, 11, 40));
            height: 10px;
            border-radius: 10px;
            position: relative;
        }

        .point-progress {
            width: 20px;
            height: 20px;
            background: rgb(80, 11, 40);
            position: absolute;
            right: 0px;
            top: 50%;
            border-radius: 50%;
            transform: translate(50%, -50%);
        }
        .gold-bar {
            background: linear-gradient(to right, #efc900, #d6a21e);
            
            .point-progress {
                background: linear-gradient(to right, #efc900, #d6a21e);
            }
        }
    }

    &.loading {
        width: 100%;
    }
}

@media (max-width: 991px) {
    .percentage-bar-container {
        .bar-container {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            margin: 0;
            border-radius: 10px;

            .point-progress {
                display: none;
            }
        }
    }
}
