$baseurl: "";
@import "@theme/mixin.scss";

.locations-main-container {
  display: grid;
  grid-template-columns: 30% 70%;
  background-color: #f0f5f8;
  aspect-ratio: 12/5;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    overflow-y: scroll;
    padding: 20px;

    &::-webkit-scrollbar {
      display: block;
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #9aa7b5;
      width: 4px;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: #dae3ec;
      width: 2px;
      border-radius: 6px;
    }

    li {
      padding: 15px 20px;
      background-color: #e9f0f7;
      font-size: 15px;
      font-weight: bold;
      color: #cf072c;
      border-radius: 12px;

      p {
        margin: 0px;
        font-size: 15px;
      }

      .schedule {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 20px;
        max-height: 0px;
        overflow: hidden;
        transition: 0.25s ease max-height;

        p {
          color: #001036;
        }
      }

      &.active {
        background-color: #e1e7ed;

        .schedule {
          max-height: 100px;
        }
      }
    }
  }

  .map-container {
    padding: 40px 75px;
    iframe {
      box-shadow: -1px 0px 13px 0px rgba(0, 0, 0, 0.2);
      border-radius: 12px;
    }
  }

  @include respond-to(md) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    padding: 0px;
    aspect-ratio: unset;
    background-color: #ffffff;
    gap: 20px;

    &::before {
      content: "Sedes";
      width: 100%;
      text-align: center;
      color: #cf072c;
      font-size: 24px;
      font-family: "inter";
      font-weight: bolder;
    }

    ul {
      margin: 0px;
      padding: 20px;
      height: fit-content;
      display: grid;
      grid-template-columns: repeat(4, 90%);
      grid-template-rows: repeat(4, auto);
      grid-auto-rows: auto; /* Rows will be created as needed */

      &::-webkit-scrollbar {
        display: none;
      }
      li {
        width: 100%;
        height: fit-content;
      }
    }

    .map-container {
      aspect-ratio: 1;
    }
  }
}
