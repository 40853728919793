$baseurl: "";
@import "theme/colors", "theme/variables";

form {
	.cards__container {
		margin: 0 auto;
	}
	.payment-row,
	.input-row {
		margin-bottom: $spacing;
		margin-right: 0px;
		margin-left: 0px;
	}
	.button__container {
		margin-bottom: $spacing-4x;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
	}

	button.mar-r-btn.active {
		background: linear-gradient(to right, #ee1c34, #500b28) !important;
		color: white !important;
	}

	button {
		&.msi-3,
		&.msi-6 {
			position: relative;
		}

		&.msi-3 {
			&::after {
				content: "Hasta 3 MSI";
				position: absolute;
				top: 100%;
				margin-top: 5px;
				left: 0;
				right: 0;
			}
		}

		&.msi-6 {
			&::after {
				content: "3 ó 6 MSI";
				position: absolute;
				top: 100%;
				margin-top: 5px;
				left: 0;
				right: 0;
			}
		}
	}
}
.tertiary.canjear-cupon-btn {
	background: linear-gradient(to right, #ee1c34, #500b28) !important;
	color: white !important;
}

.step-2-subtitle-container{
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	.instruccion {
		font-weight: bold !important;
	}

}

.type-of-users-btn {
	height: auto;
	padding: 20px;
	max-width: 450px;
	min-width: 300px;
	background-color: white;
	box-shadow: 1px 1px 10px 4px rgba(0, 0, 0, 0.2);
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.type-of-users-btn .type-title {
	font-size: 1.5rem !important;
	color: #ca5050;
}

.type-of-users-btn.active .type-title {
	color: white;
}

.type-of-users-btn.active {
	background: linear-gradient(to right, #ee1c34, #500b28) !important;
	color: white;
}

@media (max-width: 749px) {
	form {
		.button__container {
			flex-direction: column;
			align-items: center;
			gap: 18px;
		}

		.mar-r-btn {
			margin-top: 20px;
		}
	}
}
