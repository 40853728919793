$baseurl: "";
.invoice-admin-main-container {
	height: fit-content;
	.invoice-header-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		.title-container {
			display: flex;
			align-items: center;
			div {
				position: relative;
			}
			div > .hover-pending-invoices {
				display: none;
				font-size: 0.8rem;
				width: 100px;
				margin-left: 10px;
				border: 2px solid;
				border-radius: 5px;
				padding: 5px;
				position: absolute;
				background-color: white;
				top: 7%;
			}
			.pending-invoices-count:hover + div > .hover-pending-invoices {
				display: flex;
			}
		}
		.invoice-header-right-part {
			display: flex;
			flex-wrap: wrap;
			gap: 10px;
			button {
				width: 190px;
				height: 44px;
				padding: 10px 20px;
				background: linear-gradient(to right, #ee1c34, #500b28);
				color: white;
				font-size: 1rem !important;
				font-weight: 500;
			}
			.invoice-search-bar {
				border: 1px solid rgba(0, 0, 0, 0.3);
				border-radius: 10px;
				width: 360px;
				height: 42px;
				display: flex;
				align-items: center;
				gap: 10px;
				padding: 10px;
				input {
					border: none;
					width: 100%;
				}
			}
		}
	}
	.invoice-types-container {
		display: flex;
		column-gap: 15px;
		justify-content: space-evenly;
		flex-wrap: wrap;
		margin: 20px 0;
		row-gap: 10px;
		position: sticky;
		top: 50px;
		background: white;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 6.25);
		border-radius: 10px;
		padding: 10px;
		z-index: 2;
		.invoice-type-selector {
			background: none;
			border: none;
			padding: 0;
			margin: 0;
			cursor: pointer;
			outline: inherit;
			height: inherit;
			&:hover {
				text-decoration: underline;
			}
			p {
				font-style: normal;
				line-height: 30px;
				text-align: center;
				color: #000000;
				margin-bottom: 0;
				font-weight: 300 !important;
			}
		}
		.active {
			text-decoration: underline;
		}

		@media (max-width: 536px) {
			top: 78px;
		}
	}
}
.remaining-invoices-indicator {
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	background: #ffffff;
	border-radius: 24px;
	width: 114px;
	height: 50px;
	position: fixed;
	right: 2%;
	bottom: 2%;
	padding: 10px 8px;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	margin-left: auto;
	&:hover {
		display: none;
	}
	span {
		color: #87112c;
		font-size: 1.5rem;
		font-weight: 400;
	}
}
.remaining-invoices-indicator-2 {
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	background: linear-gradient(90.31deg, #ee1c34 0.26%, #500b28 99.73%);
	border-radius: 24px;
	width: 114px;
	height: 50px;
	position: fixed;
	right: 2%;
	bottom: 2%;
	padding: 10px 8px;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	opacity: 0;
	margin-left: auto;
	&:hover {
		opacity: 1;
		.invoices-left-tooltip {
			display: unset;
		}
	}
	span {
		color: white;
		font-size: 1.5rem;
		font-weight: 400;
	}
	.invoices-left-tooltip {
		position: absolute;
		bottom: 110%;
		right: 70px;
		left: -110px;
		background: black;
		color: white;
		border-radius: 10px 10px 0 10px;
		padding: 10px;
		display: none;
	}
}
