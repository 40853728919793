$baseurl: "";
@import 'theme/colors', 'theme/variables';

.exc__button {
  position: fixed;
  right: 50px;
  bottom: 110px;
  z-index: 1000;
  .question__icon {
    border-radius: 32px !important;
    outline: none;
    width: 64px;
    height: 64px;
    padding: 0 !important;
    .text {
      font-size: $font-size-h1;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(45deg, $color-left, $color-right);
    }
    &:after {
      content: '';
      border-radius: 32px;
      position: absolute;
      margin: 1px;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: -1;
      background: white;
    }
    &:hover {
      //background-image: linear-gradient(-45deg, $color-left, $color-right);
      box-shadow: 0 12px 24px rgba(gray, 0.1);
      .text {
        background-image: linear-gradient(-45deg, $color-left, $color-right);
      }
    }
  }
}

.tooltip {
  &.show {
    opacity: 1 !important;
  }
  inset: auto auto 20px -30px !important;
  .arrow {
    display: none;
  }
  .tooltip-inner {
    background-color: $color-white;
    color: $color-black;
    font-size: $font-size-p;
    max-width: 260px;
    text-align: left;
  }
}
