/* Lato */
// Light
@font-face {
  font-family: $font-family-secondary;
  src: url(./fonts/Lato-Light.ttf) format("truetype");
  font-weight: $font-weight-light;
}

// Regular
@font-face {
  font-family: $font-family-secondary;
  src: url(./fonts/Lato-Regular.ttf) format("truetype");
  font-weight: $font-weight-regular;
}

// Bold
@font-face {
  font-family: $font-family-secondary;
  src: url(./fonts/Lato-Black.ttf) format("truetype");
  font-weight: $font-weight-medium;
}

/* Poppins */
@font-face {
  font-family: $font-family-primary;
  src: url(./fonts/Poppins-Light.ttf) format("truetype");
  font-weight: $font-weight-light;
}
// Regular
@font-face {
  font-family: $font-family-primary;
  src: url(./fonts/Poppins-Regular.ttf) format("truetype");
  font-weight: $font-weight-regular;
}

// Medium
@font-face {
  font-family: $font-family-primary;
  src: url(./fonts/Poppins-Medium.ttf) format("truetype");
  font-weight: $font-weight-medium;
}

// Bold
@font-face {
  font-family: $font-family-secondary;
  src: url(./fonts/Poppins-Bold.ttf) format("truetype");
  font-weight: $font-weight-bold;
}

@font-face {
  font-family: "inter";
  src: url("../assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf") format("truetype");
}
@font-face {
  font-family: "inter black";
  src: url("../assets/fonts/Inter/static/Inter-Black.ttf") format("truetype");
}
@font-face {
  font-family: "pacifico";
  src: url("../assets/fonts/Pacifico/Pacifico-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "palanquin dark";
  src: url("../assets//fonts/Palanquin_Dark/PalanquinDark-Regular.ttf") format("truetype");
}
