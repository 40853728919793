$baseurl: "";

.notifications-container-main-component {
    .translate-input-container {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        gap: 7px;
    
        .form-check {
          padding: 0;
        }
    
        input {
          display: none;
        }
      }
    .notification-main-container {
    
        .notification-container{
            .notification-info-container{
                .notifications-container-page{
                    width: 42px!important;
                    height: 42px!important;
    
                    .profile-pic {
                        img {
                            width: 50px!important;
                            height: 50px!important;
                        }
    
                        .type-notifications-icon {
                            width: 1.5rem!important;
                            height: 1.5rem!important;
                        }
                    }
                }
            }
        }
    }
}