$baseurl: "";
.location-form-container {
  display: flex;
  flex-direction: column;
  gap: 15px;

  button {
    margin: 0px;
    padding: 0px;
    font-size: 1rem!important;

    &.trash {
      background-color: transparent;

      img {
        width: 1.25rem;
      }
    }
  }

  input {
    font-size: 1rem;
    border-radius: 7px;
    border: 1px solid #b8b8b8;
    padding: 5px;
  }

  div.error-message {
    color: #cf092b;
    font-weight: bolder;
    font-size: 0.875rem;
  }

  .input-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    
    label{
      margin: 0px;
    }
  }

  .location-schedule-row-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    

    .schedule-day-container {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      border-bottom: 2px solid #c8c8c8;
      padding-bottom: 20px;

      
      > div {
        flex: 1;
      }
      
      .day-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        .input-container {
          width: 100%;

          input {
            width: 100%;
          }
        }
      }

      .hour-row {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .hour-container {
          display: flex;
          align-items: center;
          gap: 10px;
          width: 100%;

        }
      }
    }
  }
}