$baseurl: "";
.go-up-btn {
    border-radius: 100%;
    width: 50px;
    height: 50px;
    position: fixed;
    background-color: white;
    box-shadow: 0px 4px 20px 0px #00000040;
    right: 2vw;
    top: 90vh;
    padding: 10px;
    z-index:3;
    cursor: pointer;

    img {
        width: 100%;
        height: 100%;
        transform: rotate(90deg);
    }
}

.forms-admin-container {
    position: relative;
    height: 100vh; 
    overflow-y: auto;
    padding: 20px;
    .forms-btn-see-users-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .link-form-btn {
        border: 2px solid #5081ca;
        background-color: transparent;
        color: #5081ca;
        padding: 0 10px;
        font-size: 1rem !important;
        max-width: 250px;
        margin-left: auto;
        margin-right: 10px;
        justify-content: center;
        text-align: center;
        min-width: 250px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .forms-card {
        box-shadow: 0px 4px 20px 0px #00000040;
        padding: 20px 40px;
        border-radius: 10px;
        margin-top: 20px;

        .forms-card-row {
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
                margin: 0;
                max-width: 50%;
            }
        }
    }

    .formNumber {
        background: #ffffff;
        color: #bc4e4e;
        border: 1px solid #bc4e4e;
        font-weight: 500;
        width: 15%;
        font-size: 14px !important;
        text-align: center;
        line-break: auto;
        margin: 0 0 0 0;
        padding: 0 0 0 0;
    }

    .formON {
        background-color: #bc4e4e;
        color: white;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 5px;
        margin-right: 5px;
        min-width: 100px;
    }

    .formOFF {
        margin-bottom: 10px;
        margin-left: 5px;
        margin-right: 5px;
        min-width: 100px;
    }

    .return-btn-style {
        margin-bottom: 10px;
        margin-left: 5px;
        margin-right: 5px;
        padding: 5px;
        width: 80%;
        max-width: 240px;
    }

    .gen-forms-subtitle {
        margin-top: 40px;
    }

    .action-btn {
        min-width: 100px;
    }

    table {
        td.email-td {
            max-width: 300px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }



    @media (max-width: 820px) {
        .formOFF {
            min-width: 240px;
        }
        .formON {
            background-color: #bc4e4e;
            color: white;
            min-width: 240px;
        }

        .link-form-btn {
            border: 2px solid #5081ca;
            background-color: transparent;
            color: #5081ca;
            padding: 0 10px;
            font-size: 1rem !important;
            max-width: 240px;
            margin-left: auto;
            margin-right: 5px;
            justify-content: center;
            text-align: center;
            min-width: 240px;
            align-items: center;
            margin: auto;
        }
        .forms-btn-see-users-container {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            flex-direction: column;
            margin: auto;
            align-items: center;
        }

        .forms-card {
            box-shadow: 0px 4px 20px 0px #00000040;
            padding: 20px 40px;
            border-radius: 10px;
            margin-top: 20px;

            .forms-card-row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: column;
                align-items: center;

                p {
                    margin: 0;
                    max-width: 50%;
                }
            }
        }
    }
}
