$baseurl: "";
.middle-screen-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .middle-screen-card {
        padding: 10px 40px 40px 40px;
        background: #ffffff;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        min-height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;

        .dynamic-text {
            font-size: 2.25rem;
            text-align: center;

            a {
                font-weight: bolder;
                background: linear-gradient(to right, #ee1c34, #500b28 69.67%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        .progress-bar-middlescreen {
            
            flex-direction: column-reverse;
            gap: 0px;
            
            h2 {
                font-size: 1rem!important;
                margin: 0;
                width: 100%;
                color: rgb(55, 112, 245);
            }

            p {
                font-size: 0.875rem!important;
                margin: 0;
                width: 100%;
                text-align: center;
                margin-bottom: 10px;
            }

            .words-progress-bar {
                width: 100%;

                .words-progress {
                    height: 10px;
                }
            }
        }

        h2 {
            margin-bottom: auto;
        }

        .words-container {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            margin-bottom: auto;
            flex-direction: column;
            align-items: center;

           
        }
    }

    button {
        align-self: center;
    }
}

@media (max-width: 600px) {
    .middle-screen-container {
        .middle-screen-card {
            padding: 10px;
        }
    }
}
@media (max-width: 520px) {
    .middle-screen-container {
        .middle-screen-card {
            .block-text {
                font-size: 1.4rem;
            }
            .words-container {
                .word-container {
                    // flex-direction: column;
                    // gap: 10px;

                    p {
                        text-align: center;
                        font-size: 1rem!important;
                    }

                    .word-to-study-container {
                        border: none;
                        margin: 0;
                    }
                }
            }
        }
    }
}