$baseurl: "";
.linksHistoryModal {
	padding: 20px;

	.copySuccess {
		position: absolute;
		bottom: 0;
		right: 0;
		margin: 8px;
		padding: 2px;
		transition: opacity 0.3s ease;
		opacity: 0;
	}

	.copySuccess.active {
		opacity: 1;
	}

	.footerLink {
		box-shadow: inset 0px 1px 10px rgba(0, 0, 0, 0.25);
		padding: 2px 5px;
		border-radius: 8px;
		position: relative;
		/* Utiliza min-height en lugar de height para permitir que el contenedor crezca si el contenido es demasiado grande. */
		min-height: 70px;
		border: 1px solid transparent;
		h3 {
			font-size: 1rem !important;
			font-weight: normal !important;
			margin: 0;
			padding: 10px;
			/* Se puede ajustar la línea de altura para mejorar la legibilidad en dispositivos móviles. */
			line-height: 1.5;
      word-wrap: break-word; /* Añade esto */
		}

		/* Establece un punto de interrupción para dispositivos móviles de tamaño más pequeño. */
		@media screen and (max-width: 320px) {
      height: 110px;
			h3 {
				/* Ajusta el tamaño de la fuente y el espaciado según sea necesario. */
				font-size: 0.875rem;
				padding: 5px;
			}
		}

		/* Establece un punto de interrupción para dispositivos móviles más grandes y tabletas pequeñas. */
		@media screen and (min-width: 321px) and (max-width: 768px) {
      height: 110px;
			
			h3 {
				font-size: 1rem;
				padding: 10px;
			}
		}
	}

	.linkHistoryContainer {
		display: flex;
		flex-direction: column;
		gap: 15px;

		.linkHistoryCard {
			padding: 15px;
			border: 1px solid #c9ccd0;
			border-radius: 35px;
			box-shadow: 0 1px 0 rgba(0, 11, 28, 0.25);

			/* Hacer que el ancho de la tarjeta sea 100% para ocupar todo el espacio disponible */
			width: 100%;
		}
	}

	.disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}

	.AiOutlineCopy {
		position: absolute;
		bottom: 0;
		right: 0;
		margin: 8px;
		padding: 2px;
		cursor: pointer;
		transition: transform 0.3s ease, box-shadow 0.3s ease; // Añade transiciones para que los cambios se hagan suavemente
	}

	.AiOutlineCopy:hover {
		transform: translateY(-4px); // Mueve el elemento 4px hacia arriba
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); // Añade una sombra para darle un efecto de "elevación"
	}
}
