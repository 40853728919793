$baseurl: "";
.main-layout-main-container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100%;
  max-width: 2100px;
  margin: auto;
  > div {
    position: relative;
  }
}
