$baseurl: "";
@import 'theme/colors', 'theme/variables';

.custom-form {
  label {
    font-family: $font-family-primary;
  }
  button {
    height: 50px !important;
    margin: $spacing 0 0 !important;
  }
  .row {
    margin-bottom: 0 !important;
  }
}
