$baseurl: "";
.galleries-main-contain {
	display: flex;
	flex-direction: column;
	gap: 10px;
	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}
	.button-ctn {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		flex-wrap: wrap;
		gap: 10px;
		.input-container {
			display: flex;
			flex-direction: column;
			position: relative;
			label {
				font-weight: 600;
			}
			.error {
				color: #cf072c;
				position: absolute;
				font-size: 0.8rem;
				bottom: -35px;
				left: 50%;
				transform: translateX(-50%);
				white-space: nowrap;
			}
		}
	}
	button {
		color: #fff;
		background-color: #cf072c;
		transition: 400ms ease all;
		width: fit-content;
		height: fit-content;
		padding-block: 6px;
		font-size: 1rem;
		padding-inline: 0px;
		width: 250px;
		&:hover {
			opacity: 0.5;
		}
	}
	.finish {
		background-color: #07cf39;
	}
	input {
		border: none;
		padding-block: 6px;
		padding-inline: 24px;
		outline: none;
		border: 2px solid rgba(0, 0, 0, 0.329);
		border-radius: 10px;
	}
	.gallery-content {
		display: flex;
		gap: 20px;
		margin-top: 10px;
		overflow: auto;
		flex: 1 1;
		flex-wrap: wrap;
		justify-content: space-evenly;
		.single-gallery {
			display: flex;
			flex-direction: column;
			width: 300px;
			border: 1px solid black;
			border-radius: 24px;
			padding: 12px;
			gap: 10px;
			.gall-header {
				display: flex;
				align-items: center;
				gap: 10px;
				position: relative;
				svg {
					font-size: 1.6rem;
					transition: 400ms ease all;
					cursor: pointer;
					&:hover {
						opacity: 0.5;
					}
				}
				.drag-icon {
					position: absolute;
					top: 0px;
					right: 0px;
					font-size: 1.2rem;
					cursor: grab;
				}
			}
			label {
				display: flex;
				flex-direction: column;
				gap: 10px;
				width: 100% !important;
				img {
					width: 100%;
					height: 150px;
					border-radius: 14px;
					object-fit: cover;
				}
				font-weight: 500;
				width: fit-content;
				cursor: pointer;
				transition: 300ms ease all;
				&:hover {
					opacity: 0.5;
				}
			}
		}
	}
}
