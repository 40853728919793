$baseurl: "";
.user-profile-modal{
    margin-right: 0 !important;
    margin-top: 0 !important;
    height: 100% !important;
    max-width: 450px !important;
    min-width: 300px !important;
    .header{
        flex-direction: column-reverse;
        .title{
            display: flex;
            width: 100%;
            justify-content: space-between;
            .surveys{
                gap:10px;
                display:flex;
                img{
                    width: 1.5rem;
                    cursor: pointer;
                    z-index: 2;
                    padding-top: 10px;
                }
            }
        }
    }
    .modal-body{
        h2{
            font-size: 1.5rem;
            margin:0;
        }
        .name-streak{
            margin-bottom:20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .streak-container{
                .streak-icon{

                }
                .spinner-border{
                    
                }
            }
        }
        .modal-labels{
            font-size: 1rem !important;
            font-weight: 700 !important;
            margin-bottom: 0.5rem !important;
        }
        .input1{
            padding: 10px;
            border: 2px solid rgba(0, 0, 0, 0.329);
            margin-bottom: 20px;
            border-radius: 10px;
            width: 100%;
        }
        .input-age{
            margin-bottom: 1rem;
        }
        .input-gen{
            resize: none;
        }
        .phone-input{
            margin-bottom: 20px;
        }
        .payments-info{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1rem;
            p{
                padding: 0;
                margin: 0;
            }
            .module{
                font-size: 1rem !important;
                font-weight: 400 !important;
            }
            .module-status{
                font-size: 1rem !important;
                font-weight: 400 !important;
            }
        }
        .promo-text{
            font-weight: bolder !important;
            font-size: 1rem !important;
            text-align: end;
            background: linear-gradient(to right, #ee1c34, #500b28 69.67%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        .promotion{
            display: flex;
            margin-bottom: 20px;
            gap: 10px;
            .promotion-label{
                font-size: 1rem !important;
                font-weight: 700 !important;
                margin-bottom: 0 !important;
            }
        }
        .save-delete{
            display: flex;
            flex-direction: column;
            gap: 5px;
            .delete-btn{
                transition: none;
                &:hover {
                    border: 2px solid rgba(0, 0, 0, 0.329);
                }
            }
            .save-btn{
                transition: none;
                &:hover {
                    border: 2px solid rgba(0, 0, 0, 0.329);
                }
            }
        }
        .payments-history{
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction:column;
            .total-pay{
                all:inherit;
                width:100%;
                flex-direction:row;
            }
            p{
                font-size: 1rem !important;
                font-weight: 400 !important;
            }
            .payments{
                width: 100%;
                .payment{
                    display: flex;
                    align-items: flex-start;
                    .amount-and-concept{
                        text-align: end;
                        margin-left: auto;
                    }
                }
            }
        }
        .gen-selector{
            width: 100%;
            height: 2.5rem;
            white-space: break-spaces;
            margin-bottom:1rem;
            border: 2px solid rgba(0, 0, 0, 0.329);
            border-radius: 10px;
        }
    }
    .modal-content{
        min-height: 100vh !important;
    }
}