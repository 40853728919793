$baseurl: "";
.right-aligned-elements {
    display: flex;
    gap: 15px;
    flex-direction: row;
    align-items: center;
}
@media (max-width: 521px) {

    .right-aligned-elements {
        scale: 0.8;
    }
}