$baseurl: "";
.invoice-admin-card-main-container {
	display: flex;
	width: 100%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	background: #ffffff;
	padding: 15px;
	margin: 10px 0px;
	flex-direction: column;
	.iac-container-v1 {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		align-items: center;
		.iac-left-part-container {
			display: flex;
			flex-direction: row;
			width: 70%;
			justify-content: space-between;
			.section-1 {
				display: flex;
				flex-direction: column;
				width: 35%;
				.name {
					color: #ca5050;
					font-size: 1.2rem;
					font-weight: 500;
					text-overflow: ellipsis;
					overflow: auto;
					white-space: nowrap;
				}
				.amount {
					font-weight: bolder;
				}
				.red {
					color: #9e142e;
				}
				.green {
					color: #02a377;
				}
			}
			.section-2 {
				display: flex;
				flex-direction: column;
				width: 40%;
				padding-left: 3%;
				.date-label {
					color: #707070;
				}
				.actual-date {
					font-weight: 500;
				}
				.red {
					color: #9e142e;
				}
				.green {
					color: #02a377;
				}
			}
			.section-3 {
				display: flex;
				flex-direction: column;
				width: 25%;
				padding-left: 3%;
				.state-label {
					color: #7b7c7e;
				}
				.actual-state {
					color: #636363;
					font-weight: 400;
				}
				.red {
					color: #9e142e;
				}
				.green {
					color: #02a377;
				}
				.grey {
					color: #636363;
				}
			}
		}
		.iac-right-part-container {
			position: relative;
			.check-btn {
				border: 1px solid #ca5050;
				background-color: transparent;
				color: #ca5050;
				padding: 0 15px;
				height: 44px;
				font-size: 1.2rem !important;
				font-weight: 400;
			}
			.check-invoice-btn {
				background: linear-gradient(90.31deg, #ee1c34 0.26%, #500b28 99.73%);
				box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
				height: 44px;
				font-size: 1.2rem !important;
				font-weight: 400;
				padding: 0 15px;
				color: white;
			}
			.iac-close-btn {
				position: absolute;
				top: -31px;
				right: -3px;
				cursor: pointer;
			}
		}
	}
	.iac-reject-container {
		display: flex;
		flex-direction: column;
		margin-top: 8px;
		.label {
			color: #9e142e;
			font-weight: 450;
		}
		.value {
			font-weight: 600;
		}
	}
	.iac-container-v2 {
		display: flex;
		flex-direction: row;
		width: 100%;
		position: relative;
		justify-content: space-between;
		.iac-close-btn {
			position: absolute;
			top: -3px;
			right: -3px;
			cursor: pointer;
		}
		.separator {
			width: 1px;
			background-color: rgba(0, 0, 0, 0.1);
			margin-left: 2%;
			margin-right: -2%;
		}
		.separator-2 {
			width: 1px;
			background-color: rgba(0, 0, 0, 0.1);
		}
		.section-1 {
			gap: 15px;
			width: 35%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.row {
				display: inherit;
				flex-direction: row;
				margin: 0;
				justify-content: space-between;
			}
			.column {
				display: inherit;
				flex-direction: column;
				width: 49%;
				min-width: 150px;
			}
			.top {
				justify-content: flex-start;
				gap: 20px;
				align-items: center;
				button {
					padding: 0px 10px;
					height: 35px;
				}
			}
			.bottom {
				width: 100%;
				overflow-wrap: anywhere;
			}
			.label {
				color: #707070;
				font-weight: 300;
			}
			.value {
				font-weight: 600;
				overflow-wrap: anywhere;
				input {
					width: 100%;
					border-radius: 14px;
					padding: 2%;
				}
				textarea {
					width: 110%;
					resize: none;
					border-radius: 14px;
					padding: 2%;
				}
			}

			.name {
				color: #ca5050;
				font-size: 1.2rem;
				font-weight: 500;
				text-overflow: ellipsis;
				overflow: auto;
				white-space: nowrap;
			}
			.red {
				color: #9e142e;
			}
			.green {
				color: #02a377;
			}
		}
		.section-2 {
			gap: 15px;
			display: flex;
			flex-direction: column;
			width: 19%;
			justify-content: space-between;
			margin-right: 1%;
			.row {
				display: inherit;
				flex-direction: row;
				margin: 0;
				justify-content: space-between;
				margin: 0 0 0 25%;
			}
			.column {
				display: inherit;
				flex-direction: column;
				width: 100%;
			}
			.label {
				color: #707070;
				font-weight: 300;
			}
			.value {
				overflow-wrap: anywhere;
				font-weight: 600;
				input {
					width: 100%;
					border-radius: 14px;
					padding: 2%;
				}
				textarea {
					width: 110%;
					resize: none;
					border-radius: 14px;
					padding: 2%;
				}
			}
		}
		.section-3 {
			gap: 15px;
			display: flex;
			flex-direction: column;
			width: 45%;
			justify-content: space-between;
			margin-left: 1%;
			.row {
				display: inherit;
				flex-direction: row;
				margin: 0;
				justify-content: space-between;
			}
			.column {
				display: inherit;
				flex-direction: column;
				width: 60%;
				.p-method-detail {
					font-style: italic;
					font-size: small;
					color: #474747;
				}
			}

			.column-price {
				display: flex;
				flex-direction: row;
				width: 109%;

				.column-price-button {
					display: flex;
					justify-content: flex-end;
					align-items: flex-start;
					width: 69%;
				}
			}
			.column-2 {
				display: inherit;
				flex-direction: column;
				width: 39%;
				min-width: 140px;
			}
			.invoice-action-btns {
				gap: 16px;
				display: flex;
				justify-content: flex-end;
				flex-wrap: wrap;
				button {
					padding: 0 10px;
					height: 45px;
					font-size: 1rem !important;
					background: transparent;
				}
				.see-receipt-btn {
					border: 1px solid #505050;
					color: #505050;
				}
				.reject-btn {
					border: 1px solid #ca5050;
					color: #ca5050;
				}
				.approve-btn {
					border: 1px solid #31b490;
					color: #31b490;
					// .spinner{
					// 	width:50px;
					// }
					// .spinner > div{
					// 	height:0 !important;
					// }
				}
				.disabled-btn {
					background: "grey";
				}
			}
			.label {
				color: #707070;
				font-weight: 300;
			}
			.value {
				font-weight: 600;
				overflow-wrap: anywhere;
			}
		}
		.red {
			color: #9e142e;
		}
		.green {
			color: #02a377;
		}
		.grey {
			color: #636363;
		}
	}
	.invoice-changes-container {
		margin: 20px auto 0 auto;
		display: flex;
		gap: 20px;
		justify-content: center;
		button {
			padding: 0 10px;
			height: 45px;
			font-size: 1rem !important;
			background: transparent;
		}
		.discard-changes-btn {
			border: 1px solid #ca5050;
			color: #ca5050;
		}
		.save-changes-btn {
			border: 1px solid #31b490;
			color: #31b490;
		}
	}
	@media screen and (max-width: 600px) {
		.iac-container-v2 {
			flex-direction: column;
			.section-1 {
				margin-left: 5%;
				width: 90%;
			}
			.section-2 {
				margin-left: 5%;
				width: 90%;
				.row {
					margin: 0;
				}
			}
			.section-3 {
				margin-left: 5%;
				width: 90%;
			}
		}
	}
	@media screen and (max-width: 600px) {
		.iac-container-v1 {
			flex-direction: column;
			.iac-left-part-container {
				flex-direction: column;
				width: 90%;
			}
			.section-1 {
				width: 100% !important;
			}
			.section-2 {
				width: 100% !important;
			}
			.section-3 {
				width: 100% !important;
			}
		}

		.column-price-button {
			display: none !important;
		}

		button.view-profile-responsive {
			background: linear-gradient(90.31deg, #ee1c34 0.26%, #500b28 99.73%);
			box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
			color: #ffffff;
			font-size: 1rem !important;
			display: flex;
			align-items: center;
		}
	}
}
button.view-profile-responsive {
	display: none;
}

button.view-profile {
	background: linear-gradient(90.31deg, #ee1c34 0.26%, #500b28 99.73%);
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	color: #ffffff;
	font-size: 16px !important;
	width: 30px;
	display: flex;
	height: 30px;
	justify-content: center;
	align-items: center;
	flex-wrap: nowrap;
	text-wrap: nowrap;
}
