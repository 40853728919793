$baseurl: "";
.notification-container {
    padding: 5px 25px;
    position: relative;
    
    .notification-check {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .title-container {
        text-align: end;
        h3 {
            font-size: 1rem !important;
            font-weight: 500 !important;
        }
    }

    .notification-info-container {
        display: flex;
        gap: 8px;

        .profile-pic-container {
            .profile-pic {
                background-color: red;
                border-radius: 100px;
                width: 42px;
                height: 42px;
                position: relative;

                img {
                    position: absolute;
                    width: 50px;
                    height: 50px;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }

                .type-notifications-icon {
                    width: 1.5rem !important;
                    height: 1.5rem !important;
                    left: auto;
                    top: auto;
                    transform: none;
                    right: -5px;
                    bottom: -10px;
                }

                p {
                    color: white;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 0.875rem !important;
                }
            }
        }

        .notification-user-info-contantainer {
            flex: 1;
            width: calc(100% - 50px);
            .name-hour-container {
                display: flex;

                .name-text {
                    font-size: 0.875rem !important;
                    margin: 0;
                    font-weight: 700 !important;
                    width: 50%;
                }

                .date-text {
                    font-size: 0.75rem !important;
                    margin: 0;
                    width: 50%;
                }
            }

            .text-container {
                max-height: 90px;
                overflow: hidden;

                p {
                    margin: 0;
                    font-size: 0.875rem !important;
                }

                img { 
                    width: 100%;
                }
            }
        }
    }

    .link-to-publication-container {
        margin-top: 5px;

        a {
            color: #ca5050 !important;
            cursor: pointer;
        }
    }
}

.unseen {
    background-color: #dfeefb;
}
