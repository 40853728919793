$baseurl: "";
.search-all-users-container {
	.search-input-all-users {
		max-width: 500px;
		border-radius: 10px;
		padding: 5px;
		border: 1px solid #00000080;
		margin-bottom: 20px;
	}
}

.table-container {
	height: calc(100vh - 200px); // Ajusta este valor según necesites
	overflow-y: auto;
	padding: 20px;
	table {
		thead {
			top: 0;
			background: white; // O el color que prefieras
			z-index: 1;
		}
	}
}

.loader-students {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
