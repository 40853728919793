body,
input,
select,
textarea,
button {
  font-family: $font-family-primary, Helvetica, sans-serif;
}

h1 {
  font-family: $font-family-secondary;
  font-size: $font-size-h1;
  font-weight: $font-weight-medium;
  @media screen and (max-width: 708px) {
    font-size: 40px;
  }
  @media screen and (max-width: 414px) {
    font-size: 36px;
  }
}

p {
  font-family: $font-family-primary;
  font-size: $font-size-p;
  font-weight: $font-weight-regular;
  @media screen and (max-width: 414px) {
    font-size: 16px;
  }
}

h4 {
  font-family: $font-family-secondary;
  font-size: $font-size-h4;
  font-weight: $font-weight-regular;
  @media screen and (max-width: 414px) {
    font-size: 20px;
  }
}

h3 {
  font-family: $font-family-secondary;
  font-size: $font-size-h3;
  font-weight: $font-weight-regular;
  @media screen and (max-width: 414px) {
    font-size: 28px;
  }
}

h1,
h2,
h3,
h5 {
  color: $color-black;
}

@media screen and (max-width: 706px) {
  .secondary {
    font-size: 19px !important;
  }
}
@media screen and (max-width: 414px) {
  .secondary {
    font-size: 13px !important;
    height: 60px !important;
  }
}
