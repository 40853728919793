$baseurl: "";
@import "/src/theme/mixin.scss";

.languages-cards-main-container {
  display: grid;
  grid-template-columns: repeat(4, min-content);
  justify-content: center;
  padding: 0px 80px;
  gap: 50px;
  margin: auto;

  @include respond-to(l) {
    gap: 25px;
    grid-template-columns: repeat(3, min-content);
    justify-content: center;
  }

  @include respond-to(md) {
    grid-template-columns: repeat(2, min-content);
    padding: 10px;
  }
}
