$baseurl: "";
@import 'theme/colors', 'theme/variables';

.container__modules {
  margin-bottom: $spacing-4x !important;
  .files-section-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:22px;
    flex-wrap: wrap;
  }
  .modules__navbar {
    .navbar-toggler {
      color: $color-secondary !important;
      border: 1px solid;
      border-color: $color-secondary !important;
      width: 100%;
    }
    .navbar-collapse {
      text-align: center;
    }
    .active {
      text-decoration: underline;
    }
    a {
      font-family: $font-family-primary !important;
      font-weight: $font-weight-bold;
      font-size: 22px !important;
      color: $color-black !important;
    }
  }

  .dynamics-title-container {
    display: flex;
    gap: 20px;

    h3 {
      cursor: pointer;
    }
    

    .active {
      text-decoration: underline;
    }
  }

  .call-to-action {
    text-align: start;
    h3 {
      color: $color-secondary;
    }
  }
}

@media screen and (max-width: 750px) {
  .section__program .modules__navbar .nav-link {
    margin-right: 0 !important;
  }
}
