$baseurl: "";
.test-progress-bar-component-container {
    .test-progress-bar-container-2 {
        width: 100%;
        height: 10px;
        box-shadow: rgba(50, 50, 93, 0.25) -5px 2px 4px 0px inset;
        border-radius: 10px;
        position: relative;

        .test-progress-bar {
            width: 100%;
            height: 100%;
            background:  #8d132d;
            border-radius: 10px;
            transition: 0.5s ease all;
        }

        .progress-bar-circle {
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
            width: 20px;
            height: 20px;
            border-radius: 20px;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            background-color: #ececec;
            cursor: pointer;
        }

        .active {
            background-color: #8d132d;
        }


        .final-color {
            background: linear-gradient(to right, #660d2a, #500b28);
            box-shadow: none;
        }
    }
}

@media (max-width: 991px) {
    .test-progress-bar-component-container{
        height: 100%;
        .test-progress-bar-container-2 {
            height: 100%;
            width: 10px;

            .progress-bar-circle {
                left: 50%;
            }
        }
    }
}