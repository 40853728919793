$baseurl: "";
.temary-subtitle {
    font-size: 1.5rem !important;
}

.download-temary-pdf-button {
    padding: 0;
    margin: 0;
    font-size: 1rem !important;
    border: 1px solid #ca5050;
    border-radius: 10px !important;
    color: #ca5050;
    background-color: transparent;
    width: 300px;
    font-weight: 700 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
}

.temary-card {
    box-shadow: 1px 1px 10px 4px rgba(0, 0, 0, 0.2);
    padding: 20px 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    p {
        font-family: "Open Sans", sans-serif !important;
    }

    .seminary-header-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;

        h3 {
            color: rgb(202, 80, 80);
            margin: 0px;
            font-weight: 400 !important;
            font-size: 2rem !important;
        }

        h2 {
            color: #ca5050;
            font-size: 4rem !important;
            margin: 0;
            font-family: auto;
        }

        .material-btn {
            padding: 0;
            margin: 0;
            font-size: 1rem !important;
            border: 1px solid #ca5050;
            border-radius: 10px !important;
            color: #ca5050;
            background-color: transparent;
            width: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
        }
    }

    .langs-main-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .lang-info-container {
            display: flex;
            width: 49%;
            min-width: 300px;
            margin-bottom: 10px;

            img {
                width: 64px;
                height: 64px;
                margin-right: 10px;
            }

            .lang-text-container {
                flex: 1;

                .temary-sub {
                    font-weight: bolder !important;
                    font-size: 1rem !important;
                    margin-bottom: 5px;
                }

                .lang-subtitle {
                    font-size: 1rem !important;
                }
            }

            .temary-desc-style {
                white-space: pre-wrap !important;
                font-size: 0.75rem !important;
            }
        }

        .french {
            order: 1;
        }
        .russian {
            order: 2;
        }
        .german {
            order: 3;
        }
        .chinese {
            order: 4;
        }
        .japanese {
            order: 5;

            p {
                font-family: "Murecho", sans-serif !important;
            }
            .temary-sub {
                font-weight: bolder !important;
                font-size: 1rem !important;
                margin-bottom: 5px;
                font-family: "Open Sans", sans-serif !important;
            }
        }
        .portuguese {
            order: 6;
        }
        .italian {
            order: 7;
        }

        .english {
            order: 8;
        }
    }
}

.temary-filter-container {
    gap: 10px;
    margin-bottom: 20px;

    label {
        margin-bottom: 0!important;
    }

    .temary-select-container {
        border: 1px solid #00000080;
        border-radius: 10px;


        select {
            margin: 0!important;
            border: none;
            color: #000000;
        }
    }
}

.search-section-container {
    display: flex;
    justify-content: center;
    position: sticky;
    top: 53px;
    padding: 10px 0px;
    background-color: white;
    flex-wrap: wrap;

    .search-container {
        display: flex;
        width: 50%;
        padding: 5px 15px;
        min-width: 300px;
        min-height: 51px;
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.308);
        margin-right: 20px;

        .lang-selector-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 30%;
            border-right: 1px solid rgba(0, 0, 0, 0.336);
            min-width: 100px;
            cursor: pointer;
            margin-right: 10px;

            p {
                margin: 0;
            }

            img {
                width: 15px;
                margin-right: 10px;
            }
        }

        .search-input-container {
            display: flex;
            width: 100%;

            img {
                width: 20px;
                margin-right: 10px;
            }

            input {
                width: 100%;
                border: none;
            }
        }
    }
}

.langs-selection-container {
    width: 100%;
    max-width: 760px;
    margin: auto;
    margin-top: 20px;
    box-shadow: 1px 1px 10px 4px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    position: sticky;
    top: 170px;
    background-color: white;
    height: 0;
    overflow: hidden;
    padding: 0 20px;
    visibility: hidden;
    transition: height 600ms ease-out, padding-top 600ms ease-out, padding-bottom 600ms ease-out, visibility 600ms;

    p {
        margin: 0;
    }

    .checkboxes-container {
        display: flex;
        flex-wrap: wrap;

        .checkbox-container {
            width: 25%;
            min-width: 50px;
            display: flex;
            align-items: center;
            padding: 5px;

            input {
                margin: 0;
            }
            label {
                font-size: 1rem!important;
                margin: 0;
                text-align: center;
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 1.25rem;
                    height: 1.25rem;
                }
            }
        }
    }
}

.module-selector-bar-container {
    margin-bottom: 10px;

    h3 {
        text-align: center;
        font-size: 1.25rem !important;
    }

    .module-selector-bar {
        display: flex;
        justify-content: center;
        max-width: 500px;
        margin: auto;
        border-radius: 10px;
        border: 1px solid #ca5050;
        overflow: hidden;

        .cell {
            width: 100px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ca5050;
            border-right: 1px solid #ca5050;
            cursor: pointer;
        }

        .active {
            color: white;
            background-color: #ca5050;
        }
    }
}

.langs-selection-container.show {
    padding: 20px;
    visibility: visible;
    height: 120px;
    transition: height 600ms ease-out, padding-top 600ms ease-out, padding-bottom 600ms ease-out;
}

.temary-card p {
    margin: 0;
}

.module-seminaries {
    font-size: 1rem !important;
    color: #909090;
    margin-bottom: 20px;
}

.module-title-temary {
    font-size: 2.25rem;
    font-weight: 500 !important;
    color: #ca5050;
}

.temary-search-input-container {
    border-radius: 10px;
    padding: 5px;
    border: 2px solid rgba(128, 128, 128, 0.568);
}

.temary-search-input-container input {
    border: none;
    outline: none;
}

@media (max-width: 536px) {
    .search-section-container {
        top: 74px;
        .search-container {
            flex: 1;
            margin: 0;
        }
    }

    button#search-temary-button {
        margin-top: 10px;
    }
}
