$baseurl: "";
.save-video-modal-component {
	.modal-dialog {
		.modal-content {
			.modal-body {
				.save-video-modal-body-container {
					display: flex;
					flex-direction: column;

					.save-video-row {
						display: flex;
						flex-direction: column;

						.diff-days {
							display: flex;
							justify-content: center;
							color: grey;
							margin-block: 5px;
						}
						.seminary-word {
							font-size: 1rem;
							font-weight: 700;
							text-align: center;
							margin: unset;
						}
						label {
							font-size: 1.5rem;
							font-weight: bolder;
							text-align: center;
						}

						select {
							padding: 5px;
							border-radius: 10px;
							width: 21vw;
							margin: auto;
							margin-block: 5px;
							border-width: 0.5px;
							border-color: #000000;
							height: min-content;
						}
					}
				}
			}

			.modal-footer {
				button.disabled {
					background: none;
					background-color: #cacacaca;
				}
				.translate-input-container {
					display: flex;
					//width: 100%;
					margin-right: auto;
					justify-content: flex-start;
					align-items: center;
					gap: 7px;
				
					.form-check {
					  padding: 0;
					}
				
					input {
					  display: none;
					}
				  }
			}
		}
		.date-change-btn {
			margin-right: auto;
		}
	}
}
