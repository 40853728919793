$baseurl: "";
.receipt-main-container {
    width: 100%;
    overflow: scroll;
    padding-bottom: 20px;

    .receipt-component {
        margin: auto;
        width: 100%;
        aspect-ratio: 297/210 !important;
        padding: 40px 80px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: 1100px;
        max-width: 1100px;
        position: relative;

        * {
            margin: 0px;
        }

        .line {
            position: absolute;
            right: 20px;
            height: 100%;
            top: 0px;
        }

        .header {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            img {
                width: 200px;
            }

            h2 {
                font-weight: bolder;
                text-transform: uppercase;
            }
        }

        .content {
            display: flex;
            justify-content: space-between;
            gap: 20px;

            .left-info {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 30px;

                .student-info {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    h3 {
                        font-size: 24px !important;
                    }

                    h3.name {
                        text-align: center;
                    }
                }

                .total-container {
                    display: flex;
                    align-items: center;
                    border-top: 7px solid #bd262c;
                    border-bottom: 7px solid #bd262c;
                    padding: 10px;

                    h2 {
                        font-size: 54px !important;
                    }

                    h2.amount {
                        font-size: 44px !important;
                        margin: auto;
                    }
                }
            }

            .right-info {
                display: flex;
                flex-direction: column;
                gap: 15px;
                min-width: 300px;
                .receipt-row {
                    display: flex;
                    font-size: 20px !important;
                    justify-content: space-between;

                    b {
                        font-size: 18px;
                    }
                    p {
                        font-size: 18px !important;
                        max-width: 125px;
                        text-align: end;
                        word-wrap: break-word;
                    }
                }
            }
        }

        .details {
            display: flex;
            justify-content: space-between;
            gap: 20px;

            .desglose {
                flex: 1;
                display: flex;
                gap: 20px;

                .quantity,
                .description {
                    b {
                        font-size: 18px;
                        font-weight: bolder !important;
                    }
                }
            }

            .amount {
                min-width: 300px;
                display: flex;
                justify-content: space-between;

                .total,
                .unity {
                    b {
                        font-size: 18px;
                    }

                    p {
                        font-size: 16px !important;
                    }
                }

                .total {
                    text-align: end;
                }
            }
        }

        .footer {
            display: flex;
            justify-content: center;
            h2 {
                font-weight: 100 !important;
                font-size: 24px !important;
            }
        }
    }

}
