$baseurl: "";
.my-account-cards-container {
	display: flex;
	flex-wrap: wrap;
}

.my-account-container {
	margin-right: 40px;
	min-width: 300px;
	width: 50%;
	max-width: 650px;
}

.my-account-container div {
	margin-bottom: 20px;
}

.my-account-container div p {
	margin: 0;
}

.my-account-container .PhoneInputCountry {
	margin-bottom: 0;
}

.my-account-container .PhoneInputCountryIcon,
.my-account-container .PhoneInputCountrySelectArrow {
	margin-bottom: 0;
}


.my-account-container .PhoneInput #phone {
	display: block;
	width: auto;
	padding: 10px;
	height: auto;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 10px;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.my-account-container div .subtitle {
	font-weight: 600 !important;
	font-size: 20px;
}

.my-account-container div #dropdown-menu-align-right {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.inscription-card-container {
	box-shadow: 1px 1px 10px 4px rgba(0, 0, 0, 0.2);
	padding: 30px 20px;
	border-radius: 10px;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	margin-bottom: 20px;
	min-width: 300px;
	height: fit-content;
	background-color: white;
	max-width: 380px;
}

.inscription-card-container div span {
	font-size: 1.5rem;
}

.title-inscription-card {
	color: rgb(202, 80, 80);
	font-weight: 700 !important;
	font-size: 1.5rem !important;
}

.my-account-content-container {
	width: 100%;
	min-width: 370px;
	padding: 40px 15px 80px 15px;
	//margin-bottom: 80px;
	min-height: 70vh;
	position: relative;
	//overflow-x: auto;
}

.tutorial-container {
	visibility: hidden;
	position: absolute;
	background-color: white;
	border-radius: 10px;
	padding: 20px;
	top: 10px;
	width: 300px;
	box-shadow: 1px 1px 10px 4px rgba(0, 0, 0, 0.2);
}

.question-icon2 {
	width: 20px;
	height: 20px;
	text-align: center;
	border-radius: 100%;
	background-color: gray;
	color: white;
	position: relative;
}

.info-box2 {
	position: absolute;
	display: none;
}

.question-icon2:hover {
	.info-box2 {
		display: block;
		width: 270px;
		/* font-size: 0.5rem; */
		background: gray;
		border-radius: 10px 10px 0px 10px;
		transform: translate(-99%, -110%);
		padding: 10px;
		text-align: left;
		p {
			font-size: 0.8rem !important;
		}
	}
}

.tittle-youtube {
	font-size: 15px;
}

@media (max-width: 991px) {
	.my-account-content-container {
		margin-left: auto !important;
		width: 100%;
		padding: 20px 7.5px 40px 7.5px;
	}

	.tutorial-container {
		visibility: visible;
	}

	.aside-nav {
		position: fixed;
		top: 80px;
		overflow: scroll;
		height: 100%;
	}
}

@media (max-width: 869px) {
	.my-account-container {
		width: 100%;
		margin: auto;
	}

	.inscription-card-container {
		margin: auto;
	}

	#students-talking-img {
		display: none;
	}

	.inscription-card-container {
		order: 1;
		margin-bottom: 20px;
	}

	.my-account-container {
		order: 2;
	}
}
