@font-face {
    font-family: SF-Compact-Rounded-Regular;
    src: url(./screenIMG/fonts/FontsFree-Net-SF-Compact-Rounded-Regular.ttf);
}
@font-face {
    font-family: SFProDisplay-Heavy-1;
    src: url(./screenIMG/fonts/SFPRODISPLAYBOLD.OTF);
}
@font-face {
    font-family: SFProText-Bold-1;
    src: url(./screenIMG/fonts/FontsFree-Net-SFProText-Bold-1.ttf);
}
@font-face {
    font-family: SFProText-Regular-1;
    src: url(./screenIMG/fonts/FontsFree-Net-SFProText-Regular-1.ttf);
}
@font-face {
    font-family: PingFangSCRegular;
    src: url(./screenIMG/fonts/PingFang-SC-Regular.ttf);
}
@font-face {
    font-family: Poppins-Bold;
    src: url(./screenIMG/fonts/Poppins-Bold.ttf);
}
@font-face {
    font-family: Poppins-SemiBold;
    src: url(./screenIMG/fonts/Poppins-SemiBold.ttf);
}
@font-face {
    font-family: Poppins-Medium;
    src: url(./screenIMG/fonts/Poppins-Medium.ttf);
}
@font-face {
    font-family: Poppins-Light;
    src: url(./screenIMG/fonts/Poppins-Light.ttf);
}
@font-face {
    font-family: Lato;
    src: url(./screenIMG/fonts/Lato-Regular.ttf);
}

.first-step {
    position: relative;
}

.modalIOS .circle {
    position: absolute;
    left: 42.2%;
    top: 91%;
    border: 3px solid #cd1719;
    border-radius: 50%;
    width: 36px;
    height: 36px;
}

.boxStep {
    background-color: #cd1719;
    border-radius: 31.7423px;
    width: 56.05px;
    padding-top: 4px;
    padding-bottom: 4px;
    position: absolute;
    z-index: 1;
    padding-left: 10px;
}
.boxImg {
    width: 95% !important;
}
.modalImg {
    height: auto !important;
    width: 380px !important;
    max-width: 368px !important;
}
.boxText {
    background-color: white;
    width: 95%;
    height: auto;
    border-radius: 8.54934px;

    padding-right: 26px;
}
#modalIOS .modal-content {
    background-color: transparent !important;
    border-color: transparent;
}

#boxTextLight {
    font-family: "Poppins-Light" !important;
    color: #cd1719;
    font-size: 12px !important;
    padding-left: 15px;
    font-weight: 700 !important;
}
.boxStepText {
    font-family: "Lato" !important;
    font-size: 12.27px !important;
    font-weight: 500 !important;
    color: white;
    margin-bottom: 0;
}

.boxText h3 {
    font-size: 14px !important;
    font-weight: 400 !important;
}

.boxTextRegular {
    font-family: "Poppins-Medium" !important;
}

.boxTextBold {
    font-family: "Poppins-Bold" !important;
}

.section1_Text1 {
    font-size: 19px !important;
    text-align: left;
    font-family: "SFProDisplay-Heavy-1";
}

.section1_Text2 {
    font-size: 14px !important;
    font-family: "PingFangSCRegular";
}
.section1_Text2 {
    font-size: 14px !important;
    font-family: "SFProText-Regular-1" !important;
}

.section2_Text2 {
    font-size: 23px !important;
    font-family: "SF-Compact-Rounded-Regular" !important;
    font-weight: 600 !important;
}

.section1_Text2C {
    font-size: 14px !important;
    color: white;
    line-height: 10px;
    font-family: "SFProText-Regular-1" !important;
}
#obtenerButton {
    border-radius: 26px !important;
}

.section2_Text2C {
    font-size: 25px !important;
    color: #8a898e;
    font-family: "SF-Compact-Rounded-Regular" !important;
    font-weight: 600 !important;
}
.section2_Text2B {
    font-size: 12px !important;
    color: #8a898e;
    font-family: "SF-Compact-Rounded-Regular" !important;
    line-height: 22px;
    padding-left: 10px;
}

.section2Columns {
    text-align: center;
}

.section2_Text1 {
    font-size: 11px !important;

    font-family: "SFProText-Regular-1" !important;
}

.section4_Text1 {
    font-size: 13px !important;
    color: #8a898e;
    padding-right: 66%;
    width: 280px;
    text-align: left;

    font-family: "SFProText-Regular-1" !important;
}

.section4_Text2 {
    font-size: 11px !important;

    font-family: "SFProText-Regular-1" !important;
}

.section5_Text1 {
    font-size: 15px !important;

    font-family: "SFProText-Regular-1" !important;
}

.section5_Text1B {
    font-size: 15px !important;

    font-family: "SFProText-Bold-1" !important;
}

#example-collapse-text {
    padding: 22px 20px 0 45px;
}

#example-collapse-text ul {
    position: relative;
    margin-left: 0;
}

#collapse {
    background-color: transparent !important;
    border-color: transparent !important;
    border-radius: 0px !important;
    border-top: 0px !important;
    border-right: 0px !important;
    border-left: 0px !important;
    width: 100%;

    border-block-width: 1px;
    text-align: center;
    padding-left: 9%;
}

.carouselIOS {
    position: relative !important;
    width: 95% !important;
    overflow: hidden !important;
    padding: 25px 5% 15px 5% !important;
    left: 3.25% !important;
}

.carousel-inner div {
    position: relative;
    width: 100%;
    overflow: hidden;
    border: 5px white solid;
    border-radius: 25px;
}

#collapseLine {
    border: #c6c6c8 solid 5px;
    padding-top: 20px;
    width: 95%;
    border-bottom-width: 1px;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    margin-left: 2.5%;
    display: block;
}

.columnLineA {
    border: #c6c6c8 solid 1px;
    border-top-color: rgb(198, 198, 200);
    border-top-style: solid;
    border-top-width: 1px;
    border-bottom-color: rgb(198, 198, 200);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom: 0px;
    border-top: 0px;
    border-right: 0px;
    width: 10px !important;
    padding-left: 5% !important;
    padding-right: 5% !important;
}
.columnLineB {
    border: #c6c6c8 solid 1px;
    border-top-color: rgb(198, 198, 200);
    border-top-style: solid;
    border-top-width: 1px;
    border-bottom-color: rgb(198, 198, 200);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom: 0px;
    border-top: 0px;
    border-left: 0px;
    width: 10px !important;

    padding-left: 5% !important;
    padding-right: 5% !important;
}

@media (min-width: 900px) {
    .mobile-about-app-container {
        max-width: 900px;
        margin: auto;
    }

    .mobile-about-app-container .second-container-mobile-app {
        max-width: 900px !important;
        padding-right: 15px;
        padding-left: 15px;
    }

    .mobile-about-app-container .second-container-mobile-app .row {
        margin: 0px;
    }

    .mobile-about-app-container .second-container-mobile-app .row .mobile-image-container {
        justify-content: center;
        align-items: center;
        display: flex;
        width: 30%;
        flex-grow: unset;
        min-width: 250px;
    }

    .mobile-about-app-container .second-container-mobile-app .row .mobile-image-container img {
        width: 100% !important;
        max-width: 250px;
    }

    .images-mobile-app-container {
        display: flex;
        gap: 10px;
        overflow: hidden;
        overflow-x: scroll;
        padding-bottom: 5px;
    }
    .images-mobile-app-container::-webkit-scrollbar {
        display: block;
    }

    .images-mobile-app-container::-webkit-scrollbar-thumb {
        background-color: #500b28 !important;
        border-radius: 10px !important;
    }

    .columnLineA,
    .columnLineB {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }
}

@media (max-width: 350px) {
    .circle {
        left: 42%;
        top: 91%;
        width: 30px;
        height: 30px;
    }
}
