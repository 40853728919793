$baseurl: "";
@import "@theme/mixin.scss";

.students-reviews-main-container {
  margin-top: 40px;
  padding-top: 40px;
  position: relative;
  width: 100%;
  overflow: hidden;
  h2 {
    text-align: center;
    font-size: 48px;
    font-family: "inter";
    color: #cf072c;
    font-weight: bolder;
    text-transform: uppercase;
    position: relative;
    z-index: 2;

    &::before {
      content: "QUÉ OPINAN NUESTROS ESTUDIANTES";
      font-family: "inter";
      font-weight: bold;
      position: absolute;
      z-index: 1;
      font-size: 128px;
      width: 200%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #ffffff;
      -webkit-text-stroke: 2px #cf072c; /* For Webkit browsers like Chrome and Safari */
      opacity: 0.1;
      white-space: nowrap;
    }
  }

  .content-container {
    position: relative;
    width: 100%;
    overflow: hidden;

    .wrapper {
      width: 100%;
      position: absolute;
      display: flex;
      z-index: 3;
      .reviews-cards-container {
        display: flex;
        gap: 40px;
        padding-left: 40px;

        &.slide-active {
          animation: swipe 120000ms linear infinite backwards;
        }
      }
    }
  }

  img.bg {
    width: 100%;
  }

  @include respond-to(md) {
    h2 {
      font-size: 24px;
      margin-bottom: 20px;
      &::before {
        content: "";
      }
    }
  }

  @include respond-to(sm) {
    .content-container {
      width: 100vw;
      margin-bottom: 20px;
      .wrapper {
        position: relative;
      }

      img {
        display: none;
      }
    }
  }
}

@keyframes swipe {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}
