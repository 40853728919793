$baseurl: "";
.quiz-default-info-modal {
    .modal-body {
        .quiz-info-main-container {
            * {
                margin: 0;
            }

            display: flex;
            flex-direction: column;
            gap: 10px;

            .row-quiz-default-info-modal {
                h3 {
                    font-size: 1.25rem !important;
                    font-weight: 500!important;
                }

                .word-quiz-default-container {
                    display: flex;
                    flex-wrap: wrap;

                    .word-quiz-default {
                        width: 30%;
                        min-width: 230px;

                        p {
                            font-family: "Open Sans", sans-serif !important;
                        }
                    }
                }
            }
        }
    }
}
