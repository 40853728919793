$baseurl: "";
.vocabulary-admin-main-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
        h1 {
            font-size: 2.25rem !important;
            margin: 0;
        }
    }

    .subtitle {
        h3 {
            font-size: 1rem !important;
            margin: 0;
        }
    }

    .options-btn-containers {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        .buttons-container {
            border: 1px solid #ca5050;
            border-radius: 10px;
            display: flex;
            overflow: hidden;

            div {
                max-width: 350px;
                min-width: 150px;
                padding: 10px;
                text-align: center;
                cursor: pointer;
                h3 {
                    font-size: 1rem!important;
                    color: #ca5050;
                    margin: 0;
                }
            }

            .btn-1 {
                border-right: 1px solid #ca5050;
            }
            .btn-3{
                border-left: 1px solid #ca5050;
            }

            .active {
                background-color: #ca5050;
                h3 {
                    color: white;
                }
            }
        }
        select{
            border: 1px solid #CA5050;
            border-radius: 10px;
            color: #BC4E4E;
            padding: 9px;
        }
    }

    .vocabulary-seminary-cards-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}
