$baseurl: "";
@import "@theme/mixin.scss";

@mixin modalitySwitcherOption($modality) {
	$color: null;

	@if $modality == "MATUTINO" {
		$color: #ffa333;
	} @else if $modality == "VESPERTINO" {
		$color: #0052b4;
	} @else {
		$color: #ffffff;
	}

	&::after {
		content: $modality;
		font-size: 8px;
		font-weight: bold;
		color: $color;
	}
}
@mixin modalityHeader($modality) {
	$color: null;

	@if $modality == "MATUTINO" {
		$color: #ffa333;
	} @else if $modality == "VESPERTINO" {
		$color: #c7b2d5;
	} @else {
		$color: #ffffff;
	}
	color: $color;

	&::after {
		content: $modality;
		font-size: 16px;
		font-weight: bold;
		color: $color;
		background-color: #ffffff;
		z-index: 2;
		padding: 0px 5px;
	}

	&::before {
		content: "";
		position: absolute;
		bottom: 26px;
		height: 2px;
		width: 168px;
		background-color: $color;
	}
}

.modes-main-container {
	display: flex;
	flex-direction: column;
	gap: 20px;
	overflow: hidden;
	padding-top: 40px;

	h2 {
		text-align: center;
		font-size: 48px;
		color: #001036;
		font-family: "inter";
		font-weight: bolder;
		text-transform: uppercase;
		position: relative;
		z-index: 2;

		&::before {
			content: "NUESTRAS MODALIDADES";
			font-family: "inter";
			font-weight: bold;
			position: absolute;
			z-index: 1;
			font-size: 128px;
			width: 200%;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			color: #ffffff;
			-webkit-text-stroke: 2px #001036; /* For Webkit browsers like Chrome and Safari */
			// text-stroke: 2px #001036;
			opacity: 0.1;
		}
	}

	.switcher {
		display: none;
	}

	div.table {
		max-width: 1200px;
		margin: auto;
		.table-row {
			display: grid;
			grid-template-columns: repeat(4, 1fr);

			* {
				font-family: "inter";
				margin: 0;
				text-transform: uppercase;
			}
			&.anniversary {
				background-color: #fbc754;
			}
			> div {
				padding: 15px 0px;
				display: flex;
				justify-content: center;
				align-items: center;

				&.date {
					gap: 10px;
					span.separator {
						border-right: 3px solid #cf072c;
						height: 17px;
					}
				}

				&.cost,
				&.promotion {
					flex-direction: column;
					p {
						font-size: 20px;
						font-weight: bolder;
					}

					span {
						font-size: 16px;
						font-weight: 300;
					}

					p,
					span {
						color: #79531b;
					}
				}

				&.cost {
					p {
						position: relative;

						&::before {
							content: "";
							position: absolute;
							width: 110%;
							border-bottom: 3px solid #cf072c;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
						}
					}

					p,
					span {
						color: #00103626;
					}
				}
			}

			.left {
				grid-column: 1;
				font-size: 16px;
				font-weight: 400;
				color: #001036;
			}

			.middle {
				grid-column: 2;
			}
			.right {
				grid-column: 3;
			}
			.right-2 {
				grid-column: 4;
			}

			&:not(.header) {
				.middle,
				.right,
				.right-2 {
					border-left: 4px solid #dae3ec;

					&.promotion {
						border-color: #d6ac43;
					}
				}

				.right,
				.middle,
				.right-2 {
					font-weight: bolder;
					font-size: 20px;

					&.sign-in {
						background-color: #eef4f9;
						cursor: pointer;
						flex-direction: column;
						position: relative;
						overflow: hidden;
						p,
						span {
							font-weight: 600;
							font-size: 14px;
							color: #001036;
						}

						span {
							opacity: 0;
							position: absolute;
							top: 100%;
							color: #f2cd50;
							transition: 0.25s ease all;
						}

						p {
							position: relative;
							transition: 0.25s ease transform;

							svg {
								position: relative;
								width: 12px;
								transform: translate(100%, 0);
								opacity: 0;
								transition: 0.25s ease all;
							}
						}

						@mixin commonStyles {
							background-color: #cf072c;

							&.in-person {
								p {
									transform: translate(0px, -50%);
								}
							}
							p {
								color: #ffffff;

								svg {
									opacity: 1;
									transform: translate(0%, 0);
									display: inline-block;
								}
							}

							span {
								top: 50%;
								transform: translate(0, 0%);
								opacity: 1;
							}
						}

						&:hover {
							@include commonStyles;
						}

						@include respond-to(sm) {
							&.active {
								@include commonStyles;
							}
						}
					}

					&.timer {
						font-size: 18px;
						text-decoration: underline;
						text-transform: initial;
						border: none;
					}
				}
			}

			&.anniversary {
				.left {
					position: relative;
				}
				.left::after {
					content: "Válida por tiempo limitado";
					position: absolute;
					background-color: #cf072c;
					top: 100%;
					width: 100%;
					padding: 5px 0px;
					text-align: center;
					font-family: "Times New Roman", Times, serif;
					color: #fbc754;
				}
			}

			&.header {
				.horizontal-separator {
					border-bottom: 2px solid #cf072c;
					width: 50px;
					margin: auto;
					padding: 0;
				}

				> div {
					font-size: 36px;
					font-weight: bolder;
					color: #cf072c;

					&.matutino,
					&.vespertino {
						display: flex;
						flex-direction: column;
						position: relative;
					}

					&.matutino {
						@include modalityHeader("MATUTINO");
					}
					&.vespertino {
						@include modalityHeader("VESPERTINO");
					}
				}
			}

			div {
				text-align: center;
			}
		}
	}

	@include respond-to(sm) {
		h2 {
			font-size: 24px;
			&:before {
				content: "";
			}
		}
		.switcher {
			display: flex;
			width: 90%;
			justify-content: center;
			align-items: center;
			max-width: 400px;
			min-width: 300px;
			margin: auto;
			border: 1px solid #cf072c;
			border-radius: 50px;
			height: 36px;

			> div {
				padding: 3px 20px;
				font-family: "inter";
				font-size: 0.75rem;
				text-align: center;
				height: 100%;
				flex: 1;
				text-transform: uppercase;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				font-weight: 500;

				&.active {
					background-color: #cf072c;
					border-radius: 50px;
					color: #ffffff;
				}

				&.matutino {
					@include modalitySwitcherOption("MATUTINO");
				}

				&.vespertino {
					@include modalitySwitcherOption("VESPERTINO");
				}
			}
		}
		div.table {
			.table-row {
				grid-template-columns: repeat(2, 1fr);

				&.header {
					> div {
						display: none !important;
					}
				}

				.sign-in {
					margin-top: 34px;
				}

				.left {
					font-size: 12px !important;
				}
				.right,
				.middle,
				.right-2 {
					font-size: 14px !important;

					&.promotion,
					&.cost {
						* {
							font-size: 14px !important;
						}
					}

					&.date {
						gap: 5px;
					}

					&.timer {
						font-size: 18px !important;
					}
				}

				&.anniversary {
					.left::after {
						width: 200%;
						left: 50%;
						transform: translate(-25%, 0%);
						font-size: 16px;
					}
				}
			}
		}

		&.in-person div.table .table-row {
			.middle.sign-in,
			.middle.timer {
				grid-column: span 2;
				border: none;
			}
			.right {
				display: none;
			}
			.right-2 {
				display: none;
			}
		}

		&.online1 div.table .table-row {
			.middle {
				display: none;
			}
			.right {
				grid-column: 2;
			}
			.right-2 {
				display: none;
			}

			.right.sign-in,
			.right.timer {
				grid-column: span 2;
				border: none;
			}
		}
		&.online2 div.table .table-row {
			.middle {
				display: none;
			}
			.right {
				display: none;
			}
			.right-2 {
				grid-column: 2;
			}

			.right-2.sign-in,
			.right-2.timer {
				grid-column: span 2;
				border: none;
			}
		}
	}
	@media (max-width: 877px) {
		div.table {
			.table-row {
				.right,
				.middle,
				.right-2 {
					&.date {
						font-size: 18px !important;
						gap: 5px;
					}
				}
			}
		}
	}
}

.modality-switch-main-container {
	max-width: 100vw;
	width: 100%;
	height: 3.5rem;
	display: none;
	justify-content: center;
	align-items: center;
	margin: 15px 0 15px 0;
	.modality-switch-buttons-container {
		width: clamp(fit-content, 100%, 375px);
		outline: #cf072c40 1px solid;
		border-radius: 50px;
		display: flex;
		align-items: center;
		height: inherit;
		z-index: 2;
		background-color: white;
		transition: ease;
		.modality-toggle-button {
			border-radius: inherit !important; // Override bootstrap style
			padding: 0 15px;
			height: inherit;
			width: clamp(33%, 100%, 100%);
			background-color: transparent;
			color: black;
			cursor: pointer;
			transition: ease-in-out 0.5s;
			font-size: 0.9rem !important; // Override bootstrap style
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			&.selected {
				background-color: #cf072c;
				color: white;
			}
			&.online-pm::after {
				content: "VESPERTINO";
				font-size: 0.7rem;
				color: #0052b4;
			}
			&.online-am::after {
				content: "MATUTINO";
				font-size: 0.7rem;
				color: #ffa333;
			}
		}
	}
}

@media (max-width: 1024px) {
	.modalities-table-non-responsive {
		display: none;
	}
	.modalities-table-responsive {
		display: table;
	}
	.modality-switch-main-container {
		display: flex;
	}
	.modalities-table{
		width:auto !important
	}
}

@media (min-width: 1024px) {
	.modalities-table-responsive {
		display: none;
	}
}

.modalities-table {
	max-width: 1200px;
	margin: auto;
	font-family: "inter";
	width: 100%;
	text-align: center;
	table-layout: fixed;
	tr:first-child > td {
		font-size: 36px;
		border: none;
		p {
			font-size: 36px;
			margin-bottom: 0;
		}
		div {
			font-size: 20px;
			padding: 0px 5px;
			background-color: white;
			position: relative;
			z-index: 1;
			width: fit-content;
			margin: auto;
		}
		#background-dash {
			width: 180px;
			margin: auto;
			padding: 0;
			position: relative;
			bottom: 16px;
			z-index: 0;
		}
	}
	.inscribete {
		background-color: #eef4f9;
		font-size: 14px;
		cursor: pointer;
		> svg {
			display: none;
		}
		&:hover {
			background-color: #cf072c;
			color: white;
			> svg {
				display: inline;
				transition: 25s ease all;
			}
		}
	}
	tr:last-child > td {
		font-size: 15px;
		border: none;
	}
	tr {
		th,
		td {
			padding: 15px 0;
		}
		td,
		p {
			font-weight: bolder;
			font-size: 20px;
		}
	}
	tr td {
		border-left: 4px solid #dae3ec;
		.separator {
			border-right: 3px solid #cf072c;
			margin-right: 5px;
			margin-left: 3px;
		}
	}
	#dash {
		width: 50px;
		margin: auto;
		padding: 0;
	}
	th {
		font-weight: 400;
		display: flex;
		justify-content: center;
		flex-direction: column;
		flex-wrap: wrap;
		&.schedule-header::after {
			content: "Hora de CDMX";
			font-size: smaller;
		}
	}
	.yellow-bg {
		background-color: #fbc754;
	}
	.promo-row-data {
		color: #79531b;
		p {
			font-weight: bolder;
			margin-bottom: 0;
		}
		span {
			font-size: 16px;
			font-weight: 300;
		}
	}
}
