.collaborator-subtitle-container {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
	align-items: flex-end;
}

.collaborator-subtitle-container-box {
	display: flex;
	align-items: center;
	margin: bottom 40px;
}
button.secondary-action {
	background-color: #ffffff;
	border: 1px solid #ca5050;
	color: #ca5050;
	padding: 5px 10px;
	border-radius: 20px;
	font-size: 14px;
}

button.secondary-action:hover {
	background-color: #ffffff;
	border: 3px solid #680b0b;
	color: #ca5050;
	padding: 5px 10px;
	border-radius: 20px;
	font-size: 14px;
}

button.primary-action {
	background: linear-gradient(to right, #ee1c34, #500b28);
	color: #ffffff;
	border: 1px solid;
	padding: 5px 10px;
	border-radius: 20px;
	font-size: 14px;
}
.label-dark {
	color: #000000;
}

.label-dark,
.fa {
	margin-right: 10px; /* Ajusta la separación horizontal entre el texto y el icono según tus necesidades */
	font-weight: bold;
}
@media (max-width: 601px) {
	.collaborator-subtitle-container-box {
		display: flex;
		align-items: center;
		margin: bottom 40px;
		flex-direction: column;
	}
}

.name-cell {
	position: relative;
}

.label_Rol {
	display: block;
	bottom: 0;
	left: 0;
	color: gray;
	font-size: 12px;
}

@media (max-width: 768px) {
	.name-cell {
		position: static;
	}

	.label_Rol {
		position: static;
		display: block;
		margin-top: 5px;
	}
}
