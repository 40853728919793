$baseurl: "";
.see-words-creating-personalized-quiz-modal {
    .modal-dialog {
        .modal-content {
            .modal-body {
                display: flex;
                gap: 20px;
                max-height: 500px;
                flex-wrap: wrap;
                overflow: scroll;
                padding: 15px 20px;
                min-height: 400px;

               div {
                width: 200px;
                height: fit-content;

                p {
                    margin: 0;
                }
               }
            }

            .modal-footer {
                justify-content: center;

            }
        }
    }
}
