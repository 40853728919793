$baseurl: "";
.next-payment-card-container {
    box-shadow: 1px 1px 10px 4px rgba(0, 0, 0, 0.2);
    padding: 20px 20px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    flex-wrap: wrap;
}

.next-payment-card-left-text {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.next-payment-card-right-text {
    margin: 0;
}

.next-payment-card-title {
    color: #ca5050;
}

.next-payment-line {
    display: flex;

    .question-icon {
        align-self: center;
        margin-left: 10px;
        cursor: pointer;
    }

    .question-icon:hover {
        .info-box {
            width: 40vw;
            max-width: 430px;
            min-width: 300px;
        }
    }
}

.aclaration-text {
    display: none;
}

@media (max-width: 720px) {
    .question-price-container {
        .question-icon:hover {
            .info-box {
                border-radius: 10px 10px 10px 0px;
                transform: translate(5%, -110%);
            }
        }
    }
}
