$baseurl: "";
.day-info-modal-main-container {
  .modal-dialog {
    .modal-content {
      .modal-body {
        .info-container {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .info-row {
            * {
              margin: 0 !important;
            }
            display: flex;
            align-items: center;
            font-size: 1.5rem !important;
            gap: 10px;

            p {
              font-size: 1.5rem !important;
            }
          }

          .info-row.quizzes {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            .quizzes-btn{
              width: 100%;
              display: flex;
              align-items: center;
              padding: 0;
              background-color: transparent;
              height: auto;
              justify-content: space-between;
              svg{
                transition: all 0.3s ease 0s;
              }
            }
            .quizzes-btn:hover{
              background-color: #ededed;
            }
            .date-info-quiz-card {
              box-shadow: 1px 1px 10px 4px rgba(0, 0, 0, 0.2);
              border-radius: 10px;
              padding: 5px 10px;
              color: #ca5050;
              width: 100%;
              text-align: center;
              display: flex;
              gap: 10px;
              img {
                width: 1.5rem;
              }
              .rocket{
                margin-left: auto !important;
              }
            }
          }
        }
      }
    }
  }
}
