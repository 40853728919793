$baseurl: "";
.default-quizzes-admin-main-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .actions-btn-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid #00000030;
        padding-bottom: 10px;
        flex-wrap: wrap;

       
    }

    .default-quizzes-container {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .default-quiz-card {
            border-radius: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
            background-color: #ffffff;

            .info-container {
                flex: 1;
                display: flex;
                align-items: center;
                gap: 10px;

                .image-container {
                    width: 64px;
                    height: 64px;

                    img {
                        width: 100%;
                    }
                }

                .name-container {
                    h3 {
                        font-size: 1.75rem!important;
                        margin: 0;
                    }
                }
            }

            .actions-container {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                position: relative;

                .action-btn {
                    min-width: 100px;
                    max-width: 100px;
                }

                .words-number {
                    position: absolute;
                    margin: 0;
                    left: 10px;
                    bottom: -22px;
                    font-size: 0.875rem!important;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .default-quizzes-admin-main-container {
        .default-quizzes-container {
            .default-quiz-card {
                .actions-container {
                    justify-content: flex-end;
                    width: 30%;
                    min-width: 100px;
                }
            }
        }
    }
}

@media (max-width: 483px) {
    .default-quizzes-admin-main-container {
        .actions-btn-container {
            button {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}
