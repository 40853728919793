$baseurl: "";
.modal-form-header-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;

    .steps-circles-container {
        display: flex;
        width: 200px;
        justify-content: space-between;

        .circle {
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            cursor: pointer;

            h3 {
                font-size: 1rem !important;
                margin: 0;
            }
        }

        .circle.active {
            background: linear-gradient(to right, #ee1c34, #500b28);

            h3 {
                color: white;
            }
        }
    }
}
.validationCustomTenOpt {
    display: inline !important;
    input {
        width: 1.1em;
        height: 1.1em;
        bottom: 50% !important;
        transform: translate(0px, 50%);
    }
    label {
        left: -15px;
        top: 17px;
        position: relative;

        left: 60px;
        top: -30px;
        position: relative;
    }
}

.form2-btn {
    width: 120px;
    max-width: 120px;
    padding: 0;
    min-width: auto!important;
    font-size: 1rem !important;
}

.form-recomend-container {
    gap: 10px;
}

#my-form {
    .col {
        min-width: 244px;
    }
}

@media (max-width: 992px) {
    #my-form {
        .col {
            flex-direction: row;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
        }

        .row {
            label {
                min-width: 100%;
            }
        }
    }
    .form-group {
        display: grid;
        margin-block: 12.5px;
    }
    .form-check {
        // margin-top: 25px;
        // width: 20%;
        align-items: center;
        display: flex !important;

    }
    .modal-footer {
        text-align: center;
    }
    .validationCustomTenOpt {
        label {
            left: 60px !important;
            top: -5px !important;
            position: initial !important;
        }
    }

    .info-form-modal-card {
        display: none !important;
    }

    .info-form-modal {
        display: flex !important;
    }

    .modal-form-header-container {
        justify-content: center;
    }
}

.info-form-modal-card {
    position: absolute;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    flex-direction: column;
    top: -22%;
    border: 5px solid #76102c;
    border-radius: 10px;
    left: 50%;
    width: 800px;
    transform: translate(-50%, 0);

    h2 {
        font-size: 20px;
        text-align: center;
    }

    p {
        text-align: center;
        font-size: 1rem !important;
        margin-bottom: 0px;
    }
}

.info-form-modal {
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    flex-direction: column;
    border-bottom: 1px solid #dee2e6;
    display: none;

    h2 {
        font-size: 20px;
        text-align: center;
    }

    p {
        text-align: center;
        font-size: 1rem !important;
        margin-bottom: 0px;
    }
}

.form-modal {
    .modal-content {
        border: 5px solid #76102c;
    }
}

.obligatory,
.obligatory:hover {
    color: red;
    text-decoration: none;
}

.question-icon {
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 100%;
    background-color: gray;
    color: white;
    position: relative;
}

.info-box {
    position: absolute;
    display: none;
}

.cellularStyle {
    font-size: 1rem !important;
    margin: 0;
}

.question-icon:hover {
    .info-box {
        display: block;
        width: 300px;
        /* font-size: 0.5rem; */
        background: gray;
        border-radius: 10px 10px 0px 10px;
        transform: translate(-99%, -110%);
        padding: 10px;
        text-align: left;
        p {
            font-size: 1rem !important;
        }
    }
}
