$baseurl: "";
.edit-quiz-modal-component {
  .modal-dialog {
    max-width: 1000px;
    .modal-content {
      .modal-body {
        .configuration-main-container {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(300px, 49%));
          gap: 2%;

          .c-column {
            width: 100%;
            .c-row {
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 10px;

              * {
                margin: 0;
              }

              .title {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;

                h3 {
                  font-size: 1.25rem !important;
                  margin: 0;
                }
                img {
                  width: 1.25rem;
                }
              }

              .checkboxes-container {
                display: flex;
                flex-direction: column;
                gap: 5px;
                
                .checkbox-container {
                  display: flex;
                  gap: 5px;
                  
                  label {
                    font-size: 1.125rem;
                  }
                  img {
                    width: 2rem;
                  }
                }
              }

            }
          }
        }
      }

      .modal-footer {
        justify-content: space-between;
      }
    }
  }
}

@media(max-width: 646px) {
  .edit-quiz-modal-component {
    .modal-dialog {
      .modal-content {
        .modal-body {
          .configuration-main-container {
            grid-template-columns: repeat(auto-fill, minmax(300px, 100%));
          }
        }
      }
    }
  }
}
