$baseurl: "";
@import "theme/colors", "theme/variables";

.kidssecond__section {
  .small-section {
    margin-top: $spacing;
    h2 {
      color: $color-secondary;
    }
    ul {
      padding-left: $spacing + 4px;
    }
  }

  .second-col {
    h3,
    img {
      margin-left: $spacing-3x;
    }
    h3 {
      color: $color-secondary;
    }
  }
}

@media (max-width: 1210px) {
  .kidssecond__section .second-col {
    text-align: center;
    h3,
    .kids-img {
      margin-left: 0px !important;
      margin: 0 auto;
    }

    .inscripcion {
      text-align: left;
    }
  }
}
