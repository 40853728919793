$baseurl: "";
@import 'theme/colors', 'theme/variables';

.container__questions {
  padding: $spacing-2x 0;
  .linkto {
    margin-bottom: $spacing;
    a {
      color: $color-secondary;
      font-size: $font-size-p;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
