$baseurl: "";
.super-personalized-component-main-container {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .super-personalized-test-card{
        border-radius: 10px;
        width: 100%;
        background: #ffffff;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);

        .content {
            * {
                margin: 0;
            } 

            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 20px;

            .test-row {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px 60px;

                .input-container {
                    width: 100%;

                    input,
                    textarea {
                        border: none;
                        min-width: 250px;
                    }

                    input {
                        width: 50%;
                        font-size: 1.25rem;
                        border-bottom: 2px solid #00000080;
                    }

                    input:focus {
                        border-bottom: 2px solid #ca5050;
                    }

                    textarea {
                        width: 100%;
                        min-width: 250px;
                        border: 2px solid #00000080;
                        border-radius: 10px;
                        padding: 10px;
                        resize: none;
                        font-size: 1.25rem;
                    }

                    textarea:focus {
                        border: 2px solid #ca5050;
                    }
                }
                .selects-container {
                    width: 250px;
                    .select-input-container {

                        select {
                            width: 100%;
                            border: 2px solid #00000080;
                            border-radius: 10px;
                            padding: 5px 10px;
                        }

                        select:focus {
                            border: 2px solid #ca5050;
                        }
                    }
                }

                .answer-type-container {
                    display: flex;
                    flex-direction: column;

                    label {
                        font-family: "Poppins" !important;
                        font-weight: 600 !important;
                        font-size: 1rem !important;
                        margin: 0;
                    }

                    select {
                        width: 250px;
                        padding: 10px;
                        border-radius: 10px;
                        border: 1px solid black;
                        color: black;
                    }

                    .checkboxes-container {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;

                        div {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 5px;
                        }
                    }
                }
            }

            .last {
                align-items: flex-end;
                justify-content: flex-end;
            }

            .words-row-cards-container {
                display: flex;
                flex-direction: column;
                gap: 20px;
            }
        }
    }
}