$baseurl: "";
.reject-invoice-dialog{
    height: 100%;
    display: flex;
    align-items: center;
    max-width:520px;
    .reject-invoice-content{
        height: 370px;
        .ri-modal-header{
            border-bottom: none;
            .ri-modal-title{
                display:flex;
                flex-direction: column;
                font-size: 1.7rem;
                span{
                    font-size: 1.2rem;
                    font-weight: 400;
                    margin-right: -30px;
                }
            }
        }
        .ri-modal-body{
            form{
                display:flex;
                flex-direction: column;
                row-gap:20px;
                textarea{
                    resize:none;
                    height:10rem;
                }
                .reject-btn{
                    height: 44px;
                    padding: 0px 20px;
                    font-size: 1.2rem !important;
                    background: linear-gradient(90.31deg, #ee1c34 0.26%, #500b28 99.73%);
                    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
                    color:white;
                    width:fit-content;
                    margin-left:auto;
                }
                .disabled-btn{
                    background:"grey";
                }
            }
        }
    }
}