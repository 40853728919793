$baseurl: "";
.card-container {
    box-shadow: 1px 1px 10px 4px rgba(0, 0, 0, 0.2);
    padding: 30px 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.card-container .btn-olvidar-container {
    margin-left: auto;
}

.new-card-container {
    min-height: 136px;
    cursor: pointer;
}

.new-card-container div p {
    margin-bottom: 0;
    color: #CA5050;
}

.new-card-form-container {
    width: 60%;
    margin: auto;
}

.add-btn-card {
    margin-bottom: 60px;
    float: right;
}

::-webkit-scrollbar {
    display: none;
}

@media (max-width: 500px) {
    .card-container {
        flex-direction: column;
        justify-content: center;
    }

    .card-container .btn-olvidar-container {
        margin: auto;
    }

    .add-btn-card {
        float: none;
    }

    .new-card-form-container {
        width: 90%;
    }

    .row div.col{
        text-align: center;
    }
}