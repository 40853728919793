$baseurl: "";
@import "@theme/mixin.scss";

.institution-description-main-container {
  margin: 40px auto;
  margin-bottom: 80px;
  text-transform: uppercase;
  font-family: "inter";
  font-size: 36px;
  max-width: 1100px;
  text-align: center;
  padding: 0px 15px;

  .separator {
    width: 50px;
    height: 2px;
    background-color: #cf072c;
    margin: auto;
    margin-bottom: 50px;
  }

  span {
    font-weight: bolder;
    color: #cf072c;
  }

  @include respond-to(sm) {
    font-size: 20px;
  }
}
