$baseurl: "";
@import 'theme/colors', 'theme/variables';

.header__program {
  background: $color-primary;
  padding: $spacing-2x 0 2px 0;
  color: $color-white !important;
  position: relative;

  h1 {
    color: $color-white;
  }

  button {
    width: 100%;
    height: 100%;
    border-radius: 0px !important;
    text-transform: uppercase;
    cursor: default !important;
    span {
      font-size: $font-size-h1 - 10px !important;
      font-weight: $font-weight-medium;
    }
  }
  // h2 {
  //   padding-top: 10px;
  //   padding-bottom: 10px;
  //   font-size: $font-size-h1 - 10px !important;
  //   //color: $color-white;
  //   //background-color: $color-white;
  //   border: none;
  //   outline: none;
  //   transform: translate(0);
  //   .text {
  //     -webkit-background-clip: text;
  //     -webkit-text-fill-color: transparent;
  //     background-image: linear-gradient(45deg, $color-left, $color-right);
  //   }
  //   &:after {
  //     content: '';
  //     position: absolute;
  //     margin: 1px;
  //     top: 0;
  //     left: 0;
  //     bottom: 0;
  //     right: 0;
  //     z-index: -1;
  //     background: white;
  //   }
  //}

  .description {
    margin-top: $spacing;
    p {
      font-size: 20px;
    }
  }
  .wave {
    width: 100%;
  }
}
.section__program {
  margin-top: $spacing-4x * 1.3 !important;
  margin-bottom: $spacing-4x !important;

  .modules__navbar {
    margin-bottom: $spacing;
    .nav-link {
      margin-right: $spacing-2x;
    }
  }
}
@media screen and (min-width: 300px) and (max-width: 500px) {
  .header__program {
    button {
      span {
        font-size: $font-size-h1 - 20px !important;
      }
    }
  }
}
