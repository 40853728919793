$baseurl: "";
@import "/src/theme/mixin.scss";

.modules-slide-main-container {
  flex: 0 0 95%;
  border-radius: 24px;
  margin: 0px 0%;
  margin-right: 2.5%;
  margin-left: 2.5%;
  display: grid;
  grid-template-columns: 40% auto 40%;
  padding: 100px 40px 60px 40px;
  position: relative;
  aspect-ratio: 254/131;
  overflow: hidden;

  .info-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;

    > * {
      margin: 0;
    }

    button {
      color: #ffffff;
      font-size: 20px !important;
      font-family: "inter" !important;
      font-weight: 600;
      border-radius: 50px !important;
      padding: 10px 20px;
    }

    &.I,
    &.IV {
      grid-column: 3;
    }

    &.II,
    &.III,
    &.V {
      grid-column: 1;
    }

    h2 {
      font-size: 48px;
      font-family: "inter";
      font-weight: bolder;
    }

    h4,
    ul li {
      font-size: 20px;
      font-family: "inter";
    }

    h4.title {
      font-size: 28px;
      font-weight: 600;
      color: #414141;
    }

    ul {
      padding-left: 20px;
    }
  }
  &.I {
    background-color: #eef4f9;
    h2 {
      color: #cf072c;
    }

    button {
      background-color: #cf072c;
    }
  }

  &.II {
    background-color: #f2f2f2;
    button {
      background-color: #000000;
    }
  }

  &.III {
    background-color: #fcf8ff;
    h2 {
      color: #6c567b;
    }
    button {
      background-color: #6c567b;
    }
  }

  &.IV {
    background-color: #fdf2f4;
    h2 {
      color: #f67280;
    }

    button {
      background-color: #f67280;
    }
  }

  &.V {
    background-color: #f3f9ff;
    h2 {
      color: #6b7b8e;
    }

    button {
      background-color: #6b7b8e;
    }
  }

  @include respond-to(l) {
    padding: 50px 20px 30px 20px;

    .info-container {
      gap: 10px;
      h2 {
        font-size: 36px;
      }

      h4,
      ul li {
        font-size: 16px;
      }

      button {
        font-size: 16px !important;
      }
    }
  }

  @media (max-width: 1100px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    height: fit-content;
    aspect-ratio: auto;

    .info-container {
      grid-column: 1 !important;
      grid-row: 1;

      justify-content: center;
      align-items: center;
    }
  }

  @include respond-to(sm) {
    .info-container {
      h4.title {
        font-size: 18px;
        text-align: center;
      }

      ul li,
      button {
        font-size: 14px !important;
      }
    }
  }
}
