$baseurl: "";
.pronunciation-modal {
    .modal-dialog {
        .modal-content {
            .modal-body {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 50px 0px;
                gap: 20px;

                .pronunciation-input-container {
                    width: 70%;
                    min-width: 300px;

                    label {
                        font-size: 1.25rem;
                        font-weight: bolder;
                    }

                    div {
                        width: 100%;
                        border: 1px solid black;
                        border-radius: 10px;
                        display: flex;
                        align-items: center;
                        padding: 0px 5px;
                        gap: 5px;

                        input,
                        select {
                            width: 100%;
                            padding: 5px 0px;
                            border-radius: 10px;
                            border: none;
                            font-size: 1.25rem;
                        }

                        img {
                            width: 1.25rem;
                            height: 1.25rem;
                        }
                    }
                }
            }

            .modal-footer {
                justify-content: space-between;

                button {
                    background-color: white;
                    border: 1px solid #ca5050;
                    padding: 0px;
                    height: auto;
                    color: #ca5050;
                    width: 200px;
                    padding: 10px;
                }

                .pronunciate-word {
                    color: white;
                    background: linear-gradient(to right, rgb(238, 28, 52), rgb(80, 11, 40));
                }
            }
        }
    }
}

@media (max-width: 450px) {
    .pronunciation-modal {
        .modal-dialog {
            .modal-content {
                .modal-footer {
                    flex-direction: column;
                    justify-content: center;

                    .pronunciate-word {
                        order: 1;
                    }

                    .close-modal-pronunciation {
                        order: 2;
                    }
                }
            }
        }
    }
}
