$baseurl: "";
.progress-card-container {
    box-shadow: 1px 1px 10px 4px rgba(0, 0, 0, 0.2);
    padding: 20px 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.progress-bar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.progress-bar-outside-text {
    margin: 0;
}

.title-progress-card {
    font-size: 1.5rem!important;
    margin: 0;
    color: #CA5050;
}

#link-to-facturacion{
    text-decoration: underline;
    cursor:pointer;
    color:inherit;
    text-wrap: nowrap;
}

.subtitle-progress-card {
    margin-bottom: 30px;
}

.new-payment-button {
    height: auto;
    padding: 20px;
    width: 60%;
    max-width: 450px;
    min-width: 300px;
    margin: auto;
    background-color: white;
    box-shadow: 1px 1px 10px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
        font-size: 0.75rem;
    }
}

.new-payment-button.active {
    background: linear-gradient(to right, #ee1c34, #500b28) !important;
	color: white;
}

@media (max-width: 548px) {
    .new-payment-title-row {
        flex-direction: column;
    }

}