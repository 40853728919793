$baseurl: "";
.generations-card {
    box-shadow: 1px 1px 10px 4px rgba(0, 0, 0, 0.2);
    padding: 25px 20px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    
}

.generations-card .generations-card-left h2 {
    font-size: 1.375rem;
    color: #ca5050;
    font-weight: 700;
    margin: 0;
}

.generations-card .generations-card-left p {
    margin: 0;
    font-size: 1rem;
    color: black;
    font-weight: 500;
}

.generations-card .generations-card-right button,
.edit-gen-card div button {
    background-color: transparent;
    padding: 10px;
    width: auto;
    min-width: 90px;
    height: auto;
    border: 2px solid #ca5050;
    color: #ca5050;
    /* font-size: 1rem!important; */
    font-weight: 500;

}

@media (max-width: 421px) {
    .generations-card {
        box-shadow: 1px 1px 10px 4px rgba(0, 0, 0, 0.2);
        padding: 25px 20px;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        flex-direction: column;
    }
    .generations-card .generations-card-right button,
    .edit-gen-card div button {
        background-color: transparent;
        padding: 10px;
        width: auto;
        min-width: 90px;
        height: auto;
        border: 2px solid #ca5050;
        color: #ca5050;
        /* font-size: 1rem!important; */
        font-weight: 500;
        flex-direction: column;
        
    
    }
    .generations-card .generations-card-right{
        flex-direction: column;
        margin-top: 5px;



    }
}