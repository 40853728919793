$baseurl: "";
.vocabulary-seminary-admin-main-container,
.vocabulary-language-admin-main-container {
	display: flex;
	flex-direction: column;
	gap: 20px;
	.button-container {
		display: flex;
		justify-content: space-between;
		.move-btn {
			display: flex;
			flex-direction: column;
			gap: 10px;
			.title-sem {
				font-size: 14px;
				text-align: center;
				margin: 0;
			}
			.buttons-back-for {
				display: flex;
				gap: 20px;
				@media (max-width: 450px) {
					gap: 10px;
				}
			}
			.btn-back {
				cursor: pointer;
				width: fit-content;
				padding: 0px;
				height: auto;
				font-size: 1rem !important;
				padding: 5px 20px;
				background-color: #ffffff;
				color: #ca5050;
				border-radius: 10px;
				border: 1px solid #ca5050;
				margin-bottom: 20px;
				display: flex;
				gap: 5px;
				align-items: center;
				@media (max-width: 450px) {
					font-size: 0.875rem !important;
				}
			}
			.btn-forward {
				cursor: pointer;
				width: fit-content;
				padding: 0px;
				height: auto;
				font-size: 1rem !important;
				padding: 5px 20px;
				background-color: #ca5050;
				color: #ffffff;
				border-radius: 10px;
				border: 1px solid #ca5050;
				margin-bottom: 20px;
				display: flex;
				gap: 5px;
				align-items: center;
				@media (max-width: 450px) {
					font-size: 0.875rem !important;
				}
			}
			.dis-back {
				cursor: not-allowed;
				color: rgb(125, 125, 125);
				border: 1px solid rgb(125, 125, 125);
			}
			.dis-for {
				cursor: not-allowed;
				background-color: rgb(125, 125, 125);
				border: 1px solid rgb(125, 125, 125);
			}
		}
	}
	.title {
		h1 {
			font-size: 2.25rem !important;
			margin: 0;
		}
	}

	.subtitle {
		h3 {
			font-size: 1rem !important;
			margin: 0;
		}
	}

	a {
		&:hover {
			text-decoration: none;
		}
		.row-container {
			.config-test-card {
				background-color: #ffffff;
				border-radius: 10px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
				padding: 10px 20px;

				.title {
					h3 {
						color: #ca5050;
						margin: 0;
						font-size: 1rem !important;
					}
				}
				.icon {
					width: auto;
					display: flex;
					gap: 5px;
					height: 64px;

					.default-quizzes-container {
						position: relative;

						.number-of-default-quizzes-container {
							position: absolute;
							height: 25px;
							width: 25px;
							border-radius: 100%;
							background-color: #ffffff;
							display: flex;
							align-items: center;
							justify-content: center;
							bottom: 0px;
							right: 0px;
							transform: translate(0%, 30%);

							h3 {
								text-align: center;
								font-size: 1rem !important;
								margin: 0%;
								font-weight: bolder !important;
							}
						}
					}

					div {
						img {
							height: 100%;
							width: 100%;
							cursor: pointer;
						}
					}
				}
			}
		}
	}

	.words-container {
		position: relative;
		display: flex;
		flex-direction: column;
		height: 82vh;
		overflow: auto;
		box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
		border-radius: 10px;
		padding: 20px;
		padding-top: 0px;
		.filter-container {
			gap: 10px;
			padding-top: 20px;
			position: sticky;
			top: 0;
			display: flex;
			justify-content: space-between;
			align-items: center;
			background-color: #ffffff;

			.left {
				display: flex;
				select {
					padding: 9px;
					border: 1px solid #ca5050;
					border-radius: 10px 0px 0px 10px;
					color: #bc4e4e;
					height: 44px;
				}
				.search-container {
					position: relative;
					margin: auto;
					height: 44px;

					img {
						position: absolute;
						top: 50%;
						padding-inline: 22px;
						transform: translateY(-50%);
					}
					input {
						padding: 9px 101px 9px 54.5px;
						border: 1px solid rgba(0, 0, 0, 0.3);
						border-radius: 0px 10px 10px 0px;
					}
				}
			}
			button {
				background: linear-gradient(90.31deg, #ee1c34 0.26%, #500b28 99.73%);
				box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
				color: white;
				padding: 0;
				max-width: 300px;
				min-width: 160px;
				width: 100%;
				font-size: 1.25rem !important;
				height: 44px;
			}
		}
		.table {
			margin-top: 21px;
			border-top: transparent 2px solid;
			thead {
				position: sticky;
				background: #ffffff;
				top: 63px;
			}
			tbody {
				tr {
					border: transparent 2px solid;
					.difficult-circle {
						border-radius: 50%;
						width: 1rem;
						height: 1rem;
						margin: auto;
						&:hover {
							.difficult-circle-message {
								display: flex;
								background-color: white;
								border: 2px solid;
								border-radius: 5px;
								transform: translate(-164px, 10px);
								width: max-content;
							}
						}
						.difficult-circle-message {
							display: none;
							position: absolute;
						}
					}
					.row-languages {
						display: flex;
						align-items: center;
						gap: 10px;
						border: none;
						img {
							width: 18px;
							cursor: pointer;
						}
					}
					td {
						p {
							font-size: 16px !important;
							margin: 0;
						}
						img {
							cursor: pointer;
						}
					}
					.expert {
						color: #ca5050;
					}
					.hard {
						color: #c75f5f;
					}
					.easiest {
						color: #0cdfa7;
					}
					.easy {
						color: #02a377;
					}
					.normal {
						color: #fe9a12;
					}
				}
			}
		}
	}
}
.seminary-word-modal {
	.modal-dialog {
		max-width: 737px;
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
		.modal-content {
			border-radius: 10px;
			.modal-body {
				.modal-form {
					margin-top: 15px;
					margin-block-end: 98px;
					display: flex;
					flex-direction: column;
					gap: 20px;
					.modal-row {
						display: flex;
						justify-content: space-between;
						gap: 20px;
						.modal-col {
							display: flex;
							flex-direction: column;
							width: 100%;
							label {
								margin: 0;
								font-weight: 600;
							}
							legend {
								font-size: 0.875rem;
							}
							select,
							input {
								border: 1px solid rgba(0, 0, 0, 0.3);
								border-radius: 10px;
								padding: 8px 20px;
								width: 100%;
								-webkit-appearance: none;
								-moz-appearance: none;
							}

							select:not([multiple]) {
								background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
								background-position: right 0px center;
								background-repeat: no-repeat;
							}

							.warning-note-inputs-container {
								display: flex;
								border: 1px solid #ced4da;
								border-radius: 0.25rem;
								padding: 0rem 0.25rem;
								align-items: center;
		
								img {
									width: 1rem;
									height: 1rem;
								}
								select {
									width: 100%;
									padding: 0.25rem;
									border-radius: 7px 0px 0px 7px;
									border: none;
								}
		
								input {
									border-radius: 0px 7px 7px 0px;
									border: none;
									border-left: 1px solid #ced4da;
								}
		
								.form-control:focus {
									box-shadow: none;
								}
							}
						}
					}
				}
			}
			.modal-footer {
				.buttons {
					display: flex;
					gap: 20px;
					.close {
						border: 1px solid #ca5050;
						border-radius: 10px !important;
						padding-inline: 18px;
						color: #bc4e4e;
						font-size: 16px !important;
					}
					.continue {
						background: linear-gradient(90.31deg, #ee1c34 0.26%, #500b28 99.73%);
						box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
						border-radius: 10px !important;
						color: white;
						font-size: 16px !important;
					}
				}
			}
		}
	}
}
