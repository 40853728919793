$baseurl: "";
.consult-send-notification-container {
    position: fixed;
    width: 100%;
    left: 0;
    top: 77px;
    background-color: #ca5050;
    max-height: 0px;
    overflow: hidden;
    transition: 1s ease all;
    z-index: 1001;

    div {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;

        h3 {
            font-size: 1rem !important;
            color: #ffffff;
            margin: 0;
            margin-left: auto;
        }
    }
}

.go-back-container {
    p {
        cursor: pointer;
    }

    p:hover {
        text-decoration: underline;
    }
}

.open-consult-notification {
    max-height: 100px;
    transition: 1s ease all;
}

.consult-post-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .admin-mode {
        font-family: "Poppins", sans-serif;
        font-weight: 300 !important;
        font-size: 1rem !important;
        color: #bc4e4e;
        display: inline-block;
    }

    .right-text {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;

        h3 {
            margin: 0;
            font-family: "Poppins", sans-serif !important;
            font-style: normal;
            font-weight: 500 !important;
            font-size: 1rem !important;
        }

        p {
            margin: 0;
            font-family: "Lato", sans-serif !important;
            font-style: normal;
            font-weight: 300 !important;
            font-size: 0.875rem !important;
        }
    }
}

.text-container {
    .quill {
        img {
            width: 100%;
        }
    }
}

.write-consult-btn {
    margin: 0;
    height: auto;
    width: auto;
    font-size: 1rem !important;
    padding: 10px 20px;
    border-radius: 10px !important;
    background-color: transparent;
    border: 2px solid #ca5050;
    color: #ca5050;
    font-weight: bolder;
    margin-bottom: 20px;
}

.write-consult-btn-open {
    background-color: #ca5050;
    color: white;
}

.doubt-container {
    max-width: 950px;
    margin: auto;
    margin-bottom: 20px;
    position: relative;

    h3 {
        margin: 0;
        font-family: "Poppins", sans-serif !important;
        font-style: normal;
        font-weight: 500 !important;
        font-size: 1rem !important;
        margin-bottom: 10px;
    }
    .header-seminaries-checkboxes {
        width: 100%;
        margin: 20px 0px;
        h2 {
            font-size: 1rem !important;
            margin: auto;
        }
    }

    .seminaries-selection-container {
        border: 1px solid #00000040;
        border-radius: 10px;
        padding: 10px;
        max-width: 220px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;

        p {
            margin: 0;
            font-size: 0.875rem !important;
        }

        .icon1 {
            width: 0.875rem;
            transform: rotate(0deg);
            transition: 0.5s ease all;
        }

        .show {
            transform: rotate(180deg);
            transition: 0.5s ease all;
        }
    }

    .seminary-checkboxes-main-container {
        border-radius: 10px;
        width: 170px;
        box-shadow: 0px 4px 20px #00000040;
        position: absolute;
        z-index: 1020;
        background-color: white;
        transform: translate(0px, 5px);
        max-height: 0px;
        overflow: hidden;
        transition: 0.5s ease all;

        .seminary-checkboxs-container {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
            grid-gap: 10px;
            gap: 10px;
            overflow: scroll;
            padding: 10px;

            .item-seminary {
                width: 50px;
                display: flex;
                gap: 5px;
                align-items: center;
                justify-content: flex-start;
                margin: auto;

                label {
                    margin: 0;
                }
            }
        }
    }

    .show {
        max-height: 1100px;
        transition: 0.5s ease all;
    }
}

.consult-post-revision-container {
    max-width: 950px;
    margin: auto;
    margin-bottom: 20px;

    h3 {
        margin: 0;
        font-family: "Poppins", sans-serif !important;
        font-style: normal;
        font-weight: 500 !important;
        font-size: 1rem !important;
        margin-bottom: 10px;
    }
}
.consultpost-card {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 20px #00000040;
    margin: auto;
    margin-bottom: 20px;
    position: relative;

    .solved-text {
        font-size: 1rem !important;
        position: absolute;
        right: 10px;
        top: 10px;
        font-weight: bolder;
        color: black;
        align-items: center;
        img{
            padding-bottom: 2px;
        }
    }

    .content {
        padding: 20px;
        padding-bottom: 0px;

        .header {
            p {
                font-weight: 700 !important;
                font-size: 1rem !important;
            }
        }

        .post-container {
            display: flex;
            gap: 10px;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 15px;

            .profile-pic-container {
                width: 42px;
                height: 42px;
                min-width: 42px;
                border-radius: 100%;
                position: relative;

                img {
                    position: absolute;
                    width: 50px;
                    height: 50px;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }

                h3 {
                    position: absolute;
                    color: #ffffff;
                    left: 50%;
                    top: 50%;
                    font-weight: 300 !important;
                    margin: 0 !important;
                    text-align: center;
                    transform: translate(-50%, -50%);
                }
            }

            .post-text-container {
                width: 100%;
                max-width: calc(100% - 52px);

                .post-info-container {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-end;
                    gap: 10px;
                    flex-wrap: wrap;
                    margin-bottom: 15px;

                    h3 {
                        font-weight: 500 !important;
                        font-size: 0.875rem !important;
                        margin: 0;
                        line-height: 1rem;
                    }

                    .admin {
                        color: #d32d2d;
                    }

                    img {
                        width: 1.125rem;
                        height: 1.125rem;
                    }

                    p {
                        font-weight: 300 !important;
                        font-size: 0.75rem !important;
                        margin: 0;
                        line-height: 1rem;
                    }

                    .verification-answer-container {
                        display: flex;
                        margin-left: auto;
                        gap: 10px;
                    }
                }

                .options-post-container {
                    display: flex;
                    gap: 20px;
                    align-items: center;
                    margin-top: 10px;
                    flex-wrap: wrap;

                    .option-btn-container {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 5px;
                        cursor: pointer;
                        width: fit-content;
                        position: relative;

                        h3 {
                            margin: 0;
                            font-size: 0.875rem !important;
                            color: #ca5050;
                        }

                        img {
                            width: 19px;
                        }

                        .didnotunderstand-names-container {
                            position: absolute;
                            right: 0;
                            top: 20px;
                            padding: 10px 20px;
                            border-radius: 10px;
                            box-shadow: 0px 4px 20px #00000040;
                            background-color: #ffffff;
                            display: none;
                            z-index: 1000;

                            p {
                                font-size: 0.875rem !important;
                                margin: 0;
                            }
                        }
                    }

                    .admin-names-didnotUnderstand {
                        h3 {
                            color: black;
                            font-weight: bolder;
                        }
                    }

                    .admin-names-didnotUnderstand:hover {
                        h3 {
                            cursor: pointer;
                        }
                        .didnotunderstand-names-container {
                            display: block;
                        }
                    }
                }

                .answer-text-editor-container {
                    max-height: 0px;
                    overflow: hidden;
                    opacity: 0;
                    transition: 0.5s ease all;
                }

                .answer-text-editor-container-open {
                    max-height: 500px;
                    margin-top: 10px;
                    overflow: visible;
                    opacity: 1;
                    transition: 1s ease all;
                }

                .text-container {
                    .quill {
                        .ql-bubble {
                            .ql-hidden {
                                display: none;
                            }
                        }
                    }
                }

                .hashtag-flag-container {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 10px;
                    margin-top: 10px;

                    .hashtag-container {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 10px;

                        p {
                            font-weight: 400 !important;
                            font-size: 0.875rem !important;
                            line-height: 1rem;
                            color: #ca5050;
                            text-decoration: underline;
                            cursor: pointer;
                            margin: 0;
                        }
                    }

                    .flags-container {
                        display: flex;
                        gap: 10px;

                        img {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
                .admin-options-container {
                    display: flex;
                    gap: 20px;
                    align-items: center;
                    flex-wrap: wrap;

                    .option-btn-container {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 5px;
                        cursor: pointer;
                        width: fit-content;

                        h3 {
                            margin: 0;
                            font-size: 0.875rem !important;
                            color: #ca5050;
                        }

                        img {
                            width: 19px;
                        }
                    }
                }

                .text-editor-container {
                    max-height: 0px;
                    opacity: 0;
                    transition: 0.5s ease all;
                    overflow: hidden;

                    .select-answer-type-consult {
                        margin: 20px 0;
                        display: flex;
                        gap: 10px;
                        flex-wrap: wrap;

                        button {
                            border: 1px solid #ca5050;
                            color: #ca5050;
                            background-color: white;
                            padding: 0px;
                            width: 45%;
                            max-width: 150px;
                        }

                        .active {
                            color: white;
                            background-color: #ca5050;
                        }
                    }

                    .consult-answer-voice-note-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: start;
                        gap: 15px;

                        button {
                            align-self: end;
                        }

                        .delete-record {
                            width: 50px;
                            padding: 0px;
                            border-radius: 100px!important;
                            margin-left: 15px;
                            background-color: #c93838;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            img {
                                width: 25px;
                                filter: invert(100%) sepia(0%) saturate(7498%) hue-rotate(321deg) brightness(104%) contrast(101%);
                            }
                        }
                    }

                    .header-seminaries-checkboxes {
                        width: 100%;
                        margin: 20px 0px;
                        h2 {
                            font-size: 1rem !important;
                            margin: auto;
                        }
                    }

                    .seminaries-selection-container {
                        border: 1px solid #00000040;
                        border-radius: 10px;
                        padding: 10px;
                        max-width: 220px;
                        cursor: pointer;
                        display: flex;
                        justify-content: space-between;

                        p {
                            margin: 0;
                            font-size: 0.875rem !important;
                        }

                        .icon1 {
                            width: 0.875rem;
                            transform: rotate(0deg);
                            transition: 0.5s ease all;
                        }

                        .show {
                            transform: rotate(180deg);
                            transition: 0.5s ease all;
                        }
                    }

                    .seminary-checkboxes-main-container {
                        border-radius: 10px;
                        width: 170px;
                        box-shadow: 0px 4px 20px #00000040;
                        position: absolute;
                        z-index: 100;
                        background-color: white;
                        transform: translate(0px, 5px);
                        max-height: 0px;
                        overflow: hidden;
                        transition: 0.5s ease all;

                        .seminary-checkboxs-container {
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-start;
                            flex-direction: column;
                            grid-gap: 10px;
                            gap: 10px;
                            height: 300px;
                            overflow: scroll;
                            padding: 10px;

                            .item-seminary {
                                width: 125px;
                                display: flex;
                                gap: 5px;
                                align-items: center;
                                justify-content: flex-start;
                                margin: auto;

                                label {
                                    margin: 0;
                                }
                            }
                        }
                    }

                    .show {
                        max-height: 300px;
                        transition: 0.5s ease all;
                    }
                }

                .text-editor-container-open {
                    max-height: 1000px;
                    opacity: 1;
                    transition: 0.5s ease all;
                }

                .answers-container {
                    margin-bottom: 10px;
                    display: flex;
                    gap: 10px;

                    img {
                        width: 1rem;
                        height: 1rem;
                        transform: rotate(0deg);
                        transition: 0.5s ease all;
                    }

                    .open {
                        transform: rotate(180deg);
                        transition: 0.5s ease all;
                    }

                    p {
                        font-weight: 500 !important;
                        font-size: 0.875rem !important;
                        line-height: 1rem;
                        color: #ca5050;
                        cursor: pointer;
                        margin: 0;
                    }
                }
            }

            .post-answers-container {
                max-height: 0px;
                overflow: hidden;
                transition: 0.25s ease all;
            }

            .post-answers-container-open {
                max-height: 1000px;
                overflow: scroll;
                transition: 0.5s ease all;
            }
        }
    }

    .footer {
        display: flex;
        border-top: 1px solid #00000020;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 10px;
        cursor: pointer;

        p {
            font-family: "Lato", sans-serif !important;
            font-style: normal;
            font-weight: 500 !important;
            font-size: 0.875rem !important;
            color: #bc4e4e;
            margin: 0;
        }
    }

    .check-container {
        p {
            font-style: italic !important;
            font-weight: 500 !important;
            font-size: 0.875rem !important;
            color: #00000060;
        }
    }
}

@media (max-width: 694px) {
    .consultpost-card {
        .content {
            .post-container {
                .post-text-container {
                    .hashtag-flag-container {
                        flex-wrap: wrap;
                        gap: 15px;

                        .hashtag-container {
                            order: 1;
                        }

                        .flags-container {
                            order: 2;
                        }

                        .admin-options-container {
                            order: 3;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .consult-last-post {
        .content {
            .post-container {
                .profile-pic-container {
                    width: 21px !important;
                    height: 21px !important;
                    min-width: auto !important;

                    h3 {
                        font-size: 0.7rem !important;
                    }

                    img {
                        width: 25px !important;
                        height: 25px !important;
                    }
                }
            }
        }
    }

    .consultpost-card {
        .content {
            .post-container {
                .post-text-container {
                    max-width: calc(100% - 42px);

                    .post-info-container {
                        flex-wrap: wrap;
                        align-items: center;
                        padding-right: 5px;
                    }
                }
            }
        }
    }
}
