$baseurl: "";
.generate-invoice-modal {
    max-width: fit-content !important;
	.gi-modal-content {
		width: fit-content;
		.gi-modal-header {
			.gi-modal-title {
				color: #ca5050;
			}
		}
		.gi-modal-body {
			.gi-form-grid {
				display: grid;
				gap: 15px;
				.gi-form-group {
					display: flex;
					flex-direction: column;
					width:300px;
					label {
						color: #707070;
						font-weight: 300;
						font-size: 18px;
						margin-bottom: 0;
					}
					input,
					select {
						border: 1px solid rgba(0, 0, 0, 0.3);
						border-radius: 10px;
						height: 40px;
						padding: 5px;
						width: 100%;
					}
					.gi-form-subgroup {
						flex-direction: column;
						display: flex;
						width: 49%;
					}
				}
				.corporate-name{
					grid-column-start: 1;
					grid-column-end: span 2;
					width: 100%;
					.note{
						font-size:0.8rem;
						color:black;
						font-weight:600;
					}
				}
				.gi-form-group-2 {
					flex-direction: row;
					justify-content: space-between;
				}
				.left {
					grid-column: 1;
				}
				.right {
					grid-column: 2;
				}
			}
		}
		.gi-modal-footer {
			display: flex;
			justify-content: flex-end;
			margin: 20px 20px;
			gap: 15px;
			button {
				height: 44px;
                border-radius: 10px;
                padding: 0px 20px;
                font-size: 1.2rem !important;
			}
			.discard-btn {
				border: 1px solid #ca5050;
                color:#CA5050;
                background-color: transparent;
			}
			.generate-btn {
				background: linear-gradient(90.31deg, #ee1c34 0.26%, #500b28 99.73%);
				box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
                color:white;
			}
			.disabled-btn{
				background:"grey";
			}
		}
	}
}
@media screen and (max-width:750px){
    .gi-form-group {
        width:200px !important;
    }
}
@media screen and (max-width:475px){
    .gi-form-group {
        width:160px !important;
    }
}