$baseurl: "";
.generate-receipt-modal {
    .modal-dialog {
        max-width: 1100px !important;

        .modal-content {
            .modal-body {
                margin: 0;
                padding: 0;
                ::-webkit-scrollbar {
                    display: block;
                }
            
                ::-webkit-scrollbar-thumb {
                    background: linear-gradient(90.31deg, #ee1c34 0.26%, #500b28 99.73%);
                    border-radius: 10px;
                }

                .modify-receipt-container {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    padding: 20px 40px;

                    label {
                        margin: 0!important;
                        font-weight: bolder;
                    }

                    .row-container {
                        display: flex;
                        flex-direction: column;
                        width: 100%;

                        .input-container {
                            border: 2px solid #cacaca;
                            padding: 5px 10px;
                            border-radius: 5px;

                            input {
                                width: 100%;
                                border: 0;
                            }
                        }
                    }
                }
            }
        }

        .modal-footer {
            display: flex;
            justify-content: space-between;

            button:disabled {
                background: none;
                background-color: #cacaca!important;
            }
        }
    }
}
