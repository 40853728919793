$baseurl: "";
.Vocabulary-generation-admin{
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
        h1 {
            font-size: 2.25rem !important;
            margin: 0;
        }
    }
    .generations-container{
        position: relative;
        display: flex;
        flex-direction: column;
        height: 600px;
        overflow: auto;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 20px;
        padding-top: 0px;
        .filter-content{
            padding-top: 20px;
            position: sticky;
            top: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #ffffff;
            .filter-input{
                position: relative;
                padding-block: 9px;
                border: 1px solid rgba(0, 0, 0, 0.3);
                border-radius: 10px;
                width: 331px;
                input{
                    border: none;
                    width: 100%;
                    padding-left: 54px;
                }
                img{
                    position: absolute;
                    left: 22.25px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
        .table{
            margin-top: 21px;
            border-top: transparent 2px solid;
            tbody{
                tr{
                    border: transparent 2px solid;
                }
                img{
                    cursor: pointer;
                }
            }
        }
    }
}



    .modal-vocabulary-admin-gen {
        .modal-dialog {
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
            .modal-content{
                .modal-header{
                    display: flex;
                    flex-direction: column;
                    p{
                        margin: 0;
                    }
                }
                border-radius: 10px;
                .modal-body{
                    .progress-container{
                        display: flex;
                        flex-direction: column;
                        gap: 17px;
                        h1{
                            font-size: 16px !important;
                            margin: 0 !important;
                        }
                        .progess-user-container{
                            display: flex;
                            flex-direction: column;
                            gap: 17px;
                            .progress-row{
                               .flex{
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                gap: 20px;
                                .progress-bar-bg{
                                    background: #FFFFFF;
                                    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
                                    border-radius: 100px;
                                    height: 10px;
                                    width: 100%;
                                    .progress-bar-fill{
                                        background: linear-gradient(90.31deg, #EE1C34 0.26%, #500B28 99.73%);
                                        border-radius: 100px;
                                        height: 100%;
                                        width: 0px;
                                    }
                                }
                                p{
                                    margin: 0;
                                    font-weight: 600;
                                    font-size: 14px !important;
                                }
                               }
                            }
                        }
                        .words-container{
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            p{
                                font-size: 14px !important;
                                margin: 0;
                            }
                            .top-section-pagination{
                                display: flex;
                                justify-content: space-between;
                                .pagination{
                                    user-select: none;
                                    display: flex;
                                    align-items: center;
                                    gap: 5px;
                                    .FontAwesomeIcon{
                                        cursor: pointer;
                                    }

                                    svg {
                                        cursor: pointer;
                                    }
                                }
                                h1{
                                    font-size: 16px !important;
                                    font-weight: 700 !important;
                                }
                            }
                            .words-table{
                                display: flex;
                                row-gap: 10px;
                                gap: 5%;
                                flex-wrap: wrap;

                                .word{
                                    flex: 0 30%;
                                }
                            }
                        }
                    }
                }
                .modal-footer{
                    button{
                        border: 1px solid #CA5050;
                        border-radius: 10px;
                        color: #BC4E4E;
                        background: none;
                        font-size: 14px;
                    }
                }
            }
        }
}

.vocabulary-language-admin-main-container .words-container .words-table{
    flex-wrap: wrap;
    margin: auto;
}

@media (max-width:431px){

    .vocabulary-language-admin-main-container
     .words-container .words-table .words-table-column .words-table-row {
        display: grid;
        grid-template-columns: minmax(30%, max-content) minmax(100px, max-content);
        grid-gap: 10px;
        gap: 10px;
        align-items: end;
        justify-content: space-between;
        align-content: stretch;
        justify-items: stretch;
     }
    }