$baseurl: "";
@import 'theme/colors', 'theme/variables';

.section__card {
  .card-title {
    font-family: $font-family-secondary;
    font-weight: $font-weight-regular;
    font-size: 80px;
    text-align: center;
  }
}

@media screen and (max-width: 414px) {
  .section__card {
    .card {
      width: 22rem !important;
      height: 28rem !important;
    }
  }
}
