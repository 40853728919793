$baseurl: "";
@import "theme/colors", "theme/variables";

.header__enrollment {
  padding: $spacing-2x 0 2px 0;
  background: $color-primary;
  color: $color-white !important;

  a {
    color: $color-white;
    text-decoration: underline;
    &:hover {
      color: $color-white !important;
    }
  }

  h1 {
    color: $color-white !important;
  }

  .enrollment {
    padding: $spacing-3x 0;
    color: $color-white;
  }
}

.section__enrollment {
  margin-top: $spacing-4x * 1.5 !important;
  margin-bottom: $spacing-4x !important;

  .button__container {
    margin-bottom: $spacing-2x;
  }
}

.receive-notice-container {
  width: 80%;
  padding: 30px;
  border-radius: 10px;
  margin: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.enrollment-inscriptions-cards-container {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  gap: 40px;
  flex-wrap: wrap;

  * {
    margin: 0;
    padding: 0;
  }

  a {
    width: fit-content;
    text-decoration: none;
    width: 33%;
    min-width: 300px;
    max-width: 350px;

    .inscription-card {
      padding: 20px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      border-radius: 10px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      aspect-ratio: 1;
      user-select: none;
      width: 100%;
      position: relative;

      .title-container {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      img {
        width: 70%;
        min-width: 150px;
        aspect-ratio: 1;
      }

      .full-card {
        background: linear-gradient(90.31deg, #ee1c34 0.26%, #500b28 99.73%);
        position: absolute;
        width: 100%;
        top: 60%;
        left: 50%;
        transform: translate(-50%, 0%) skew(0deg, -10deg);

        h3 {
          text-align: center;
          color: white;
          font-weight: bolder;
          font-size: 3rem!important;
          font-style: italic;
        }
      }
    }
  }

  a.disable {
    pointer-events: none;
    .inscription-card {
      cursor: not-allowed;
    }
  }

  a:hover {
    text-decoration: none;
  }
}

.title-type-inscription-enrollment {
  font-size: 1.25rem !important;

  a {
    font-weight: bolder;
    background: linear-gradient(to right, #ee1c34, #500b28 69.67%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@media (max-width: 435px) {
  .receive-notice-container {
    width: 95%;
    margin-top: 40px;
  }
}

@media (max-width: 366px) {
  .request-invoice-btn {
    font-size: 1rem !important;
  }
}
