$baseurl: "";
.admin-aside-nav {
	min-width: 300px;
	max-width: 300px;
	padding: 20px 0px;
	box-shadow: 1px 1px 10px 4px rgba(0, 0, 0, 0.2);
	position: sticky;
	background: linear-gradient(180deg, #ee1c34, #500b28);
	overflow: scroll;
	top: 67px;
	height: 100%;
}
.admin-aside-nav ul {
	list-style: none;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	position: fixed;
	min-width: 300px;
	height: 100%;
	overflow: scroll;

	.sub-menu-item {
		max-height: 85px;
		//overflow: hidden;
		transition: 0.25s ease all;
		
		.sub-menu-action {
			gap: 20px;
			cursor: pointer;
			.arrow-icon {
				margin-left: 74px;
				transform: rotate(0deg);
				transition: 0.2s ease all;
			}
		}
	}
	.sub-menu-container {
		display: flex;
		flex-direction: column;
		padding-left: 20px;
	}
	.sub-menu-open {
		max-height: 498px;
		transition: 0.25s ease all;

		.sub-menu-action {
			.arrow-icon {
				transform: rotate(180deg);
				transition: 0.25s ease all;
			}
		}
	}
	.notification-btn {
		&.responsive {
			display: flex;
		}
		&.desk {
			display: none;
		}
	}

	@media (max-width: 992px) {
		.notification-btn {
			&.desk {
				display: flex;
			}

			&.responsive {
				display: none;
			}
		}
	}
}

.admin-aside-nav ul li {
	font-size: 20px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 20px 0px 20px 30px;
}

.admin-aside-nav ul li div img {
	width: 100%;
	filter: invert(80%) sepia(67%) saturate(0%) hue-rotate(178deg) brightness(115) contrast(101%);
}

.admin-aside-nav ul a.active li div img {
	filter: invert(80%) sepia(67%) saturate(0%) hue-rotate(178deg) brightness(115%) contrast(101%);
}

.admin-aside-nav ul a,
.admin-aside-nav ul li {
	text-decoration: none;
	color: #cecece;
}

.admin-aside-nav ul a:hover,
.admin-aside-nav ul li:hover {
	text-decoration: underline;
	color: white;
}

.admin-aside-nav ul a.active {
	color: white;
	text-decoration: underline;
	background-color: #00000052;
}

.admin-aside-nav ul a.active li {
	color: white;
}

.custom__navbar .container {
	justify-content: flex-end !important;
}

.asidenav-active-btn {
	display: none;
}
.finance-routes {
	display: flex;
	justify-content: center;
	align-items: center;
}
@media (max-width: 991px) {
	.admin-aside-nav {
		display: none;
	}
	.admin-aside-nav ul {
		position: unset;
		min-width: unset;
	}
	.admin-aside-nav-responsive {
		display: block;
		//background-color: white;
		z-index: 3;
		position: sticky;
		top: 80px;
		overflow: scroll;
		height: 100%;
		//height: -webkit-fill-available;
	}

	.asidenav-active-btn {
		display: block;
	}
}
