* {
  padding: 0;
  margin: 0;
  box-sizing: border-box !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
}

html,
body,
#root {
  height: 100%;
  width: 100%;

  scroll-behavior: smooth;
}

#root {
  overflow: scroll;
  overflow-x: hidden;
}

:focus {
  outline: none !important;
  transition: none !important;
}
::-moz-focus-inner {
  border: 0 !important;
}
