$baseurl: "";
.container-speiref {
  padding: 30px;
  display: flex;
  flex-direction: column;
}

.card-speiref {
  width: 100%;
  padding: 30px;
  position: relative;
  margin-bottom: 80px;
  margin-top: 40px;
  max-width: 800px;
  box-shadow: 1px 1px 10px 4px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  align-self: center;
  min-width: 320px;
}

.card-speiref img {
  max-width: 320px;
  width: 100%;
  min-width: 260px;
}

.card-speiref h4 {
  font-size: 1.2rem;
}


.card-speiref p {
    font-size: 1rem !important;
    font-weight: bolder !important;
    text-align: justify;
}

.card-speiref p.instrucciones-text {
    font-size: 2rem!important;
}

.card-speiref .spei-email-text {
    font-size: .7rem!important;
    text-align: justify;
}
.speiref-num-container {
  background-color: #f2f2f2;
  width: 100%;
  max-width: 320px;
  min-width: 260px;
  text-align: center;
}

@media (max-width: 450px) {
    .speiref-num-container h2 {
        font-size: 1.5rem;
    }
}