$baseurl: "";
.form-main-container {
    padding: 40px 0;
    margin: auto;
    margin-bottom: 100px;
    width: 50%;
    min-width: 300px;
    height: 100%;

    .experience-form-container {
        .radio-containers {
            .form-check {
                padding-left: 0px;
                display: flex !important;
                width: fit-content;
                align-items: center;
                gap: 5px;

                input[type="radio"] {
                    position: initial;
                    margin: 0;
                    padding: 0;
                    transform: none;
                }
                label {
                    position: initial;
                }
            }
        }

        .radio-containers.row-container {
            display: flex;
            justify-content: space-around;

            .form-check {

                flex-direction: column-reverse;
            }
        }

        .time-of-study-row-container {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;

            .input-container {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;

                label {
                    margin: 0;
                }

                input {
                    width: 4rem;
                    text-align: center;
                }
            }
        }
    }
    
}

.form-title {
    color: #ca5050;
}

.validationCustomTenOpt {
    display: inline !important;
    input {
        width: 1.1em;
        height: 1.1em;
        bottom: 7px;
    }
    label {
        left: -15px;
        top: 17px;
        position: relative;
    }
}

input.notObligatoryInput.form-control:valid {
    padding: 0.375rem 0.75rem;
    border-color: #ced4da;
    background-image: none;
}

@media (max-width: 992px) {
    .form-main-container {
        form {
            .buttons-row-container {
                flex-direction: column;
                gap: 20px;
                align-items: center;
                justify-content: center;

                button {
                    margin: auto !important;
                }
            }
        }

        .experience-form-container {
            .radio-containers {
                display: flex;
                justify-content: space-around;
                align-items: center;
                min-width: 100%;

                .form-check {
                    position: static;
                    margin: 0;
                    padding: 0;

                    input[type="radio"] {
                        position: static;
                        padding: 0;
                        margin: 0;
                    }
                }
            }
        }
    }
}
