$baseurl: "";
.payment-history-card-container {
	box-shadow: 1px 1px 10px 4px rgba(0, 0, 0, 0.2);
	padding: 25px 20px;
	border-radius: 10px;
	margin-bottom: 30px;

	.payment-history-card-row-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		position: relative;
	}
}

.payment-history-right-content-card-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	max-width: 345px;
}

.icon-payment-history-container {
	width: 50px;
	margin-right: 15px;
}

.icon-payment-history-container img {
	width: 100%;
}

.text-payment-history-container {
	max-width: 280px;
	width: 100%;
}

.text-payment-history-container p {
	margin: 0;
	color: #7b7b7b;
	font-size: 1rem !important;
}

.text-payment-history-container {
	.amount-text {
		color: #9c132f;
		font-size: 1.5rem !important;
		font-weight: bolder !important;
	}
}

.text-payment-history-container .realizacion-date {
	font-weight: 500;
	color: #000000;
	font-size: 1rem !important;
}

.text-payment-history-container {
	.date-text {
		color: #7b7b7b;
		font-size: 1.25rem;
		text-align: center;
	}
}

.payment-history-left-content-card-container p {
	margin: 0;
	font-weight: 700 !important;
}

.payment-history-left-content-card-container {
	display: flex;
	flex-direction: row;
	gap: 25px;
    justify-content: flex-end;
	.invoice-status {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		.fixed-text {
			color: #7b7c7e;
		}
		.red {
			color: #ff0000;
		}
		.question-icon {
			width: 20px;
			height: 20px;
			text-align: center;
			border-radius: 100%;
			background-color: gray;
			color: white;
			position: relative;
			min-width: 19px;
			&:hover {
				.info-box {
					display: block;
				}
			}
			.info-box {
				position: absolute;
                display: none;
                width: 170px;
                background: gray;
                border-radius: 10px 10px 0 10px;
                transform: translate(-100%, -122%);
                padding: 10px;
                text-align: left;
                p {
                    font-size: 0.8rem !important;
                }
			}
		}
	}
	.generate-invoice-btn {
		border: 1px solid #ca5050;
		background-color: transparent;
		color: #ca5050;
		padding: 0 15px;
		height: 44px;
		font-size: 1.2rem !important;
		font-weight: 400;
	}
	button {
		padding: 0 10px;
		height: 45px;
		font-size: 1rem !important;
		background: transparent;
	}
	.see-receipt-btn {
		border: 1px solid #505050;
		color: #505050;
	}
}

.title-red {
	color: #ca5050;
	font-size: 1.5rem;
	font-weight: 700;
}

.title-red:hover {
	color: #ca5050;
	text-decoration: none;
}

@media (max-width: 611px) {
	.payment-history-card-container {
		.payment-history-card-row-container {
			justify-content: center;

			.payment-history-right-content-card-container {
				flex-direction: column;
				.icon-payment-history-container {
					position: absolute;
					left: 0;
					top: 0;
				}

				.text-payment-history-container {
					text-align: center;
					margin-bottom: 20px;

					.realizacion-date {
						text-align: start;
					}
				}
			}
		}
	}
}


@media (max-width: 451px) {
	.title-red {
		color: #ca5050;
		font-size: 1.2rem;
		font-weight: 700;
		width: 200px;
		margin: auto;
	}
	.payment-history-card-container {
		.payment-history-card-row-container {
			justify-content: center;

			.payment-history-right-content-card-container {
				flex-direction: column;
				.icon-payment-history-container {
					position: absolute;
					left: -7px;
					top: 0;
				}

				.text-payment-history-container {
					text-align: center;
					margin-bottom: 20px;

					.realizacion-date {
						text-align: start;
					}
				}
			}
		}
	}
	.text-payment-history-container .realizacion-date {
		width: auto !important;
	}
}
