/* Theme colors  */
$color-primary: linear-gradient(to right, #ee1c34, #500b28);
$primary-color: #cf072c;
$primary-color-hover: #a60623;
// $color-primary: linear-gradient(to right, #ee1c34, #500b28);
$color-secondary: #bc4e4e;
$color-left: #ee1c34;
$color-right: #500b28;
// GRAY-SCALE
$color-white: #ffffff;
$color-black: #000000;
$color-gray-soft: #e5e5e5;
