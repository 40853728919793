$baseurl: "";
@import "@theme/mixin.scss";

.student-review-card {
  aspect-ratio: 3/2;
  width: clamp(300px, 600px, 600px);
  background-color: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 55px;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .top {
    .image-container {
      width: 48px;
      height: 48px;
      aspect-ratio: 1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    svg {
      width: 50px;
    }
  }

  .opinion-container {
    font-size: 20px;
    font-family: "inter";
  }

  .bottom {
    h3 {
      font-size: 32px;
      font-family: "inter black";
    }

    svg {
      width: 50px;
      transform: rotate(180deg);
    }
  }

  @include respond-to(sm) {
    width: 350px;
    background-color: #eef4f9;
    padding: 20px;

    .opinion-container {
      font-size: 14px;
    }

    .top {
      svg {
        width: 30px;
      }
    }

    .bottom {
      h3 {
        font-size: 20px;
      }
      svg {
        width: 30px;
      }
    }
  }
}
