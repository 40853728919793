$baseurl: "";
.container-oxxoref {
  padding: 30px;
  display: flex;
  flex-direction: column;
}

.card-oxxoref {
    width: 100%;
    padding: 30px;
    position: relative;
    margin-bottom: 80px;
    margin-top: 40px;
    max-width: 800px;
    box-shadow: 1px 1px 10px 4px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    align-self: center;
    min-width: 320px;
}

.card-oxxoref img {
    max-width: 320px;
    width: 100%;
    min-width: 260px;
    margin-bottom: 20px;
}

.card-oxxoref h4 {
    font-size: 1.2rem;
}

.card-oxxoref p {
    font-size: 1rem!important;
    font-weight: bolder!important;
    text-align: justify;
}

.card-oxxoref p.instrucciones-text {
    font-size: 2rem!important;
}

.card-oxxoref .oxxo-email-text {
    font-size: .7rem!important;
    text-align: justify;
}

.oxxoref-num-container {
    background-color: #f2f2f2;
    width: 100%;
    max-width: 320px;
    min-width: 260px;
    text-align: center;
}

.contact-email-text, .contact-email-text:hover {
    font-weight: 100;
    color: #000000;
    text-decoration: none!important;
}

@media (max-width: 450px) {
    .oxxoref-num-container h2 {
        font-size: 1.5rem;
    }
}