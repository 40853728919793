$baseurl: "";
@import "@theme/mixin.scss";

.burger-btn-main-container {
  display: none;

  @include respond-to(md) {
    display: block;
    width: 24px;
    height: 12px;
    position: relative;
    transition: 0.5s ease all;

    svg {
      position: absolute;
      width: 100%;
      transition: .5s ease all;

    }

    .top {
      bottom: 100%;
      left: 50%;
      transform: translate(-50%, 100%);
    }

    .middle {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .bottom {
      top: 100%;
      left: 50%;
      transform: translate(-50%, -100%);
    }
    &.active {
      .top {
        bottom: 50%;
        transform: translate(-50%, 50%) rotate(-45deg);
      }

      .middle {
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      .bottom {
        bottom: 0;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }
}
