$baseurl: "";
@import "@theme/_colors.scss";
@import "@theme/mixin.scss";

footer {
  background-color: #f0f5f8;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  padding: 35px 70px;

  .logo-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .center-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;

    .social {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      margin-bottom: auto;

      svg {
        cursor: pointer;
      }
    }

    .name {
      display: flex;
      align-items: center;
      gap: 10px;
      p {
        font-size: 1rem;
        margin: 0px;
      }

      .separator {
        border: 1.5px solid $primary-color;
        height: 1rem;
      }
    }
    h6 {
      font-size: 0.875rem;
      font-weight: 400;
      color: #777777;
    }
  }

  .privacy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 10px;

    .privacy-links-container {
      display: flex;
      flex-direction: column;
      a {
        text-decoration: underline;
        font-size: 0.75rem;
        color: #000000;
      }
    }

    > div {
      margin-top: 10px;
      text-align: end;

      h4 {
        font-size: 0.875rem;
        color: #777777;
      }
      h3 {
        font-size: 0.875rem !important;
      }
    }
  }

  @include respond-to(md) {
    grid-template-columns: auto;
    grid-template-rows: repeat(3, auto);
    gap: 10px;
    padding: 10px;

    .logo-container {
      justify-content: center;
      grid-row: 1;
    }

    .center-container {
      grid-row: 3;
    }

    .privacy {
      flex-direction: row;
      justify-content: space-between;

      .privacy-links-container {
        text-align: start;
      }
    }
  }
}
