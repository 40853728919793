$baseurl: "";
.opacity-0 {
    opacity: 0;
}

.consult-title-container {
    display: flex;
    justify-content: space-between;
    height: fit-content;
    margin-bottom: 10px;

    h1 {
        margin: 0;
    }

    .consult-change-content-btn-container {
        display: flex;
        align-items: center;

        .left {
            border: 1px solid #ca5050;
            border-radius: 10px 0px 0px 10px;
            padding: 10px;
            cursor: pointer;
            transition: 0.2s ease-out;
        }

        .right {
            border: 1px solid #ca5050;
            border-radius: 0px 10px 10px 0px;
            padding: 10px;
            cursor: pointer;
            transition: 0.2s ease-out;
        }

        .active {
            background-color: #ca5050;
            transition: 0.2s ease-in;
        }
    }
}

.description-container {
    margin-bottom: 30px;

    h3 {
        font-size: 1.25rem !important;
    }
}

.module-navigation-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 20px;

    .left, .right {
        a {
            text-decoration: none;
        }
        flex: 1;
        .consult-zero-container {
            border: 1px solid #ca5050;
            border-radius: 10px;
            height: 52px;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            max-width: 250px;
            padding: 5px 10px;
    
            h2 {
                margin: 0;
                font-size: 1rem!important;
                text-align: center;
                color: #ca5050;
            }
        }
    }

    .module-selector-bar-container {
        margin-bottom: 10px;
    
        h3 {
            text-align: center;
            font-size: 1.25rem !important;
        }
    
        .module-selector-bar {
            display: flex;
            justify-content: center;
            max-width: 500px;
            margin: auto;
            border-radius: 10px;
            border: 1px solid #ca5050;
            overflow: hidden;
    
            .cell {
                width: 100px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #ca5050;
                border-right: 1px solid #ca5050;
                cursor: pointer;
            }
    
            .active {
                color: white;
                background-color: #ca5050;
            }
        }
    }
}

.consult-search-section-container {
    position: relative;
    top: 0;

    .search-container {
        position: relative;
        margin-right: 0px;

        .lang-selector-container {
            .icons-container {
                position: relative;
                width: 15px;
                height: 15px;
                margin-right: 10px;

                .icon1 {
                    position: absolute;
                    margin: 0;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    opacity: 1;
                    transition: 0.25s ease all;
                }

                .icon2 {
                    position: absolute;
                    margin: 0%;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    opacity: 0;
                    transition: 0.25s ease all;
                }
            }
        }

        .langs-selectior-container-open {
            .icons-container {
                .icon1 {
                    opacity: 0;
                    transition: 0.25s ease all;
                }

                .icon2 {
                    opacity: 1;
                    transition: 0.25s ease all;
                }
            }
        }

        .filters-container {
            max-height: 0px;
            position: absolute;
            top: 70px;
            width: 100%;
            background: #ffffff;
            left: 0;
            border-radius: 10px;
            box-shadow: 0px 4px 20px #00000040;
            padding: 0px 20px;
            overflow: hidden;
            z-index: 1020;
            transition: 0.5s ease all;

            .lang-filter-container,
            .hashtag-filter-container {
                display: flex;
                flex-direction: column;
                gap: 5px;
                margin-bottom: 10px;

                h3 {
                    font-weight: 500 !important;
                    font-size: 1rem !important;
                    margin: 0;
                    line-height: 1rem;
                }

                .checkboxes-container,
                .hashtags-container {
                    display: flex;
                    flex-wrap: wrap;
                    row-gap: 5px;
                    column-gap: 5px;

                    .checkbox-container {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 5px;
                        width: 124px;

                        label {
                            margin: 0;
                        }
                    }
                }

                .hashtags-container {
                    gap: 10px;

                    .hashtag {
                        border: 1px solid #ca5050;
                        border-radius: 100px;
                        color: #ca5050;
                        font-size: 0.875rem;
                        padding: 0px 5px;
                        cursor: pointer;
                    }

                    .active {
                        background-color: #ca5050;
                        color: white;
                    }
                }
            }

            .alone-filter {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 5px;
                margin-bottom: 10px;

                label {
                    font-weight: 800 !important;
                    font-size: 1rem !important;
                    margin: 0;
                    line-height: 1rem;
                }
            }
        }

        .filters-container-open {
            max-height: 1000px;
            padding: 20px;
            transition: 1s ease all;
        }
    }
}

.search-just-my-questions-container {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;

    label {
        margin: 0;
    }
}

.filter {
    filter: invert(54%) sepia(96%) saturate(0%) hue-rotate(177deg) brightness(97%) contrast(103%);
}

.consult-seminary-selector-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;

    img {
        cursor: pointer;
    }

    .consult-seminary-card {
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        background: #ffffff;
        padding: 25px;
        width: 280px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
        cursor: pointer;
        position: relative;
        max-height: 119px;

        h3 {
            font-family: "Poppins", sans-serif !important;
            font-style: normal;
            font-weight: 500 !important;
            font-size: 1.5rem !important;
            line-height: 29px;
            text-align: center;
            color: #ca5050;
            margin: 0;
        }

        p {
            font-family: "Poppins", sans-serif !important;
            font-style: normal;
            font-weight: 500 !important;
            font-size: 1.25rem !important;
            line-height: 30px;
            text-align: center;
            margin: 0;
        }

        .next-class-container {
            display: flex;
            gap: 5px;
            align-items: center;

            .next-class {
                font-size: 0.75rem !important;
                color: rgba(188, 78, 78, 0.5);
                line-height: 12px;
            }
        }

        .block-seminary {
            position: absolute;
            background: hsla(0, 0%, 0%, 0.6);
            border-radius: 10px;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            backdrop-filter: blur(3px); /* apply the blur */

            p {
                color: #ffffff;
                font-size: 0.875rem !important;
                line-height: 0.875rem;
            }

            img {
                width: 64px;
            }
        }
    }

    .active {
        background: linear-gradient(90.31deg, #ee1c34 0.26%, #500b28 99.73%);
        color: #ffffff;
        width: 360px;
        height: 160px;
        max-height: initial;

        h3 {
            color: #ffffff;
            font-size: 2rem !important;
        }
    }
}

.consult-last-post {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 20px #00000040;
    max-width: 900px;
    margin: auto;
    margin-bottom: 20px;
    position: relative;

    .remain {
        width: 20px;
        height: 20px;
        position: absolute;
        right: 0;
        background: #ca5050;
        border-radius: 100px;
        transform: translate(-50%, 50%);
        cursor: pointer;

        .remain-description {
            position: absolute;
            width: 150px;
            padding: 10px;
            background: #dbdbdb;
            border-radius: 10px 0px 10px 10px;
            top: 20px;
            right: 12px;
            display: none;

            p {
                font-size: 0.75rem !important;
                margin: 0;
            }
        }
    }

    .remain:hover {
        .remain-description {
            display: block;
        }
    }

    .content {
        padding: 20px;
        padding-bottom: 0px;

        .header {
            p {
                font-weight: 700 !important;
                font-size: 1rem !important;
            }
        }

        .no-consult-container {
            min-height: 302px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            flex-direction: column;

            h3 {
                margin: 0;
                font-weight: 300 !important;
                font-style: italic;
                opacity: 0.4;
            }
        }

        .post-container {
            display: flex;
            gap: 10px;
            justify-content: space-between;
            align-items: flex-start;

            .profile-pic-container {
                width: 42px;
                height: 42px;
                min-width: 42px;
                border-radius: 100%;
                background: #ca5050;
                position: relative;
                overflow: hidden;

                img {
                    position: absolute;
                    width: 50px;
                    height: 50px;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }

                h3 {
                    position: absolute;
                    color: #ffffff;
                    left: 50%;
                    top: 50%;
                    font-weight: 400 !important;
                    margin: 0 !important;
                    text-align: center;
                    transform: translate(-50%, -50%);
                    font-size: 1rem !important;
                }
            }

            .post-text-container {
                flex: 1;
                .post-info-container {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-end;
                    gap: 10px;
                    margin-bottom: 10px;

                    h3 {
                        font-weight: 500 !important;
                        font-size: 0.875rem !important;
                        margin: 0;
                        line-height: 1rem;
                    }

                    .admin {
                        color: #d32d2d;
                    }

                    img {
                        width: 1.125rem;
                        height: 1.125rem;
                    }

                    p {
                        font-weight: 300 !important;
                        font-size: 0.75rem !important;
                        margin: 0;
                        line-height: 1rem;
                    }
                }

                .text-container {

                    img {
                        width: 100%;
                    }

                    p {
                        font-weight: 400 !important;
                        font-size: 1rem !important;
                        line-height: 1.2rem;
                    }

                    ol,
                    ul {
                        font-weight: 400 !important;
                        font-size: 0.875rem !important;
                        line-height: 1rem;
                    }
                }

                .hashtag-flag-container {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 10px;
                    flex-wrap: wrap;
                    gap: 10px;

                    .hashtag-container {
                        display: flex;
                        gap: 10px;
                        flex-wrap: wrap;

                        p {
                            font-weight: 400 !important;
                            font-size: 0.875rem !important;
                            line-height: 1rem;
                            color: #ca5050;
                            text-decoration: underline;
                            cursor: pointer;
                            margin: 0;
                        }
                    }

                    .flags-container {
                        display: flex;
                        gap: 10px;

                        img {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }

                .answers-container {
                    margin-bottom: 10px;

                    p {
                        font-weight: 500 !important;
                        font-size: 0.875rem !important;
                        line-height: 1rem;
                        color: #ca5050;
                        cursor: pointer;
                        margin: 0;
                    }
                }
            }
        }
    }

    .block-seminary {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #00000099;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        backdrop-filter: blur(3px); /* apply the blur */

        p {
            color: #ffffff;
        }
    }

    .footer {
        display: flex;
        border-top: 1px solid #00000020;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 10px;
        cursor: pointer;

        p {
            font-family: "Lato", sans-serif !important;
            font-style: normal;
            font-weight: 500 !important;
            font-size: 0.875rem !important;
            color: #bc4e4e;
            margin: 0;
        }
    }
}

.consult-seminary-cards-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 30px;
    max-width: 1110px;
    margin: auto;

    .consult-seminary-card {
        max-width: 350px;
        background: #ffffff;
        box-shadow: 0px 4px 20px #00000040;
        border-radius: 10px;
        position: relative;

        .header {
            padding: 20px;
            padding-bottom: 10px;
            border-bottom: 1px solid #00000030;

            h3 {
                margin: 0;
                font-weight: 500 !important;
                font-size: 1.5rem !important;
                color: #ca5050;
            }

            .sub-container {
                display: flex;
                justify-content: space-between;
                align-items: center;

                p {
                    margin: 0;
                    font-family: "Poppins", sans-serif !important;
                    font-style: normal;
                    font-weight: 500 !important;
                    font-size: 1rem !important;
                }

                .next-class-container {
                    display: flex;
                    gap: 5px;
                    align-items: center;

                    .next-class {
                        font-size: 0.75rem !important;
                        color: rgba(188, 78, 78, 0.5);
                        line-height: 12px;
                    }
                }
            }
        }

        .post-content {
            padding: 20px;
            min-width: 350px;
            min-height: 220px;
            height: 220px;
            overflow: scroll;

            .last-post-label {
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                p {
                    font-weight: 500 !important;
                    font-size: 0.875rem !important;
                    margin: 0;
                }
                .flags-container {
                    display: flex;
                    gap: 10px;

                    img {
                        width: 24px;
                        height: 24px;
                    }
                }
            }

            .post-info-container {
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;
                gap: 10px;

                h3 {
                    font-weight: 500 !important;
                    font-size: 0.875rem !important;
                    margin: 0;
                    line-height: 1rem;
                }

                .admin {
                    color: #d32d2d;
                }

                img {
                    width: 1.125rem;
                    height: 1.125rem;
                }

                p {
                    font-weight: 300 !important;
                    font-size: 0.75rem !important;
                    margin: 0;
                    line-height: 1rem;
                }
            }

            .text-container {
                margin-bottom: 8px;

                img {
                    width: 100%;
                }

                p {
                    font-weight: 400 !important;
                    font-size: 0.875rem !important;
                    line-height: 1rem;
                }
            }

            .hashtag-flag-container {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .hashtag-container {
                    display: flex;
                    gap: 10px;
                    max-width: 200px;
                    flex-wrap: wrap;

                    p {
                        font-weight: 400 !important;
                        font-size: 0.875rem !important;
                        line-height: 1rem;
                        color: #ca5050;
                        text-decoration: underline;
                        cursor: pointer;
                        margin: 0;
                    }
                }

                .flags-container {
                    display: flex;
                    gap: 10px;

                    img {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }

        .block-seminary {
            position: absolute;
            background: #00000099;
            border-radius: 10px;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            top: 0;
            left: 0;
            backdrop-filter: blur(3px); /* apply the blur */

            p {
                color: #ffffff;
                font-size: 0.875rem !important;
            }

            img {
                width: 64px;
            }
        }

        .remain {
            width: 20px;
            height: 20px;
            position: absolute;
            right: 0;
            top: 0;
            background: #ca5050;
            border-radius: 100px;
            transform: translate(-50%, 50%);
            cursor: pointer;

            .remain-description {
                position: absolute;
                width: 150px;
                padding: 10px;
                background: #dbdbdb;
                border-radius: 10px 0px 10px 10px;
                top: 20px;
                right: 12px;
                display: none;

                p {
                    font-size: 0.75rem !important;
                    margin: 0;
                }
            }
        }

        .remain:hover {
            .remain-description {
                display: block;
            }
        }

        .footer {
            display: flex;
            border-top: 1px solid #00000020;
            justify-content: center;
            align-items: center;
            gap: 10px;
            padding: 10px;
            cursor: pointer;

            p {
                font-family: "Lato", sans-serif !important;
                font-style: normal;
                font-weight: 500 !important;
                font-size: 0.875rem !important;
                color: #bc4e4e;
                margin: 0;
            }
        }
    }
}

.doubts-text-consults-page {
    margin: 0;
    font-family: "Poppins", sans-serif !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 1rem !important;
    margin-bottom: 10px;
}



@media (max-width: 758px) {
    .consult-seminary-cards-container {
        justify-content: center;
    }

    .module-navigation-container {
        justify-content: center;
        align-items: center;

        .left {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .module-selector-bar-container {
            width: 100%;
            margin: auto;
        }
    }
}

@media (max-width: 1350px) {
    .consult-seminary-selector-container {
        .consult-seminary-card {
            display: none;
        }
        .active {
            display: flex;
        }
    }
}
