$baseurl: "";
@import "@theme/mixin.scss";

.slide-main-container.promotion {
  position: relative; /* Added to position the pseudo-element */

  padding: 0px 75px;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    max-width: 300px;

    h3 {
      color: #ffffff;
      font-size: 45px !important;
      font-weight: 500 !important;
      span {
        font-family: "pacifico";
        font-size: 60px;
      }
    }

    h2 {
      font-size: 59px;
      word-break: keep-all;
      white-space: nowrap;
      text-align: center;
      color: #fbc754;
      border-bottom: 8px solid #fbc754;
      font-family: "palanquin dark";
      line-height: 60px;
    }

    img {
      width: 100%;
      aspect-ratio: 307/293;
      height: auto;
      background-color: #fbc754;
    }
  }

  @include respond-to(sm) {
    padding: 0px 20px;
    .content {
      max-width: 200px;

      h3 {
        font-size: 30px!important;

        span {
          font-size: 40px;
        }
      }

      h2 {
        font-size: 29px!important;
        line-height: 30px;
      }
    }
  }
}

.slide-main-container.promotion::after {
  content: "";
  background-image: url("../../../../../../assets/images/flagswallpaper.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.4; /* Adjust this value to change the opacity */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1; /* Ensure it's behind the content */
}
