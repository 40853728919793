$baseurl: "";
.edit-word-info-modal-header {
	height: 85px;
	align-items: center !important;
	.info-icon {
		color: #23617a;
		margin: 5px;
	}
	img {
		position: absolute;
		left: 50%;
		transform: translate(-50%);
	}
	@media (max-width: 500px) {
		.modal-title {
			font-size: 0.8rem;
		}
	}
}
.grid-info {
	padding: 10px;
	margin: 10px;
	display: grid;
	grid-template-columns: 49% 49%;
	gap: 20px;
	grid-auto-flow: column;
	.grid-info-item {
		display: flex;
		flex-wrap: wrap;
		overflow-wrap: anywhere;
	}
	.item-category {
		grid-column: 1;
	}
	.item-subcategory {
		grid-column: 1;
	}
	.item-appearsin {
		grid-column: 1;
	}
	.item-studied-first {
		grid-column: 1;
	}
	.item-studied-last {
		grid-column: 1;
	}
	.item-difficulty {
		grid-row-end: span 2;
		grid-column: 2;
		display: flex;
		flex-direction: column;
		align-items: center;

		.Tooltip-Tip {
			top: 10px;
		}

		.difficulty-container {
			display: flex;
			justify-content: center;
			align-items: center;

			.diff-icon {
				margin-right: 5px;
			}

			span {
				margin: 0;
				user-select: none;
			}

			img {
				display: block;
				width: 25%;
				height: 50px;
				width: 50px;
				// margin-left: auto;
				// margin-right: 10px;
			}
		}
	}

	.item-correct-ratio {
		grid-column: 2;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: space-around;
	}

	.item-domain {
		grid-row-end: span 2;
		grid-column: 2;
		display: flex;
		flex-direction: column;
		align-items: center;
		.domination-balls-container {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 10px;
			width: fit-content;
			margin-top: 20px;
			@media (max-width: 450px) {
				scale: 0.7;
			}
			.active {
				scale: 2;
			}
			.progress-ball {
				width: 15px;
				height: 15px;
				border-radius: 100%;
			}
			.one {
				background-color: #9c1217;
			}
			.two {
				background-color: #ff5500;
			}
			.three {
				background-color: #ffb300;
			}
			.four {
				background-color: #fff200;
			}
			.five {
				background-color: #d4ff00;
			}
			.six {
				background-color: #0ae26a;
			}
			.seven {
				background-color: #008e00;
			}
		}
	}
}

.warnings-parent-container {
	width: 60%;
	margin: 1rem auto;
	.warning-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border: 5px solid #1193f1;
		width: 100%;
		padding: 10px;
		margin-bottom: 10px;
		.ql-editor {
			all: unset;
			margin-right: auto !important;
			width: 100%;
			height: auto;
			max-height: 13rem;
			overflow: scroll;
			p {
				font-size: 1 rem;
				//font-weight: 400;
				display: block;
				text-align: left !important;
			}
			span {
				display: inline-block;
				font-size: 1 rem;
			}
			.pre {
				gap: 0;
			}
		}
		h3 {
			font-size: 1.25rem !important;
			font-weight: bolder !important;
		}

		img {
			margin-left: auto;
			width: 2rem;
		}
	}
}
