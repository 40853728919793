$baseurl: "";
.test-word-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
        font-size: 3rem !important;
        margin: 0;
        font-weight: 400 !important;
        color: #ca5050;
        line-height: 4rem !important;
    }
    .optional-size {
        font-size: 4rem !important;
    }

    .text-p {
        font-size: 0.875rem !important;
        font-weight: 300 !important;
        margin: 0;
    }

    .see-info-btn {
        cursor: pointer;
    }

    .see-info-btn:hover,
    .active {
        text-decoration: underline;
    }
}