$baseurl: "";
.btn-select-picture-payment-update {
    padding: 10px;
    border: 1px solid #ced4da;
    color: #000;
    border-radius: 10px;
    cursor: pointer;
}

.paymentUpdate-formats-picture-payment{
    font-size: 12px;
    
    font-weight: 400;
}

.progress-bar  {
    background-color: #ca5050 !important;
    font-size: 7px;
}
.progress{

    height: 12px !important;
}
.solicitud-card-container {
    box-shadow: 1px 1px 10px 4px rgba(0, 0, 0, 0.2);
    padding: 25px 20px;
    border-radius: 10px;
    margin-bottom: 20px;

    .content-container-solicitud-card {
        display: flex;
        justify-content: space-between;
    }
}

.solicitud-card-container div p {
    margin: 0;
}

.solicitud-card-container .right .text-up {
    color: #7b7b7b;
    margin-bottom: 10px;
}

.solicitud-card-container .right {
    .denied-message-container {
        margin-top: 15px;
        h3 {
            font-size: 1.25rem !important;
            margin: 0;
        }
    }
}

.solicitud-card-container .right .text-up a {
    color: #ca5050;
    font-weight: 500;
}

.solicitud-card-container .right .text-up a:hover {
    color: #ca5050;
}

.solicitud-card-container .right .text-down {
    font-weight: 500 !important;
}

.solicitud-card-container .left {
    text-align: end;
}

.solicitud-card-container .left .text-up {
    font-weight: 700 !important;
    margin-bottom: 10px;
}

.solicitud-card-container .left .text-down {
    font-weight: 700 !important;
    color: #02a377;
}

.dropdown-container #dropdown-menu-align-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn-comprobante{
    text-align: right;
    background-color: #ca5050;
    width: 310px;
    color: white;
    margin-top: 20px;
    font-size: 10px;
    
    
}
.btn-comprobante:hover {
    background-color: #4CAF50; 
    color: black;
}

@media (max-width: 550px) {
    .solicitud-card-container {
        .content-container-solicitud-card {
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;

            .left {
                .text-up {
                    display: none;
                }
            }

            .right {
                display: flex;
                flex-direction: column;

                .card-amount {
                    order: 2;
                    margin: 0;
                }

                .card-name {
                    order: 1;
                    text-align: center;
                    font-size: 1rem !important;
                    margin-bottom: 20px;
                }

                .card-date {
                    order: 4;
                    text-align: start;
                }

                .card-concept {
                    order: 3;
                    color: #959595;
                    text-align: center;
                }
            }
        }
    }
}
