/* Theme spacing variables */
$spacing: 20px;
$spacing-2x: $spacing * 2;
$spacing-3x: $spacing * 3;
$spacing-4x: $spacing * 4;
$spacing-half: $spacing / 2;
/* Theme typography variables */
// Font family
$font-family-primary: 'Poppins';
$font-family-secondary: 'Lato';
// Font sizes
$font-size-h1: 48px;
$font-size-h3: 36px;
$font-size-h4: 24px;
$font-size-p: 20px;

$font-size-h2: 40px;
$font-size-h5: 20px;
$font-size-h6: 18px;

$font-size-s: 14px;
$font-size-label: 12px;
$font-size-xs: 13px;
// Font weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$color-primary: #ca5050;
