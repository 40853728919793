$baseurl: "";
.caracteristicas-card {
	box-shadow: 1px 1px 10px 4px rgba(0, 0, 0, 0.2);
	border-radius: 15px;
	padding: 40px 25px;
	height: 100%;
	max-width: 450px;
}

.caracteristicas-card h3 {
	font-size: 30px !important;
}

.caracteristicas-card ul {
	list-style: none;
	margin-top: 2rem;
}

.caracteristicas-card ul li {
	margin-top: 1rem;
}

.total-pay-card a {
	font-weight: bolder;
	background: linear-gradient(to right, #ee1c34, #500b28 69.67%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.visible-card {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 30px;
}

.visible-card .card {
	width: 320px;
	min-width: 270px;
	max-width: 320px;
	height: 184px;
	background: linear-gradient(to right, #ee1c34, #500b28) !important;
	border-radius: 15px;
}

.yellow-circle {
	height: 35px;
	width: 35px;
	background-color: rgba(245, 151, 19, 0.75);
	border-radius: 50px;
	position: absolute;
	right: 18px;
	top: 13px;
}

.red-circle {
	width: 35px;
	height: 35px;
	background-color: rgba(229, 3, 26, 0.75);
	border-radius: 50px;
	position: absolute;
	right: 42px;
	top: 13px;
}

.top-left-card {
	color: white;
	font-size: 12px !important;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: 600;
	position: absolute;
	top: 24px;
	left: 25px;
}

.card img {
	width: 35px;
	position: absolute;
	top: 60px;
	left: 25px;
	border-radius: 10px;
}

.card-number {
	color: white;
	position: absolute;
	top: 110px;
	left: 25px;
	font-family: arial;
	background: none;
	outline: none;
	border: 0px;
	width: 75%;
}

.expire-date {
	position: absolute;
	width: 50px;
	background: none;
	outline: none;
	border: 0px;
	color: rgba(255, 255, 255, 0.494);
	bottom: 16px;
	right: 23px;
	text-align: center;
	font-size: 12px;
}

.input-name {
	position: absolute;
	bottom: 14px;
	left: 25px;
	background: none;
	outline: none;
	border: 0px;
	font-size: 12px;
	font-weight: 500;
	width: 75%;
	color: white;
}

.cards-icons {
	display: flex;
	align-items: flex-end;
	justify-content: space-evenly;
	width: 50%;
	height: 30px;
	align-self: flex-end;
	margin-top: 30px;
}

.icon {
	width: 50px;
}

.tertiary {
	min-width: 240px;
}
.tertiary.checkout-inscribirme-btn {
	background: linear-gradient(to right, #ee1c34, #500b28) !important;
	color: white !important;
}
.card-table {
	width: 100%;
	margin-top: 56px;
}

.card-table-2 {
	width: 100%;
	margin-top: 35px;
}

#dropdown-menu-align-right {
	width: 100%;
	background-color: #ffffff;
	color: #000000;
	border: 1px solid #ced4da;
	font-family: "Poppins", Helvetica, sans-serif !important;
	font-size: 1rem !important;
	font-weight: 500;
}

.dropdown-menu.show {
	width: 100%;
	transform: translate(0px, 53px);
}

.checkout-secure-image-container {
	margin-top: 50px;
	text-align: center;
}

.checkout-secure-image-container img {
	width: 60%;
	min-width: 200px;
}

.checkout-conekta-image-container {
	text-align: center;
	margin-top: 50px;
}

.checkout-conekta-image-container img {
	width: 40%;
	min-width: 160px;
}

.select-card {
	width: 100%;
	border-radius: 5px !important;
	padding: 6px 12px !important;
	box-shadow: none !important;
	height: 51px;

	border: 1px solid #ced4da;
}

@media (max-width: 354px) {
	.header__checkout .checkout-main-container {
		width: 100% !important;
	}
}

@media (max-width: 450px) {
	.caracteristicas-card img {
		position: relative!important;
		left: 50%;
		transform: translate(-50%, 0);
	}
}

@media (max-width: 901px) {
	.form-card-container {
		justify-content: center !important;
	}

	#card-form {
		width: 100% !important;
	}

	.visible-card {
		justify-content: center;
	}

	.cards-icons {
		margin-top: 30px;
	}

	.total-pay-card {
		font-size: 1.5rem;
	}
}
