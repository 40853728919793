$baseurl: "";
.test-main-container {
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 20px;
	* {
		font-family: sans-serif;
	}

	.close-modals {
		display: none;
	}

	.open-close-modal {
		display: block;
		background-color: rgba(0, 0, 0, 0.35);
		position: absolute;
		left: 0;
		top: 0;
		width: 100vw;
		height: calc(100% + 80px);
		z-index: 1;
	}
	.top-container {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.logo-container {
			img {
				width: 128px;
			}
		}

		.obj {
			width: 10%;
			min-width: 83.75px;
		}
	}

	.header-container {
		display: flex;
		gap: 10px;
		align-items: center;
		max-width: 944px;
		margin: auto;
		width: 100%;

		.image-container {
			width: 64px;
			min-width: 64px;
			height: 64px;
			overflow: hidden;
			position: relative;
			border-radius: 100px;
			aspect-ratio: 1/1;

			img {
				height: 100%;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}

		.title-container {
			display: flex;
			justify-content: space-between;
			width: 100%;
			flex-wrap: wrap;
			h2 {
				font-size: 3rem !important;
				margin: 0;
			}

			img {
				width: 3rem;
			}

			h3 {
				font-size: 1rem !important;
			}
		}

		@media (max-width: 768px) {
			.image-container,
			.title-container h2,img {
				display: none;
			}
		}
	}

	.quiz-name-container {
		display: none;
		h2 {
			font-size: 1.5rem;
			opacity: 0.4;
			text-align: center;
		}
	}

	.loader-container {
		margin-top: 100px;
		display: flex;
		flex-direction: column;
		gap: 40px;

		.rocket-row,
		.creating-test-row,
		.pro-tip-row,
		.progress-bar-row {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.rocket-row {
			img {
				animation: rocketMove 5s infinite;
			}
		}

		.creating-test-row {
			h3 {
				font-size: 1.25rem !important;
			}
		}

		.progress-bar-row {
			.progress-bar-container {
				width: 275px;
				height: 10px;
				box-shadow: rgba(50, 50, 93, 0.25) -5px 2px 4px 0px inset;
				border-radius: 10px;
				overflow: hidden;

				.progress-bar {
					width: 50%;
					height: 100%;
					background: linear-gradient(to right, rgb(238, 28, 52), rgb(80, 11, 40));
					border-radius: 10px;
				}
			}
		}

		.pro-tip-row {
			flex-direction: column;

			h2,
			h3 {
				font-size: 1rem !important;
				margin: 0;
			}

			h2 {
				color: #ca5050;
			}
		}
	}

	.test-container {
		max-width: 944px;
		margin: auto;
		margin-top: 0;
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 20px;

		.test-progress-container {
			.test-progress-card {
				padding: 10px 40px 40px 40px;
				background: #ffffff;
				box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
				border-radius: 10px;
				display: flex;
				flex-direction: column;
				gap: 40px;

				.title-container {
					display: flex;
					align-items: center;
					flex-direction: column;
					gap: 10px;

					.title {
						display: flex;
						width: 100%;
						justify-content: space-between;
						h2 {
							margin: 0;
							opacity: 0.4;
							flex: 1;
							padding-left: 10%;
						}
					}
					.title-2 {
						margin-bottom: auto;
						h3 {
							font-size: 1.25rem !important;
							font-weight: 400 !important;
						}
						.translate-input-container {
							display: flex;
							width: 100%;
							justify-content: flex-end;
							align-items: center;
							gap: 7px;
							label {
								width: 1rem;
								height: 1.5rem;
								display: flex;
								align-items: center;
								margin: 0;
							}
							img {
								width: 1rem;
								height: 1rem;
							}
							input {
								display: none;
							}
						}
					}

					.test-progress-info-container {
						display: flex;
						justify-content: space-between;
						align-items: center;
						width: 100%;

						h2 {
							margin: 0;
							font-size: 1.25rem !important;
							color: #ca5050;
						}

						.segments-container {
							display: flex;
							gap: 0px 15px;

							.segment {
								box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
								border-radius: 100%;
								width: 30px;
								height: 30px;
								display: flex;
								align-items: center;
								justify-content: center;

								p {
									margin: 0;
									font-size: 15px !important;
								}
							}

							.active {
								background: linear-gradient(90.31deg, #ee1c34 0.26%, #500b28 99.73%);

								p {
									color: white;
								}
							}
						}
					}
				}
			}
			.close-card {
				display: none;
			}
		}

		.progress-score-btn-container {
			display: none;
			button {
				width: 120px;
				height: 35px;
				border-radius: 10px;
				background-color: #ffffff;
				border: 1px solid #ca5050;
				padding: 0px;
				font-size: 0.75rem !important;
				color: #ca5050;
			}
		}

		.test-info-container {
			display: flex;
			gap: 20px;

			.test-score-card-container {
				box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
				border-radius: 10px;
				width: 50%;
				min-width: 300px;
				max-width: 372px;

				.close-icon-container {
					display: none;
				}
			}

			.test-score-card {
				background: #ffffff;
				border-radius: 10px;
				padding: 20px;
				display: flex;
				flex-direction: column;
				gap: 20px;
				min-width: 240px;
				position: relative;

				.title {
					h3 {
						font-size: 1rem !important;
						font-weight: bolder !important;
						font-family: "Lato" !important;
					}
				}

				.total-errors-container {
					display: flex;
					justify-content: center;
					align-items: center;

					h2 {
						cursor: pointer;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						margin: 0;
						font-size: 0.875rem;
						line-height: 1.7rem;
						span {
							color: #ca5050;
							font-size: 2rem;
						}
					}
				}

				.good-answers-row,
				.incorrect-answers-row {
					.label-container {
						display: flex;
						gap: 10px;
						align-items: center;
						justify-content: flex-start;

						.question-icon:hover {
							.info-box {
								border-radius: 10px 10px 10px 0px;
								transform: translate(6%, -120%);
								width: 300px;
							}
						}
					}
					h3 {
						font-size: 0.875rem !important;
						font-weight: 400 !important;
						margin: 0;
					}

					h3:hover {
						text-decoration: underline;
						cursor: pointer;
					}

					.words-progress-bar-container {
						display: flex;
						align-items: center;
						justify-content: space-between;
						gap: 10px;

						p {
							margin: 0;
							font-size: 0.875rem !important;
							font-weight: 400 !important;
						}

						.words-progress-bar {
							background: #ffffff;
							box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
							border-radius: 100px;
							height: 10px;
							flex: 1;

							.words-progress {
								background: linear-gradient(90.31deg, #ee1c34 0.26%, #500b28 99.73%);
								border-radius: 100px;
								height: 100%;
								width: 50%;
							}
						}
					}
				}

				.donut-container {
					h3 {
						font-size: 0.875rem !important;
						font-weight: 400 !important;
					}

					.chart-container {
						position: relative;

						.percentage {
							font-size: 1.25rem !important;
							font-weight: 500 !important;
							position: absolute;
							left: 50%;
							top: 52.5%;
							transform: translate(-50%, -50%);
							margin: 0;
						}
					}
				}
			}

			.test-words-container {
				flex: 1;
				background: #ffffff;
				box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
				border-radius: 10px;
				padding: 20px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				position: relative;
				min-width: 342px;

				.d-flex {
				}
				.wrench-word {
					width: 1.5rem;
					cursor: pointer;
				}

				.result-title {
					display: flex;
					flex-direction: column;
					align-items: center;

					h2 {
						font-size: 1.75rem !important;
						margin: 0;
					}

					.correct {
						color: #02a377;
					}

					.incorrect {
						color: #ee1c34;
					}

					p {
						font-size: 1rem !important;
						font-family: sans-serif;
					}

					.terms-check {
						.test-word-container {
							h2,
							span {
								font-size: 1rem !important;
								line-height: 1rem !important;
							}

							.see-info-btn,
							.text-p {
								display: none;
							}
						}
					}
				}
				.domination-image-container {
					margin-bottom: 30px;
					p {
						display: none;
						margin-left: auto;
						position: absolute;
						background-color: white;
						cursor: grab;
						transform: translateX(50%);
						right: 50%;
					}
					&:hover {
						p {
							display: block;
						}
					}
				}

				.domination-progress-word-container {
					h3 {
						font-size: 1rem !important;
					}

					.domination-balls-container {
						display: flex;
						justify-content: center;
						align-items: center;
						gap: 15px;

						.progress-ball {
							border-radius: 100%;
							transition: 4s ease all;
						}

						.nothing {
							width: 0.5rem;
							height: 0.5rem;
						}

						.one {
							background-color: #9c1217;
						}

						.two {
							background-color: #ff5500;
						}

						.three {
							background-color: #ffb300;
						}

						.four {
							background-color: #fff200;
						}

						.five {
							background-color: #d4ff00;
						}

						.six {
							background-color: #0ae26a;
						}

						.seven {
							background-color: #008e00;
						}

						.active {
							width: 1rem;
							height: 1rem;
							// transition: 4s ease all;
							animation-name: blinkandGrow;
							animation-duration: 2s;
							animation-iteration-count: 1;
						}

						.activeBlink {
							width: 1rem;
							height: 1rem;
							animation-name: blink2;
							animation-duration: 2s;
							animation-iteration-count: 1;
						}

						.disactive {
							width: 0.5rem;
							height: 0.5rem;
							animation-name: blinkandDecre;
							animation-duration: 2s;
							animation-iteration-count: 1;
						}
					}
				}

				.info-word-container {
					display: flex;
					flex-direction: column;
					margin: auto;
					margin-left: 0;
					gap: 20px;
					width: 100%;

					.try-again-btn {
						border-radius: 100px !important;
						background-color: rgb(255, 119, 0);
						color: white;
						font-size: 0.875rem !important;
						padding: 5px 10px;
					}

					p {
						display: flex;
						// justify-content: center;
						align-items: center;
						gap: 10px;
						margin: 0;
						flex-wrap:wrap; //new
						span.default-text {
							font-size: 1rem !important;
							margin: 0;
							color: #949494;
							font-family: sans-serif;
							// margin-right: auto;
							margin-right:0; //new
							min-width: fit-content;
						}

						.category-text {
							font-size: 1rem !important;
						}

						span :not(.ql-editor),
						b {
							color: #000000;
							span {
								font-size: 1rem !important;
							}
							font-size: 1.75rem !important;
						}

						b {
							// width: 100%;
						}

						b.colors-word {
							font-weight: bolder;
						}
						span.good {
							color: #02a377;
							font-weight: bolder;
						}

						span.bad {
							color: red;
							font-weight: bolder;
						}

						.warning-type-image {
							width: 1rem;
							min-width: 1rem;
						}
					}

					.default-text-main {
						b {
							line-height: 1rem !important;
						}

						.sub-cat {
							font-size: initial !important;
							line-height: 1.5 !important;
						}
					}

					.correct-chars {
						span {
							font-size: 1.75rem !important;
						}
					}

					.warning-container {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						border: 5px solid #1193f1;
						width: 100%;
						padding: 10px;
						margin-bottom: 10px;
						.ql-editor {
							all: unset;
							margin-right: auto !important;
							width: 100%;
							height: auto;
							max-height: 13rem;
							overflow: scroll;
							text-align: center;
							p {
								font-size: 1 rem;
								//font-weight: 400;
								display: block;
								justify-content: center;
								text-align: center !important;
							}
							span {
								display: inline-block;
								font-size: 1 rem;
							}
							.pre {
								gap: 0;
							}
						}
						h3 {
							font-size: 1.25rem !important;
							font-weight: bolder !important;
						}

						img {
							margin-left: auto;
							width: 2rem;
						}
					}
				}

				.test-info-container {
					display: flex;
					align-items: flex-end;
					justify-content: space-between;
					margin-top: auto;
					.comment-button {
						display: flex !important;
						justify-content: center;
						align-items: center;
						width: 20px;
						height: 20px;
						margin-right: -15px;
						border-radius: 40%;
						background-color: rgb(119, 119, 119);
						color: white;
						font-weight: 700;
						&:hover {
							cursor: pointer;
						}
					}
					h3 {
						font-size: 0.875rem !important;
						margin: 0;
					}
					.word-audio-container {
						display: flex;
						align-items: center;
						flex-direction: column;
						gap: 8px;

						div {
							cursor: pointer;
							display: flex;
							align-items: center;
							justify-content: center;
							gap: 8px;

							.listen-to-word-container {
								position: relative;
								border-radius: 10px;
								display: flex;
								justify-content: center;
								align-items: center;
								background-color: #008e00;
								padding: 5px;
								border-radius: 100px;

								img {
									width: 40px;
								}

								.arrow-image {
									width: 4rem;
									transform: rotate(320deg);
								}

								.speaker-image {
									position: absolute;
									width: 2rem;
								}
							}
							.listen-to-word-container.green {
								background-color: #02a377;
							}

							.listen-to-word-container.red {
								background-color: #9c1217;
							}
						}

						.slow-audio-btn {
							padding: 0;
							height: auto;
							font-size: 0.75rem !important;
							background-color: white;
							width: 48px;
							min-width: 48px;
							aspect-ratio: 1;
							border-radius: 100px !important;
							font-weight: bolder;
							width: fit-content;
						}

						.slow-audio-btn.normal {
							font-size: 0.75rem !important;
						}

						.auto-play-btn {
							position: relative;
							border: none;
							padding-top: 20px;

							span {
								position: absolute;
								bottom: 0;
								left: 50%;
								transform: translate(-50%, 120%);
								color: #000000;
								font-weight: normal;
							}

							img {
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								width: 52px;
							}
						}

						img {
							width: 1.5rem;
						}
					}

					.is-right-container-btn {
						position: relative;
						.hot-key {
							position: absolute;
							width: 1.5rem;
							height: 1.5rem;
							background-color: white;
							bottom: 0;
							right: 0;
							border: 1px solid #ca5050;
							display: flex;
							align-items: center;
							justify-content: center;
							border-radius: 100%;
							transform: translate(50%, 50%);

							span {
								color: #ca5050;
								text-align: center;
							}
						}
					}

					.btn-acerte {
						border: 1px solid #ca5050;
						font-size: 1rem !important;
						border-radius: 10px;
						background-color: #ffffff;
						color: #ca5050;
						padding: 7px 37px;
						height: auto;
						align-self: center;
					}
					.word-lang-container-text {
						display: flex;
						flex: auto;
						align-self: center;
						justify-content: center;

						color: red;
						font-weight: bolder;
						font-size: 30px;
					}
					.word-lang-container {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						gap: 10px;

						img {
							width: 64px;
							height: 64px;
						}
					}
				}
			}
		}

		.final-results {
			justify-content: center;

			.test-score-card {
				max-width: 550px;
				width: 100%;
				background: #ffffff;
				box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
				border-radius: 10px;

				.title {
					display: flex;
					justify-content: space-between;
					h2 {
						font-size: 1rem !important;
						margin: 0;
					}
				}

				.good-answers-row {
					p {
						font-size: 0.875rem !important;
					}
				}

				.results-row {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					gap: 10px;

					h2 {
						font-size: 2rem !important;
						font-weight: bolder !important;
						color: #ca5050;
						margin: 0;
					}

					h3 {
						font-size: 0.875rem !important;
						font-weight: bolder !important;
					}
				}
			}

			.time-text-container,
			.words-dominated-quiz {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-items: flex-end;

				div {
					width: 100%;
					max-width: 125px;
					h2 {
						text-align: center;
						font-size: 1rem;
						margin: 0;
						color: #aaaaaa;

						img {
							width: 1rem;
							height: 1rem;
						}
					}

					.label {
						font-size: 0.75rem;
					}
				}
			}

			.words-dominated-quiz {
				div {
					max-width: 170px;
					h2 {
						color: black;
						font-size: 0.875rem !important;
					}
				}
				.dominated-number,
				.dominated-label {
					font-size: 3rem !important;
					color: #ffb300;
					cursor: pointer;

					.offset-header {
						font-style: normal;
						letter-spacing: -0.02em;
						max-width: 500px;
					}
					span.offset-header {
						overflow: hidden;
						animation-name: slideUp;
						animation-duration: 3s;
						animation-timing-function: ease-in;
						display: block;
					}
				}
			}
		}

		.final-screen-text {
			width: 100%;
			h3 {
				font-size: 1.5rem !important;
				font-weight: bolder !important;
			}
		}

		.test-footer {
			display: flex;
			gap: 20px;
			width: 100%;

			.test-special-chars-card {
				background-color: #ffffff;
				box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
				min-width: 300px;
				width: 100%;
				max-width: 372px;
				max-height: 82px;
				overflow: hidden;

				border-radius: 10px;
				display: flex;
				align-items: center;
				padding: 10px;
				justify-content: flex-start;
				flex-wrap: wrap;
				gap: 8px;
				position: relative;

				transition: 0.5s ease max-height;

				.card-char {
					width: 64px;
					height: 64px;
					background: #ffffff;
					box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
					border-radius: 10px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					user-select: none;

					h2 {
						text-align: center;
						margin: 0;
						color: #ca5050;
						font-size: 2rem !important;
						font-weight: bolder;
					}
					img {
						width: 1.5rem;
						height: 1.5rem;
						transform: rotate(-90deg);
					}
				}

				.special-keyboard {
					width: 100%;
					box-shadow: none;
					border-bottom: 1px;
					border-radius: 0px;
					h2 {
						font-size: 1rem !important;
					}
				}

				.active {
					background-color: #ca5050;
					h2 {
						color: #ffffff;
					}

					img {
						filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(20deg) brightness(200%) contrast(100%);
					}
				}

				.upper-case {
					position: absolute;
					top: 10px;
					display: none;
				}
			}

			.test-special-chars-card-active {
				max-height: 600px;
				transition: 0.5s ease max-height;

				.upper-case {
					display: flex;
				}
			}

			.input-container {
				display: flex;
				flex-direction: column;
				width: 100%;
				gap: 10px;

				h3 {
					font-size: 1rem !important;
					font-weight: 500 !important;
					margin: 0;
				}

				.input-btn {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 10px;
					flex-direction: row;

					.input {
						border: 1px solid rgba(0, 0, 0, 0.3);
						border-radius: 10px;
						width: 100%;
						position: relative;

						.term-input-information-container {
							position: absolute;
							left: 50%;
							top: 50%;
							width: 100%;
							transform: translate(-50%, -50%);
							z-index: -1;

							.test-word-container {
								h2,
								span {
									font-size: 1rem !important;
									line-height: 1rem !important;
								}

								h2 {
									span {
										span {
											white-space: nowrap !important;
										}
									}
								}

								.see-info-btn,
								.text-p {
									display: none;
								}

								br {
									display: none;
								}
							}
						}

						.alert-text {
							font-size: 1rem;
							position: absolute;
							opacity: 0;
							left: 50%;
							top: -25%;
							z-index: -1;
							transform: translate(-50%, -50%);
							color: #ca5050;
							text-align: center;
							margin: 0;
							width: 100%;
						}

						.is-wrong {
							opacity: 1;
							animation-name: blink;
							animation-duration: 3s;
							animation-iteration-count: 2;
						}

						input {
							border: none;
							outline: none;
							padding: 12px 10px;
							width: 100%;
							background-color: transparent;
						}
					}

					button {
						background: linear-gradient(90.31deg, #ee1c34 0.26%, #500b28 99.73%);
						color: white;
						padding: 10px;
						font-size: 1rem !important;
						width: 140px;
						min-width: 140px;
					}

					.next-btn {
						position: relative;
						.hot-key {
							position: absolute;
							width: 1.5rem;
							height: 1.5rem;
							background-color: white;
							bottom: 0;
							right: 0;
							border: 1px solid #ca5050;
							display: flex;
							align-items: center;
							justify-content: center;
							border-radius: 100%;
							transform: translate(50%, 50%);

							span {
								color: #ca5050;
								text-align: center;
							}
						}
					}
				}
			}

			.options-btns {
				display: flex;
				justify-content: center;
				flex-direction: column;
				align-items: center;
				gap: 20px;
				width: 100%;

				.is-right-input-container {
					max-width: 300px;
					border-radius: 10px;
					border: 1px solid #00000080;
					align-self: center;
					height: auto;
					width: 100%;

					input {
						border: none;
						border-radius: 10px;
						font-size: 1rem !important;
						padding: 7px;
						width: 100%;
					}
				}

				button {
					font-size: 1rem !important;
					padding: 0px 20px;
					border-radius: 10px;
				}

				.prev-btn {
					border: 1px solid #ca5050;
					color: #ca5050;
					background-color: #ffffff;
				}

				.next-btn {
					background: linear-gradient(90.31deg, #ee1c34 0.26%, #500b28 99.73%);
					color: white;
				}

				@media (max-width: 400px) {
					// Puedes ajustar este valor para el punto de quiebre que prefieras
					.next-btn {
						//margin-top: -21px;
						height: 40px;
					}
				}
			}
		}
	}
}
@media (max-width: 991px) {
	.test-main-container {
		gap: 10px;
		.quiz-name-container {
			display: block;
			height: 2rem;
			h2 {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
		.test-container {
			gap: 10px;
			.test-progress-container {
				position: absolute;
				height: 620px;
				width: 130px;
				right: 0px;
				top: 10%;
				max-width: 0px;
				overflow: hidden;
				transition: 0.5s ease all;

				.test-progress-card {
					height: 100%;
					width: 100%;
					padding: 10px;
					border-radius: 10px 0px 0px 10px;
					box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
					position: absolute;
					z-index: 20;
					flex-direction: row-reverse;
					align-items: center;
					justify-content: center;
					gap: 0px;

					.title-container {
						height: 100%;
						align-items: flex-start;

						.title {
							display: none;
						}

						.test-progress-info-container {
							.segments-container {
								flex-direction: column;
								gap: 10px;
							}
						}
					}

					.test-progress-bar-container {
						height: 100%;
						padding: 20px;
						padding-top: 0px;
					}
				}
				.close-card {
					display: block;
					position: absolute;
					background-color: #ffffff;
					width: 100px;
					height: 130px;
					box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
					border-radius: 10px;
					display: flex;
					align-items: center;
					left: -40px;
					z-index: 1;
					top: 50%;
					transform: translate(0, -50%);
				}
			}

			.test-progress-container-open {
				max-width: 300px;
				overflow: visible;
				transition: 0.5s ease all;
			}

			.progress-score-btn-container {
				display: flex;
				justify-content: space-between;
			}

			.test-info-container {
				align-items: center !important;
				.test-score-card-container {
					position: absolute;
					max-width: 0px;
					left: 0;
					top: 50%;
					transform: translate(0px, -50%);
					border-radius: 0px 10px 10px 0px;
					overflow: hidden;
					min-width: 0px;
					transition: 0.5s ease all;
					z-index: 2;
				}

				.test-score-card-open {
					max-width: 300px;
					transition: 0.5s ease all;

					.close-icon-container {
						display: block;
						position: absolute;
						right: 5px;
						top: 5px;
					}
				}
			}

			.test-footer {
				flex-direction: column;
				justify-content: center;
				align-items: center;
				margin-top: 20px;

				.incorrects-responsive {
					flex-wrap: wrap;
					justify-content: center;

					.input-btn {
						align-items: center !important;
					}
				}

				.test-special-chars-card {
					order: 2;
					width: 100%;
					min-width: 372px;
				}

				.input-container {
					order: 1;
					.input-btn {
						flex-direction: row;
					}
				}
			}
		}
	}
}

@keyframes slideUp {
	0%,
	50% {
		transform: translateY(100%);
		opacity: 0;
	}

	60%,
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes rocketMove {
	0% {
		top: 0px;
		left: 0px;
	}
	50% {
		top: -35px;
		left: 50px;
	}
	100% {
		top: 0px;
		left: 0px;
	}
}

@keyframes blink {
	0% {
		color: #ca5050;
	}
	25% {
		color: transparent;
	}
	50% {
		color: #ca5050;
	}
	75% {
		color: transparent;
	}
	100% {
		color: #ca5050;
	}
}
@keyframes blink2 {
	0% {
		opacity: 1;
	}
	10% {
		opacity: 0;
	}
	20% {
		opacity: 1;
	}
	30% {
		opacity: 0;
	}
	40% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	60% {
		opacity: 1;
	}
	70% {
		opacity: 0;
	}
	80% {
		opacity: 1;
	}
	90% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes blinkandDecre {
	0% {
		opacity: 1;
		width: 1rem;
		height: 1rem;
	}
	10% {
		opacity: 0;
	}
	20% {
		opacity: 1;
	}
	30% {
		opacity: 0;
	}
	40% {
		opacity: 1;
	}
	50% {
		opacity: 0;
		width: 0.5rem;
		height: 0.5rem;
	}
	60% {
		opacity: 1;
	}
	70% {
		opacity: 0;
	}
	80% {
		opacity: 1;
	}
	90% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes blinkandGrow {
	0% {
		opacity: 1;
		width: 0.5rem;
		height: 0.5rem;
	}
	10% {
		opacity: 0;
	}
	20% {
		opacity: 1;
	}
	25% {
	}
	30% {
		opacity: 0;
	}
	40% {
		opacity: 1;
	}
	50% {
		opacity: 0;
		width: 0.5rem;
		height: 0.5rem;
	}
	60% {
		opacity: 1;
	}
	70% {
		opacity: 0;
	}
	80% {
		opacity: 1;
	}
	90% {
		opacity: 0;
	}
	100% {
		opacity: 1;
		width: 1rem;
		height: 1rem;
	}
}
@media (max-width: 560px) {
	.test-main-container {
		.test-container {
			.test-info-container {
				.test-words-container {
					.info-word-container {
						p {
							b {
								font-size: 0.9 rem !important;
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: 505px) {
	.test-main-container {
		.test-container {
			.test-info-container {
				.test-words-container {
					.test-word-container {
						h2 {
							font-size: 2.5rem !important;
						}
					}
				}

				.btn-acerte {
					padding: 7px 5px !important;
				}
			}
		}
	}
}
@media (max-width: 441px) {
	.test-main-container .test-container .test-info-container .test-words-container .test-info-container .btn-acerte {
		font-size: 0.7rem !important;
	}
	.test-main-container .test-container .test-info-container .word-lang-container-text {
		font-size: 23px !important;
		span.default-text {
			width: 130px !important;
		}
	}
	.test-main-container .test-container .test-info-container .test-words-container .test-info-container h3 {
		font-size: 0.75rem !important;
	}
}
@media (max-width: 405px) {
	.test-main-container {
		.test-container {
			.test-info-container {
				.test-words-container {
					.info-word-container {
						p {
							b,
							span.bad,
							span.good {
								font-size: 1rem !important;
							}
						}
					}
				}
			}
		}
	}
}
