$baseurl: "";
.edit-gen-card.collaborator {
	margin-top: 20px;
	padding: 10px;
	height: 80vh;
	
	.button-container {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 10px;
 
	}

	.tertiary {
		background-color: #ccc;
		color: #fff;
		padding: 8px 16px;
		border: none;
		border-radius: 3px;
		cursor: pointer;
		font-size: 14px;
		font-weight: bold;
		height: 40px !important;
		transition: background-color 0.3s ease;

		&:hover {
			background-color: #999;
		}
	}

	.return-btn-style {

	}


}
