$baseurl: "";
@import "theme/colors", "theme/variables";

.thankyou_container {
  min-height: 70vh;
  padding: $spacing-3x $spacing $spacing $spacing;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: auto;
}

.thankyou_container div button {
  margin-bottom: 70px;
}

.recibo-container {
  width: 100%;
  padding: 30px;
  position: relative;
  margin-bottom: 40px;
  margin-top: 40px;
  box-shadow: 1px 1px 10px 4px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}

.recibo-container h4 {
  font-size: 1.1rem;
}

.recibo-description {
  font-size: 1rem !important;
}

.recibo-container img {
  position: absolute;
  bottom: 0;
  right: 0;
}

.return-invoice-btns-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.recibo-container h2.thanks {
  text-align: center;
}

.recibo-container p.send-text {
  text-align: center;
}

.return-btn-style {
  background: linear-gradient(to right, #ee1c34, #500b28) !important;
  color: white !important;
  font-weight: 500 !important;
}

.order-data-variable {
  font-size: 1rem !important;
  font-weight: bold !important;
}

.welcome-thankyou {
  font-size: 1.5rem !important;
}

@media (max-width: 639px) {
  .return-invoice-btns-container {
    justify-content: center;
  }
}

@media (max-width: 1149px) {
  .recibo-container img {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

@media (max-width: 715px) {
  .recibo-container h2.thanks {
    font-size: 1.5rem;
  }

  .recibo-container p.send-text {
    font-size: 1rem !important;
    overflow-wrap: break-word;
  }

  .thankyou_container {
    width: 100%;
  }
}
