$baseurl: "";
@import "@theme/mixin.scss";

.main-slider-main-container {
	width: 100vw;
	max-width: 2100px;
	position: relative;
	.content-container {
		width: 100%;
		aspect-ratio: 1440/570;

		.slide-main-container {
			width: 100%;
			height: 100%;
			position: relative;
		}
		.gall-image {
			width: 100%;
			object-fit: cover;
			height: 100%;
		}
		.gall-image-responsive {
			display: none;
			width: 100%;
			object-fit: cover;
			height: 100%;
		}
	}
	.mx-ctn {
		.usd {
			display: none;
		}
	}
	.us-ctn {
		.mxn {
			display: none;
		}
	}
	ul {
		display: flex;
		list-style: none;
		width: 100%;
		padding: 0px 75px;
		overflow-x: scroll;
		.responsive {
			display: none;
		}
		li {
			flex: 1;
			display: flex;
			text-align: center;
			justify-content: center;
			align-items: center;
			padding: 20px 0px;
			cursor: pointer;
			color: #ffffff;
			font-family: "inter";
			font-weight: 600;
			transition: 400ms ease all;
			&.active {
				background-color: #ffffff1a;
			}
			&.slide-0 {
				min-width: 300px;
				width: 300px;
				max-width: 300px;
				&.active {
					// background-color: #FBC754;
					background-color: #79531b;
					color: #fbc754;
				}
			}
		}
	}

	@include respond-to(md) {
		ul {
			padding: 0px 20px;
			li {
				flex: 0 0 200px;
				flex-shrink: 0;

				&.slide-0 {
					width: auto;
					min-width: 0px;
				}
			}
		}
	}

	@include respond-to(sm) {
		.content-container {
			aspect-ratio: 1;
		}
	}
	@media (max-width: 800px) {
		// transform: translateX();
		ul {
			.responsive {
				display: unset;
			}
			.web {
				display: none;
			}
		}
		.content-container {
			.gall-image {
				display: none;
			}
			.gall-image-responsive {
				display: unset;
			}
		}
	}
}

.main-slider-main-container::before {
	content: "";
	background-color: #001036;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -2; /* Behind the image */
}
