$baseurl: "";
@import "theme/colors", "theme/variables";

.section__counter .container {
  h4 {
    font-size: 21px !important;
  }
}
.star-img {
  position: absolute;
  margin-top: -80px;
  margin-left: -10px !important;
  max-width: 114px!important;
}

.star-img-sm {
  left: 0;
  right: 0;
  margin-left: auto !important;
  margin-right: auto;
  text-align: center;
  // position: absolute;
  bottom: -80px;
}

@media screen and (max-width: 550px) {
  .section__counter .container {
    margin-bottom: 0px !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1393px) {
  .section__counter .star-img {
    width: 22%;
  }
}
