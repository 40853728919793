$baseurl: "";
.vocabulary-categories-main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .line {
        border-bottom: 2px solid #00000040;
    }

    .title-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    .categories-cards-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .category-card {
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 20px;

            h2 {
                font-size: 2rem;
                margin: 0;
            }

            button {
                margin: 0;
            }
        }
    }
}