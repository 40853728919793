$baseurl: "";
.temary-card-recordings {
    width: 100%;
    max-height: 68.8px;
    overflow: hidden;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 300px;
    overflow: scroll;
    transition: 0.5s ease all;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        user-select: none;

        h2 {
            margin: 0;
            font-size: 1.5rem !important;
            color: #ca5050;
            font-family: Lato !important;
            font-weight: 400 !important;
        }

        img {
            display: block;
            transform: rotate(90deg);
            width: 1.5rem;
            transition: 0.5s ease all;
        }
    }
}

.open-temary-card {
    max-height: 600px;
    transition: 0.5s ease all;

    .header {
        img {
            transform: rotate(-90deg);
            transition: 0.5s ease all;
        }
    }
}
