$baseurl: "";
@import 'theme/colors', 'theme/variables';

.accordion__container {
  padding: $spacing-2x 90px;
  .accordion {
    border: 0;
    border-radius: 20px;
  }
  .accordion__item {
    margin-bottom: $spacing;
    border-top: 0;
  }
  .accordion__button {
    border-radius: 12px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3) !important;
    font-family: $font-family-secondary;
    font-weight: $font-weight-regular;
    background-color: #fff;
    color: $color-secondary;
    font-size: 24px;
  }
  .accordion__panel p {
    font-weight: $font-weight-medium !important;
  }
  a {
    color: $color-black;
    &:hover {
      color: $color-secondary;
    }
  }
  //padding: $spacing-2x 0;
}

.faq-promotions-container {

  * {
    margin: 0;
  }

  display: flex;
  flex-direction: column;
  gap: 80px;

  .header {
    
    .title {
      font-size: 3rem;
      color: #d54632;
    }
    p {
      font-weight: bolder!important;

    }
  }

  .promotion-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    h2 {
      color: #25651f;
      font-size: 1.5rem;
      font-weight: bolder!important;
    }

    .promotion-info-container {
      padding-left: 1rem;

      h3 {
        color: #d54632;
        font-size: 1.5rem!important;
      }
    }

  }
  .promotion-legend-text {
    white-space: pre-line;

    span {
      color: #d54632;
    }
  }
}

.faq-after-firstpay-container {
  display: flex;
  flex-direction: column;
  gap: 30px;

  .paragraph-container {
    p {
      font-weight: 400!important;

      span {
        font-weight: bolder;
      }
      .open-promotions{
        cursor:pointer;
        &:hover{
          color:#bc4e4e;
        }
      }
    }
  }
}

.week-doubts-list {
  color: red;
  font-size: 2rem;
  padding-left: 38px;
  
  li {
    h3 {
      font-size: 1.5rem!important;
      font-weight: 500!important;
    }

    p {
      font-size: 1.25rem!important;
      color: #000;
      word-break: break-word;
    }
  }
}

@media screen and (min-width: 350px) and (max-width: 750px) {
  .accordion__container {
    padding: 0;
  }
}
