$baseurl: "";
.vocabulary-language-admin-main-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .difficult-color-circle {
        width: 1rem;
        height: 1rem;
        border-radius: 1rem;
        margin: auto;
    }
    .title {
        h1 {
            font-size: 2.25rem !important;
            margin: 0;
        }
    }

    .words-container {
        font-family:sans-serif;
        span{
            font-family:sans-serif;
        }
        .difficult-filter-container {
            margin-top: 20px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;
            flex-wrap: wrap;

            .input-container {
                display: flex;
                gap: 5px;
                align-items: center;
                label {
                    margin: 0px;
                }
            }
        }

        table {
            tbody {
                tr {
                    td.seminaries-td {
                        display: flex;
                        align-items: center;
                        border: none;

                        span {
                            margin: 0;
                            flex: 1;
                        }
                    }
                }
            }
        }
    }
}
