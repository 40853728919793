.materials-subtitle-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}



.no-material-card {
    padding: 20px;
    background-color: #f6f7fb;
    border: 2px solid #989898;
    border-radius: 10px;
    color: #989898;
    margin-bottom: 20px;
}
.materials-list-container {
    height: calc(100vh - 200px); /* Ajusta este valor según el espacio que necesites */
    overflow-y: auto;
   /*position: relative;*/
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
.material-card {
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin-bottom: 20px;
}

.dropfiles-materiales {
    width: 100%;
    height: 300px;
    border-radius: 10px;
    border: 2px dashed #989898;
    background-color: #f6f7fb;
    position: relative;;
    margin-bottom: 40px;
}

.dropfiles-materiales label {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    color: #989898;
    text-align: center;
    padding: 0 40px;
    flex-direction: column;
}

.dropfiles-materiales label {
    cursor: copy;
}

.dropfiles-materiales input {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    color: transparent;
    background: transparent;
    opacity: 0;
}

.delete-icon-btn {
    cursor: pointer;
}

.save-materials-btn {
    width: auto;
    height: auto;
    width: 250px;
    border-radius: 10px;
    border: none;
    color: #ffffff;
    font-weight: 500;
    font-size: 1rem;
    padding: 10px;
    background: linear-gradient(to right, #ee1c34, #500b28);
}

.filter-date-selector {
    padding: 5px;
    width: 200px;
    max-width: 200px;
    min-width: 200px;
    border-radius: 10px;
    margin: 0 10px;
}

.generations-card-right {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.files-next-seminary-container {
    text-align: center;
    width: 100px;
}

.files-next-seminary-container p {
    margin: 0;
}

.add-parts-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 1.1rem;
    overflow: hidden;
    margin-bottom: 20px;
    transition: 0.5s ease all;
}

.views-recordings-container {
    display: flex;
    justify-content: end;
    gap: 10px;
}

.views-recordings-container .recordings-views-container {
    width: auto;
}

.views-recordings-container .recordings-views-container.total-views {
    cursor: initial;
}

.add-parts-container div {
    width: 100%;
}

.add-parts-container.active {
    max-height: 300px;
    transition: 0.5s ease all;
}

.add-parts-container .add-parts-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    margin-bottom: 10px;
}

.add-parts-container .add-parts-btn h3 {
    font-size: 1rem!important;
    margin: 0;
    line-height: 1rem;
}

.add-parts-container .add-parts-btn img {
    transform: rotate(0deg);
    transition: 0.5s ease all;
}
.add-parts-container.active .add-parts-btn img {
    transform: rotate(180deg);
    transition: 0.5s ease all;
}

.gen-name-container {
    width: calc(100% + 30px);
    position: sticky;
    top: 53px;
    background: white;
    padding: 15px;
    margin-bottom: 20px;
    margin-left: -15px;
    margin-top: -40px;
}

@media (max-width: 536px) {
    .gen-name-container {
        top:68px
    }
}
