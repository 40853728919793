$baseurl: "";
.admin-gen-selector-bar-container {
    margin-bottom: 10px;
display: grid;
    h3 {
        text-align: center;
        font-size: 2rem !important;
    }

    .admin-gen-selector-bar {
        display: flex;
        justify-content: center;
        max-width: 950px;
        margin: auto;
        border-radius: 10px;
        border: 3px solid #ca5050;
        overflow: hidden;
        width: 100%;

        .cell {
            flex: 1;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ca5050;
            border-right: 1px solid #ca5050;
            cursor: pointer;
        }

        .active {
            color: white;
            background-color: #ca5050;
        }
    }

    .green {
        filter: invert(59%) sepia(38%) saturate(2953%) hue-rotate(76deg) brightness(112%) contrast(121%);
    }
    .orange {
        filter: invert(70%) sepia(25%) saturate(4762%) hue-rotate(358deg) brightness(102%) contrast(108%);
    }
    .blue {
        filter: invert(9%) sepia(88%) saturate(7452%) hue-rotate(247deg) brightness(92%) contrast(148%);
    }
}
.admin-profile {
    margin-bottom: unset;
    order: 1;
    display: grid;
   
    h3 {
      //  display: none;
        text-align: center;
        font-size: 2rem !important;
    }
    
    .admin-gen-selector-bar {
        display: flex;
        justify-content: center;
        max-width: 950px;
        margin: auto;
        border-radius: 10px;
        border: 3px solid #ca5050;
        overflow: hidden;
        width: 100%;

        .cell {
            flex: 1;
            height: 80px;
            font-size: 13px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ca5050;
            border-right: 1px solid #ca5050;
            cursor: pointer;
            @media (max-width: 500px) {
                font-size: 8px;
            }
        }

        .active {
            color: white;
            background-color: #ca5050;
        }
    }
    @media (max-width: 869px) {
        width: -webkit-fill-available;
    }
    .green {
        filter: invert(59%) sepia(38%) saturate(2953%) hue-rotate(76deg) brightness(112%) contrast(121%);
    }
    .orange {
        filter: invert(70%) sepia(25%) saturate(4762%) hue-rotate(358deg) brightness(102%) contrast(108%);
    }
    .blue {
        filter: invert(9%) sepia(88%) saturate(7452%) hue-rotate(247deg) brightness(92%) contrast(148%);
    }
}
@media screen and (max-width: 510px) {
    .cell {
      font-size: 10px;
    }
  }