$baseurl: "";
@import "@theme/mixin.scss";

.home-contact-main-container {
	display: flex;
	flex-wrap: wrap;
	display: flex;
	align-items: center;
	justify-content: center;

	* {
		font-family: "inter";
	}

	.head {
		width: 30%;
		height: fit-content;
		min-width: 300px;
		background-color: #cf072c;
		// aspect-ratio: 219/181;
		padding: 100px 40px;
		background-image: url("../../../../assets/images/ali-bg.png");
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;

		h3 {
			font-size: 1.5rem;
			color: #ffffff;
			font-weight: bold;
		}

		p {
			font-size: 1rem;
			color: #ffffff;
			font-weight: 600;
		}
	}

	.new-letter-container {
		width: 70%;
		padding: 115px 75px;
		background-color: #cdf6f0;

		h2 {
			font-size: 3rem;
			font-weight: 500;
			b {
				font-weight: bolder;
				font-family: "inter black";
			}
		}

		p {
			font-size: 1.25rem;
			font-weight: 400;
		}

		form {
			display: flex;
			flex-direction: column;
			gap: 10px;
			.form-row {
				padding: 0px;
				margin: 0px;
				display: flex;
				flex-direction: column;
				gap: 5px;

				label {
					font-family: "inter" !important;
					font-size: 14px !important;
					font-weight: bolder !important;
					margin: 0;
				}

				input, select, textarea {
					flex-grow: 1;
					background-color: transparent;
					border: 2px solid #cf072c;
					padding: 5px 10px;
					font-size: 14px;

					&::placeholder {
						color: black;
					}
				}
				p.form-error-msg {
					color: #cf072c;
					font-size: 0.875rem;
					font-weight: bold;
					font-family: "inter";
				}
			}

			button {
				border-radius: 0px !important;
				background-color: #cf072c;
				color: #ffffff;
				padding: 5px 25px;
				font-size: 1rem !important;
				color: #cdf6f0;
				font-weight: 500;
			}
		}
	}

	@include respond-to(md) {
		.head {
			display: none;
		}

		.new-letter-container {
			width: 100%;
			position: relative;
			padding: 60px 20px 60px 40px;

			h2 {
				font-size: 1.5rem;
			}

			p {
				font-size: 1rem;
			}

			form {
				width: 100%;

				input {
					font-size: 0.875rem;
				}
			}
			&::before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				display: inline-block;
				height: 100%;
				width: 20px;
				background-color: #cf072c;
			}
		}
	}
}
