.record-buttons-container {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.record-buttons-container button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 50px;
    height: 50px;
    border-radius: 100px!important;
}


.record-buttons-container button.record {
    background-color: rgb(48, 209, 136);
}

.record-buttons-container button.stop-record {
    background-color: rgb(56, 114, 201);
}

.record-buttons-container button.delete-record {
    background-color: #c93838;
}

.record-buttons-container button:disabled {
    background-color: rgb(112, 112, 112);
}

.record-buttons-container button img {
    width: 30px;
    filter: invert(100%) sepia(0%) saturate(7498%) hue-rotate(321deg) brightness(104%) contrast(101%);
}

.record-buttons-container button.stop-record img {
    width: 20px;
}

.time {
    font-size: 3rem;
    text-align: center;
}

.preview-audio-and-upload-btn-container {
    display: flex;
    gap: 15px;
}

.preview-audio-and-upload-btn-container button.upload-btn {
    background: linear-gradient(to right, #ee1c34, #500b28);
    color: white !important;
    font-weight: 500 !important;
    padding: 0;
    width: 100px;
}

.preview-audio-and-upload-btn-container button.upload-btn:disabled {
    background: rgb(112, 112, 112)!important;
}

