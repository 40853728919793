.progress-bar-text-ball {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate(-50%, 0%);
    margin: 0;
}

.not-show {
    display: none;
}

.sesiones-text-progress-bar {
    font-size: 1rem!important;
    white-space: nowrap;
    top: 30px;
}