$baseurl: "";
.final-results {
    .test-score-card {
        .good-answers-row {
            .label-container {
                .question-icon {
                    display: flex;
                    justify-content: center;

                    align-items: center;
                }
                .question-icon:hover {
                    .info-box {
                        position: absolute;
                        left: 15px;
                        transform: translate(0px, -50%) !important;
                        p {
                            margin: 0;
                            text-align: center;
                        }
                    }
                }
            }
        }

        .circle-progress-flag-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .circle-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                h2 {
                    font-size: 0.875rem !important;
                }
                .progress-container {
                    cursor: pointer;
                    border-radius: 100px;
                    width: 70px;
                    position: relative;

                    img {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        width: 60px;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }
    }
}

.modal-quiz-results-info {
    .modal-dialog {
        span.acerte-word {
            color: #af34af;
            font-size: 1.125rem!important;
            line-height: 0.9rem!important;
            font-weight: bolder;
        }
        .modal-header {
            display: flex;
            flex-direction: column;

            h3 {
                font-size: 1rem!important;
            }
        }
        .modal-content {
            .modal-header {
                .modal-title {
                    span {
                        color: #ca5050;
                    }
                }
            }
            .modal-body {
                padding: 1rem 5px;
                h3 {
                    font-size: 1.25rem !important;
                    font-weight: bolder !important;
                }

                p {
                    font-size: 0.9rem !important;
                }

                b {
                    color: #000000;
                }

                span.good {
                    color: #02a377;
                    font-weight: bolder;
                }

                span.bad {
                    color: red;
                    font-weight: bolder;
                }

               
            }

            .modal-footer {
                justify-content: center;
            }
        }
    }
}

.close-modal {
    color: #ca5050;
    font-size: 20px;
}
.close-modal:hover {
    cursor: pointer;
}

@media (max-width: 515px) {
    .final-results {
        .test-score-card {
            .good-answers-row {
                .label-container {
                    .question-icon:hover {
                        .info-box {
                            width: 200px !important;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 404px) {
    .final-results {
        .test-score-card {
            .good-answers-row {
                .label-container {
                    .question-icon:hover {
                        .info-box {
                            width: 150px !important;
                        }
                    }
                }
            }
        }
    }
}
