$baseurl: "";
.vocabulary-seminary-main-container {
	.header-container {
		.title-container {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			flex-wrap: wrap;

			h1 {
				margin: 0;
			}

			.time-container {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-items: center;

				h3 {
					margin: 0;
					font-size: 1rem !important;
					display: flex;
					align-items: center;
					justify-content: center;

					img {
						width: 24px;
						height: 24px;
					}
				}

				p {
					font-size: 0.75rem !important;
					margin: 0;
					color: #ca5050;
				}
			}
		}
	}

	.responsive-circle-progress-main {
		display: none;
	}

	.pronunciation-btn-container {
		margin-bottom: 20px;
		// width: 340px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		max-width: 340px;
		gap: 10px;

		button {
			background-color: white;
			color: #ca5050;
			border: 2px solid #ca5050;
			border-radius: 10px;
			font-size: 2rem;
			display: flex;
			align-items: center;
			justify-content: center;
			flex: 1;
			padding: 7px 0;
			gap: 10px;
			img {
				width: 2rem;
			}
		}
		input {
			max-width: 279px;
			background-color: white;
			border: 2px solid #646464;
			border-radius: 7px;
			padding: 0px 15px;
			font-size: 20px;
			height: 51px;
			flex: 1;
		}
		::placeholder {
			color: #c0c0c0;
		}
		.search-container {
			background-color: white;
			color: #ca5050;
			border: 2px solid #ca5050;
			border-radius: 10px;
			height: 51px;
			width: 51px;
			min-width: 51px;
			display: grid;
			align-items: center;
			justify-content: center;
			img {
				filter: invert(44%) sepia(78%) saturate(455%) hue-rotate(315deg) brightness(84%) contrast(92%);
			}
		}
	}

	.vocabulary-content-main-container {
		display: flex;

		.vocabulary-tests-container {
			display: flex;
			flex-direction: column;
			gap: 20px;
			width: 100%;

			.tests-buttons-container {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 40px;

				.test-card {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					background: #ffffff;
					box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
					border-radius: 10px;
					width: 250px;
					height: 250px;
					cursor: pointer;
					gap: 30px;
					position: relative;

					.percentage-bar-container {
						position: absolute;
						bottom: 0;
						width: 100%;
						left: 0;
						h3,
						img {
							display: none;
						}

						.bar-container {
							display: block;
							position: absolute;
							left: 0;
							bottom: 0;
							width: 100%;
							margin: 0;
							border-radius: 0px 0px 10px 10px;

							.bar-progress {
								border-radius: 0px 0px 10px 10px;
							}

							.point-progress {
								display: none;
							}
						}
					}

					.personalized-div-test-card {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						width: 100%;

						span {
							color: #ca5050;
							font-size: 1.15rem !important;
							font-weight: bolder;
							margin: 0;
						}
					}
					h3 {
						span {
							color: #ca5050;
						}
						font-size: 1rem !important;
						margin: 0;
					}

					.default-quizzes-card-text {
						margin: 0;
						width: 100%;
						position: absolute;
						font-size: 0.875rem !important;
						color: #00000080;
						left: 0;
						right: auto;
						top: auto;
						bottom: 10px;
						text-align: center;
					}
				}

				.about-vocabulary-btn-container {
					display: none;

					button {
						border: 1px solid #ca5050;
						border-radius: 10px !important;
						background-color: #ffffff;
						font-size: 0.75rem !important;
						color: #ca5050;
						padding: 5px 40px;
						height: auto;
					}
				}
			}

			.tests-buttons-seminary-zero-container {
				flex-direction: column;
				gap: 10px;
				padding-left: 10px;

				.test-card {
					flex-flow: row-reverse;
					height: auto;
					padding: 0px 10px;
					max-width: 340px;
					min-width: 320px;
					justify-content: space-between;
					padding-bottom: 10px;

					img {
						height: 96px;
						width: 96px;
						opacity: 0.5;
					}

					.gold-rocket {
						opacity: 1;
					}

					.default-quizzes-card-text {
						width: auto;
						left: 0;
						right: auto;
						top: auto;
						bottom: 10px;
						transform: translate(25%, 0%);
						text-align: center;
					}
				}
			}

			.history-vocabulary-seminary-card {
				display: flex;
				flex-direction: column;
				gap: 20px;
				padding: 20px;

				.title {
					font-size: 1.125rem !important;
					margin: 0;
					font-weight: 400;
				}
				
				.quizzes-vocabulary-lang{
					display: flex;
					justify-content: flex-end;
					.quizzes-vocabulary-lang-selector{
						border: 2px solid #646464;
						border-radius: 7px;
					}
				}

				.no-quizzes {
					font-size: 1.25rem !important;
				}
			}
		}

		.detail-seminary-card-container {
			box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
			border-radius: 10px;
			align-self: flex-start;
		}

		.back-modal-detail-seminary-card {
			position: fixed;
			height: 100vh;
			width: 100vh;
			top: 0;
			left: 0;
			background-color: rgba(0, 0, 0, 0.5);
			z-index: 100;
			display: none;
		}
	}
}

@media (max-width: 1230px) {
	.vocabulary-seminary-main-container {
		.vocabulary-content-main-container {
			.vocabulary-tests-container {
				.tests-buttons-container {
					flex-direction: column;
					gap: 10px;
					padding-left: 10px;

					.test-card {
						flex-flow: row-reverse;
						height: auto;
						padding: 0px 10px;
						max-width: 340px;
						min-width: 320px;
						justify-content: space-between;
						padding-bottom: 10px;

						img {
							height: 96px;
							width: 96px;
							opacity: 0.5;
						}

						.gold-rocket {
							opacity: 1;
						}

						.default-quizzes-card-text {
							width: auto;
							left: 0;
							right: auto;
							top: auto;
							bottom: 10px;
							transform: translate(18%, 0%);
							text-align: center;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 991px) {
	.vocabulary-seminary-main-container {
		.header-container {
			.title-container {
				margin-bottom: 20px;

				.time-container {
					align-items: center;

					h3 {
						gap: 6px;
					}
				}
			}
		}

		.responsive-circle-progress-main {
			display: flex;
		}

		.pronunciation-btn-container {
			width: auto;
			display: none;
		}

		.vocabulary-content-main-container {
			.detail-seminary-card-container {
				position: absolute;
				top: 0;
				left: 50%;
				transform: translate(-50%, 20px);
				max-width: 0px;
				overflow: hidden;
				transition: 0.5s ease all;
				z-index: 101;
			}

			.open-card {
				max-width: 500px;
				transition: 0.5s ease all;
			}

			.back-modal-detail-seminary-card-open {
				display: block;
			}

			.vocabulary-tests-container {
				.tests-buttons-container {
					flex-direction: column;
					gap: 10px;
					padding: 0;

					.test-card {
						flex-flow: row-reverse;
						height: auto;
						padding: 0px 10px;
						max-width: none;
						min-width: 320px;
						width: 100%;
						justify-content: space-between;
						padding-bottom: 10px;

						img {
							height: 96px;
							width: 96px;
							opacity: 0.5;
						}

						.personalized-div-test-card {
							width: auto;
						}
						.personalized-div-test-card-1 {
							width: auto;
						}
					}

					.about-vocabulary-btn-container {
						display: flex;
						justify-content: center;
					}
				}

				.history-vocabulary-seminary-card {
					padding: 0;
				}
			}
		}
	}
}
