$baseurl: "";
.last-quizzes-cards-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    justify-content: center;
    gap: 2%;
    align-items: flex-start;

    .last-quiz-card {
        cursor: pointer;
        user-select: none;

        * {
            margin: 0;
        }

        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 10px 20px;
        border-radius: 10px;
        box-shadow: 0px 4px 20px 0px #00000040;
        width: 32%;
        // max-width: 350px;
        margin-bottom: 10px;
        min-width: 300px;
        position: relative;


        .footer,
        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .header {
            .item {
                width: 33.3333%;
                display: flex;
                justify-content: flex-start;
            }

            .item2 {
                justify-content: flex-end;
            }

            .image-container {
                justify-content: center;

                img {
                    width: 1.5rem;
                }
            }

            p {
                font-size: 0.875rem !important;
            }
        }

        .title {
            h3 {
                text-align: center;
                font-size: 1rem !important;
                font-weight: bolder !important;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }

        .footer {
            .item {
                width: 33.3333%;
                display: flex;
                justify-content: center;

                label {
                    font-size: 0.5rem !important;
                    text-align: center;
                    margin-top: 5px;
                }
            }

            .image-container {
                justify-content: flex-start;

                img {
                    width: 1.5rem;
                }
            }

            .seminary-number {
                font-family: initial !important;
            }


        }

        .block-card-quiz {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #000000a1;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 5px;
            padding: 0px 10px;
            left: 0px;
            top: 0px;

            .block-quiz-title {
                font-size: 1rem !important;
                color: white;
                font-weight: 400 !important;
                margin: 0;
            }

            button {
                padding: 7px;
                font-size: 0.75rem !important;
                height: auto;
                width: 150px;
                max-width: 150px;
            }

            .primary-btn {
                background: linear-gradient(90.31deg, #ee1c34 0.26%, #500b28 99.73%);
                color: #fff;
            }

            img {
                filter: invert(96%) sepia(96%) saturate(0%) hue-rotate(57deg) brightness(104%) contrast(107%);
            }
        }
    }
}

@media (max-width: 1267px) {
    .last-quizzes-cards-container .last-quiz-card {
        width: 48%;
    }
}
@media (max-width: 642px) {
    .last-quizzes-cards-container .last-quiz-card {
        width: 100%;
    }
}