$baseurl: "";
.comment-modal{
    .modal-body{
        padding: 10px;
        .text-input{
            resize: none;
            width: 100%;
            padding:10px;
            border-radius: 10px;
            height: 12rem;
        }
    }
    .modal-footer{
        justify-content: space-between;
        button{
            background: linear-gradient(90.31deg, #ee1c34 0.26%, #500b28 99.73%);
            color: #fff;
            padding: 0 20px;
            font-size: 1rem !important;
            .disabled {
                scale:0.6;
            }
        }
    }
}