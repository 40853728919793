$baseurl: "";
.aside-nav {
	position: relative;
	width: fit-content;
	max-width: 300px;
	min-width: 300px;
	background-color: white;
	padding-bottom: 60px;
	box-shadow:
		5px 0 5px -3px rgba(0, 0, 0, 0.44),
		-5px 0 5px -3px rgba(0, 0, 0, 0.44);
}
.backdrop-asidenav {
	display: none;
}

.aside-nav-close {
	min-width: 70px;
}
.aside-nav ul {
	list-style: none;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	top: 80px;
	position: sticky;
	max-width: 300px;
}

.aside-nav {
	ul {
		.sub-menu-item {
			max-height: 39px;
			overflow: hidden;
			transition: 0.2s ease all;
			.sub-menu-action {
				gap: 20px;
				cursor: pointer;
				.arrow-icon {
					margin-left: auto;
					transform: rotate(0deg);
					transition: 0.2s ease all;
				}
			}

			.sub-menu-container {
				display: flex;
				flex-direction: column;
				gap: 10px;
				padding-top: 15px;
				padding-left: 20px;
			}
		}

		.notification-btn {
			&.responsive {
				display: flex;
			}
			&.desk {
				display: none;
			}
		}

		.shuffle-aside-nav {
			display: flex;
			cursor: pointer;
		}

		.shuffle-aside-nav:hover {
			text-decoration: underline;
		}

		.sub-menu-open {
			max-height: 300px;
			transition: 0.25s ease all;

			.sub-menu-action {
				.arrow-icon {
					transform: rotate(180deg);
					transition: 0.25s ease all;
				}
			}
		}
	}

	@media (max-width: 992px) {
		ul {
			.shuffle-aside-nav {
				display: none;
			}

			.notification-btn {
				&.desk {
					display: flex;
				}

				&.responsive {
					display: none;
				}
			}
		}
	}
}

.aside-nav.responsive {
	display: none;

	@media (max-width: 992px) {
		display: block;
	}
}

.aside-nav ul a li div {
	display: grid;
	justify-content: center;
	align-items: center;
	position: relative;
	cursor: pointer;

	.streak-area {
		display: block;
		position: relative;
		cursor: pointer;

		.streak-icon {
			position: absolute;
			width: 1.5rem;
			z-index: 1;
			transform: translate(60%, -80%);
		}
		h2 {
			text-align: center;
			position: relative;
			margin: auto;
			left: 100%;
			bottom: 0%;
			transform: translate(-59%, -80%);
			font-size: 0.7rem;
			z-index: 2;
		}
	}
}
.aside-nav ul li {
	font-size: 20px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
}

.aside-nav ul a.active {
	color: white;
	text-decoration: underline;
	background-color: #c0c0c09a;
}

.aside-nav ul li div img {
	width: 30px;
}

.aside-nav ul a {
	text-decoration: none;
	color: black;
}

.aside-nav ul a:hover {
	text-decoration: underline;
}

.aside-nav ul a.active li span {
	text-decoration: underline;
}

.custom__navbar .container {
	justify-content: flex-end !important;
}

.asidenav-active-btn {
	display: none;
}

.number-icon {
	position: absolute;
	background-color: #ee1c35;
	width: 20px;
	height: 20px;
	border-radius: 100px;
	border: 2px solid #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.6rem;
	color: #fff;
	transform: translate(10px, 1px);
	z-index: 10;
}

.number-icon-navbar {
	position: absolute;
	width: 20px;
	height: 20px;
	border-radius: 100px;
	border: 2px solid #ee1c35;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.75rem;
	color: #ee1c35;
	transform: translate(-7px, -7px);
	background-color: white;
}

.logout-btn-style:hover {
	text-decoration: underline;
}

@media (min-width: 991px) {
	.aside-nav ul li {
		margin-block: 10px;
		margin-inline: 20px;
	}
}

@media (max-width: 991px) {
	.myaccount-screen-main-container {
		.aside-nav {
			display: none;
		}
		.backdrop-asidenav.aside-nav-responsive {
			display: none;
		}
	}
	.backdrop-asidenav.aside-nav-responsive {
		display: block;
		position: absolute;
		background-color: rgba(0, 0, 0, 0.15);
		top: 80px;
		left: 0px;
		width: 100vw;
		height: calc(100vh - 80px);
		z-index: 2;
		max-width: none;
	}
	.aside-nav ul {
		position: unset;
	}
	.aside-nav ul li {
		margin-block: 10px;
		margin-inline: 20px;
	}
	.aside-nav {
		min-width: 0px;
		max-width: 0px;
		padding: 0px;
		z-index: 1000;
		transition: 0.5s ease all;
	}

	.aside-nav-responsive {
		display: block;
		min-width: 0px;
		max-width: 300px;
	}

	.asidenav-active-btn {
		display: block;
		min-width: 0px;
		max-width: 300px;
	}
}
