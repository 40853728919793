$baseurl: "";
@import "/src/theme/mixin.scss";

.modules-slide-main-container {
  img {
    position: absolute;
    bottom: 0px;
    transition: 0.5s ease all;
  }

  &.I {
    img {
      left: 0px;
      height: 100%;
    }

    img.module-1-person-reading {
      width: 55%;
      z-index: 2;
    }

    &:hover {
      img.module-1-person-bg {
        transform: scale(1.1);
      }
      img.module-1-person-reading {
        transform: scale(1.1) translate(-5%, -2%);
      }
    }
  }

  &.II,
  &.III,
  &.V {
    img {
      right: 0px;
    }
  }

  &.II {
    img.module-2-person-laptop {
      z-index: 2;
      width: 58%;
    }

    img.module-2-bg {
      width: 55%;
    }

    &:hover {
      img.module-2-bg {
        transform: scale(1.1) translate(0, -5%);
      }

      img.module-2-person-laptop {
        transform: scale(1.05);
      }
    }
  }

  &.III {
    img.module-3-main-image {
      width: 60%;
      z-index: 2;
    }

    .top-squares,
    .middle-squares,
    .bottom-squares {
      z-index: 1;
    }
    .bottom-squares {
      width: 60%;
      bottom: 0px;
    }

    .middle-squares {
      bottom: 50%;
      width: 40%;
      transform: translate(-50%, 50%);
    }

    .top-squares {
      bottom: 50%;
      width: 25%;
      transform: translate(-40%, -25%);
    }

    &:hover {
      .top-squares,
      .middle-squares,
      .bottom-squares {
        transform: scale(1.1);
      }
      .middle-squares {
        transform: scale(1.25) translate(-20%, 50%);
      }

      .top-squares {
        bottom: 50%;
        transform: scale(1.25) translate(-25%, -70%);
      }

      .module-3-main-image {
        transform: translate(4%, 0);
      }
    }
  }

  &.IV {
    img.circles-row {
      width: 42%;
      left: 28%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }

    .module-4-main-image {
      width: 37%;
      left: 28%;
      z-index: 2;
      transform: translate(-50%, 0);
    }

    img.img1 {
      top: 45%;
    }
    img.img2 {
      top: 40%;
    }
    img.img3 {
      top: 35%;
    }
    img.img4 {
      top: 30%;
    }
    img.img5 {
      top: 25%;
    }
    img.img6 {
      top: 55%;
    }
    img.img7 {
      top: 60%;
    }
    img.img8 {
      top: 65%;
    }
    img.img9 {
      top: 70%;
    }
    img.img10 {
      top: 75%;
    }
    img.img11 {
      top: 50%;
    }
    img.img12 {
      top: 80%;
    }
    img.img13 {
      top: 85%;
    }
    img.img14 {
      top: 20%;
    }

    &:hover {
      .module-4-main-image {
        transform: scale(1.07) translate(-50%, 0%);
      }

      img.img14 {
        transform: translate(-35%, -50%);
      }

      img.img4,
      img.img1 {
        transform: translate(-37%, -50%);
      }

      img.img5 {
        transform: translate(-55%, -50%);
      }

      img.img3 {
        transform: translate(-57%, -50%);
      }

      img.img2 {
        transform: translate(-52%, -50%);
      }

      img.img6 {
        transform: translate(-60%, -50%);
      }

      img.img7 {
        transform: translate(-48%, -50%);
      }

      img.img9 {
        transform: translate(-42%, -50%);
      }

      img.img10 {
        transform: translate(-47%, -50%);
      }

      img.img12 {
        transform: translate(-40%, -50%);
      }

      img.img13 {
        transform: translate(-54%, -50%);
      }
    }
  }

  &.V {
    img.module-5-main-image {
      width: 65%;
      z-index: 2;
    }

    img.top-waves {
      width: 60%;
    }

    img.middle-waves {
      width: 40%;
    }

    img.right-waves {
      width: 20%;
      z-index: 3;
    }

    &:hover {
      img.module-5-main-image {
        transform: scale(1.05);
      }

      img.right-waves {
        transform: translate(5%, -4%) scale(1.08);
      }

      img.middle-waves {
        transform: scale(1.1);
      }

      img.top-waves {
        transform: translate(-10%, 0) scale(1.2);
      }
    }
  }

  @include respond-to(l) {
  }

  @media (max-width: 1100px) {
    img {
      grid-column: 1;
      grid-row: 2;
      position: absolute;
      width: 100% !important;
      max-height: 100%;
      max-width: 320px;
      bottom: 0px;
    }
    &.IV {
      img.circles-row {
        left: 47%;
        width: 80%!important;
        transform: translate(-50%, -50%);
      }

      img.module-4-main-image {
        width: 60%!important;
        left: 50%;
      }
    }

    &.V {
      img.top-waves {
        width: 100%!important;
      }
      
      img.middle-waves {
        width: 70%!important;
      }

      img.right-waves {
        width: 30%!important;
      }

    }
  }
}
