@mixin respond-to($breakpoint) {
  @if $breakpoint == xs {
    @media (max-width: 500px) {
      @content;
    }
  }

  @if $breakpoint == sm {
    @media (max-width: 750px) {
      @content;
    }
  }

  @if $breakpoint == md {
    @media (max-width: 992px) {
      @content;
    }
  }
  @if ($breakpoint == l) {
    @media (max-width: 1366px) {
      @content;
    }
  }
  @if $breakpoint == xl {
    @media (min-width: 1700px) {
      @content;
    }
  }
}
