$baseurl: "";
.streak-modal-main-container {
    .modal-body {
        * {
            padding: 0;
        }

        .rdp {
            width: 100%;
            border: none;
            margin: 0;

            .rdp-months {
                width: 100%;
                height: 100%;

                .rdp-month {
                    width: 100%;
                    height: 100%;

                    .rdp-table {
                        width: 100%;
                        max-width: none;
                        height: 100%;

                        .rdp-day_selected {
                            background-color: #ffffff;
                            background-image: url("./img/streak-flame-icon.svg") !important;
                            background-position: 90% 100% !important;
                            background-size: 2.5rem !important;
                            background-repeat: no-repeat !important;
                            color: black !important;
                            font-weight: normal!important;
                        }

                        .rdp-day {
                            margin: auto;
                        }

                        .rdp-row {
                            height: auto;
                        }
                    }
                }
            }

            .react-calendar__tile--hasActive:enabled:hover {
                background: initial;
            }

            .react-calendar__tile:enabled:hover {
                background-color: transparent;
            }

            .react-calendar__tile--hasActive {
                background-color: transparent;
            }

            .react-calendar__tile {
                background-color: transparent;
            }
            .react-calendar__tile:enabled {
                background-color: #fff;
            }
            .react-calendar__tile--hasActive {
                background-color: #ffffff;
                background-image: url("./img/streak-flame-icon.svg") !important;
                background-position: 47.5% -25% !important;
                background-size: 2.75rem !important;
                background-repeat: no-repeat !important;
                color: black !important;
            }

            .react-calendar__year-view__months__month {
                background: none !important;
            }
        }
    }
}
