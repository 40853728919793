$baseurl: "";
@import "/src/theme/mixin.scss";

.modules-slider-main-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 2100px;
  margin-bottom: 100px;

  .navigation-container {
    padding: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;

    .nav-circle {
      width: 22px;
      height: 22px;
      border-radius: 50px;
      background-color: #b1b1b1;
      transition: 0.5s ease width;
      cursor: pointer;

      &.active {
        width: 74px;
        background-color: #cf072c;
      }
    }
  }

  .slides-container {
    display: flex;
    overflow: hidden;
    width: 100%;
    padding: 0 4%;
  }
  
}
