$baseurl: "";
.currency-selector-container {
	width: fit-content;
    height: 50px;
	margin: auto 5px;
	.btn,
	.btn:focus,
	.btn:active,
	.btn-primary:not(:disabled):not(.disabled):active,
    .btn-primary:hover,
    .btn-primary:focus,
    .btn-primary:focus-visible,
    .show>.btn-primary.dropdown-toggle { // Necessary to override bootstrap styles
        display: flex;
		background-color: transparent;
		color: gray;
		border-color: transparent;
		box-shadow: none;
        padding: 0;
        align-items: center;
        justify-content: space-between;
        outline: none;
        box-shadow: none !important;
	}
    .dropdown-toggle::after{
        // Customize toggle arrow
    }
    .current-currency-container{
        display: flex;
        flex-direction: column;
        width: fit-content;
        font-size: 13px;
        font-weight: 500;
        align-items: center;
        color: black;
        img{
            width:35px
        }
    }
    .dropdown-menu.show{
        text-align: center;
        width: fit-content;
    }
    .dropdown > .dropdown-menu.show > .currency-option{
        padding: 0px 10px;
        display: flex;
        width: 100%;
        align-items: center;
        margin: 5px 0;
        img{
            width: 30px;
            margin-left: auto;
            margin-right: 10px;
        }
        label{
            margin: 0 5px 0 10px;
            white-space: nowrap;
            cursor: pointer;
        }
        input{
            height: 25px;
            width: 25px;
            accent-color: black;
            cursor: pointer;
        }
    }
}

.flex-center-center {
	display: flex;
	align-items: center;
	justify-content: center;
}
