$baseurl: "";
.default-quizzes-main-container {
	display: flex;
	flex-direction: column;
	gap: 40px;
	width: 100%;

	.header-container {
		.top {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.default-quizzes-progress-counter {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				h3 {
					text-align: center;
					margin: 0;
				}
			}
		}
	}
	.default-quizzes-cards-container {
		display: flex;
		flex-direction: column;
		max-width: 920px;
		margin: auto;
		gap: 40px;
		width: 100%;
		* {
			margin: 0;
		}

		.default-quizzes-filter-btns {
            border: 1px solid #3757a6;
            border-radius: 10px;
            display: flex;
            overflow: hidden;
			width: fit-content;
            div {
                max-width: 250px;
				width: auto;
                min-width: 120px;
                padding: 10px;
                text-align: center;
				display: flex;
				justify-content: center;
				align-items: center;
                cursor: pointer;
                h3 {
                    font-size: 1rem!important;
                    color: #3757a6;
                    margin: 0;
                }
            }

            .btn-1 {
                border-right: 1px solid #3757a6;
            }
            .btn-3{
                border-left: 1px solid #3757a6;
            }

            .active {
                background-color: #3757a6;
                h3 {
                    color: white;
                }
            }
        }
		.cards-container {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			gap: 20px;
			.card-quiz {
				background: #ffffff;
				box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
				border-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				width: calc(50% - 10px);
				min-width: 300px;
				padding: 20px 10px;
				gap: 20px;
				position: relative;
				cursor: pointer;

				.flag {
					width: 48px;
					height: 48px;
					min-width: 48px;
					.ant-progress-circle{
						position:absolute;
						z-index:1;
						width: 57px;
						left: 3px;
						top: 12px;
					}
					img {
						width: 100%;
						z-index:0;
					}
					.ant-progress-text{
						position: absolute;
						top: -11px;
						left: 22px;
					}
				}

				.info {
					display: flex;
					flex-direction: column;
					gap: 10px;

					h2 {
						font-size: 1.125rem !important;
						font-family: "Lato" !important;
						font-weight: 400 !important;
					}

					h3 {
						font-size: 0.875rem !important;
                        padding-bottom: 1rem;
					}
				}

				.progress-hover {
					opacity: 0;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
				}

				.completed {
					display: flex;
					flex-direction: column;
					align-items: center;
					margin-left: auto;
					p {
						font-size: 0.875rem !important;
						color: #02a377;
					}
				}

				.words-progress-bar-container {
					position: absolute;
					width: 100%;
					left: 0;
					bottom: 0;

					.words-progress-bar {
						border-radius: 0px;
					}
				}
				&:hover {
					.progress-hover {
						opacity: 1;
                        transition: 0.2s ease all;
					}
				}
			}
		}
	}
}

@media (max-width: 649px) {
	.default-quizzes-main-container {
		.default-quizzes-cards-container {
			.cards-container {
				.card-quiz {
					width: 100%;
				}
			}
		}
	}
}
