$baseurl: "";
.loader-container {
    .pro-tip-row {
        h3 {
            span {
                .listen-to-word-container.protip {
                    position: relative;
                    border-radius: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #008e00;
                    padding: 5px;
                    border-radius: 100px;
                    width: 2rem;
                    height: 2rem;
                    img {
                        width: 40px;
                    }

                    .arrow-image {
                        width: 1.7rem;
                        transform: rotate(320deg);
                    }

                    .speaker-image {
                        position: absolute;
                        width: 1rem;
                    }
                }
            }

            span.with-image {
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }
    }
}
