$baseurl: "";
@import 'theme/colors', 'theme/variables';

.header__contact {
  padding: $spacing-2x 0 2px 0;
  background: $color-primary;
  color: $color-white !important;

  h1 {
    color: $color-white !important;
  }
  p {
    margin-top: $spacing;
  }
}
.socials {
  margin-top: $spacing-4x * 1.5;
  .row {
    margin-bottom: $spacing-2x;
    margin-top: 120px;
    .social_icons {
      padding-left: 100px;
      a {
        color: $color-black;
        justify-content: center;
        display: inline-block;
        margin-bottom: $spacing-2x;
        span {
          margin-left: $spacing * 1.3;
          font-size: $font-size-h3;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .image-col {
    display: none;
  }
}

@media screen and (min-width: 471px) and (max-width: 632px) {
  .socials .row {
    .social_icons {
      padding-left: $spacing;
      span {
        font-size: 28px !important;
      }
    }
  }
}

@media screen and (min-width: 300px) and (max-width: 471px) {
  .socials .row {
    .social_icons {
      padding-left: $spacing !important;
      span {
        font-size: 20px !important;
      }
    }
  }
}
