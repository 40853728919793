$baseurl: "";
.invoice-sent-modal-dialog{
    .invoice-sent-modal-content{
        .invoice-sent-body{
            overflow-wrap:anywhere;
        }
        .is-modal-footer{
            .close-popup-btn{
                padding: 0;
                width: 130px;
                border: 1px solid #CA5050;
                background: transparent;
                color: #CA5050;
            }
        }
    }
}