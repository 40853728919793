$baseurl: "";
@import "theme/colors", "theme/variables";

.invoice-container-component {
	padding: 30px !important;
	padding-top: $spacing-2x !important;
	min-height: 84vh;
	max-height: fit-content;
    max-width: 1200px;
    margin: auto;
}

.invoice-form-main-container {
	width: 95%;
	margin: auto;
	margin-top: 15px;
	height: min-content;
	margin-bottom: 270px;
	.invoice-form-grid {
		width: 100%;
		display: grid;
		gap: 15px;
		grid-template-columns: repeat(3, 1fr);
		margin-bottom: 20px;
		.invoice-form-group {
			display: flex;
			flex-direction: column;
			width: 300px;
			margin: auto;
			label {
				color: #707070;
				font-weight: 300;
				font-size: 16px;
				margin-bottom: 0;
				.note{
					font-size:0.8rem;
					color:black;
					font-weight:600;
				}
			}
			input,
			select {
				border: 1px solid rgba(0, 0, 0, 0.3);
				border-radius: 10px;
				height: 36px;
				padding: 5px;
				width: 100%;
			}
			.invoice-form-subgroup {
				flex-direction: column;
				display: flex;
				width: 49%;
			}
		}
		.invoice-form-group-2 {
			flex-direction: row;
			justify-content: space-between;
		}
		.receipts {
			grid-column: 1/4;
			p {
				font-size: unset !important;
				margin-bottom: unset !important;
			}
			.fiscal-receipt-btn-div {
				display: flex;
				align-items: center;
				gap: 15px;
				span {
					color: #929292;
					font-size: 0.875rem;
				}
			}
		}
		.receipt-btn {
			height: 44px;
			border: 1px solid #ca5050;
			border-radius: 10px;
			text-align: center;
			color: #bc4e4e;
			display: flex;
			width: fit-content;
			padding: 0px 20px;
			align-items: center;
			&:hover {
				cursor: pointer;
			}
		}
	}
    .goback-generate-btns-container{
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: center;
        
        button{
            width:210px;
            padding:0;
            font-size: 1.2rem !important;
            height:50px;
        }
        .go-back-btn {
            border: 1px solid #CA5050;
            background: transparent;
            color: #BC4E4E;
        }
        .generate-invoice-btn {
            background: linear-gradient(90.31deg, #EE1C34 0.26%, #500B28 99.73%);
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
            color:white;
        }
		.disabled-btn{
			background:"grey";
		}
    }
}
.invoice-sent-modal-dialog{
    .invoice-sent-modal-content{
        .is-modal-footer{
            .close-popup-btn{
                padding: 0;
                width: 130px;
                border: 1px solid #CA5050;
                background: transparent;
                color: #CA5050;
            }
        }
    }
}

@media (max-width: 500px) {
	.goback-generate-btns-container{
        flex-direction: column !important;
        align-items: center;
    }
}

@media (max-width: 990px) {
	.invoice-form-grid {
		grid-template-columns: repeat(2, 1fr) !important;
		.receipts {
			grid-column: 1/3 !important;
		}
	}
}

@media (max-width: 690px) {
	.invoice-form-grid {
		grid-template-columns: 1fr !important;
		.receipts {
			grid-column: 1 !important;
		}
	}
}

@media (max-width: 380px) {
	.invoice-form-grid {
		grid-template-columns: 1fr !important;
		.receipts {
			grid-column: 1 !important;
		}
	}
}

.btn-invoice-style {
	background: linear-gradient(to right, #ee1c34, #500b28) !important;
	color: white !important;
	font-weight: 500 !important;
}

.btn-select-picture {
	border: 1px solid #bc4e4e;
	padding: 10px;
	color: #bc4e4e;
	border-radius: 10px;
	cursor: pointer;
}

.invoice-image {
	position: absolute;
	bottom: -50px;
	left: 0;
}

@media (max-width: 1350px) {
	.invoice-image {
		width: 350px;
	}
}

@media (max-width: 1049px) {
	.invoice-image {
		display: none;
	}
}

@media (max-width: 660px) {
	.invoice-top-text-container {
		padding-left: 20px;
		padding-right: 20px;
	}

	.invoice-top-text-container div {
		text-align: justify;
	}

	.invoice-container-component {
		padding-left: 30px !important;
		padding-right: 30px !important;
	}

	.invoice-form-main-container {
		width: 100%;
	}
}
