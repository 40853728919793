$baseurl: "";
.vocabulary-detail-seminary-card-container {
    max-height: 0px;
    overflow: hidden;
    transition: 0.5s ease all;

    .content-container {
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        padding: 20px;

        .header, .number-of-tests-text-container {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h3 {
                margin: 0;
                font-size: 1rem !important;
            }

            p {
                margin: 0;
                font-size: 1rem !important;
                color: #ca5050;
            }
        }

        .number-of-tests-text-container {
            display: none;
        }

        .languages-main-container {
            display: flex;
            gap: 60px;
            padding-top: 10px;

            .languages-container {
                display: flex;
                flex-direction: column;
                gap: 10px;
                flex-wrap: wrap;
                height: 150px;
                flex: 1;
                align-items: flex-start;
                justify-content: flex-start;

                .lang-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;
                    cursor: pointer;

                    img {
                        width: 32px;
                        height: 32px;
                    }

                    .lang-info {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;

                        h3 {
                            font-size: 1rem !important;
                            font-weight: bolder;
                            margin: 0;
                            font-family: "Poppins" !important;
                        }

                        p {
                            font-size: 0.75rem !important;
                            margin: 0;
                        }

                        .complete {
                            color: #ca5050;
                        }
                    }
                }
            }

            .see-btn-container {
                display: flex;
                align-items: flex-end;
                margin-left: auto;
                button {
                    margin: 0;
                    border: 1px solid #ca5050;
                    border-radius: 10px;
                    color: #ca5050;
                    font-size: 0.75rem !important;
                    background-color: transparent;
                    height: fit-content;
                    width: fit-content;
                    padding: 7px 15px;
                }
            }
        }
    }
}

.open-vocabulary-detail {
    max-height: 500px;
    transition: 0.5 ease all;
}

@media (max-width: 991px) {
    .vocabulary-detail-seminary-card-container {
        .content-container {

            .header {
                .test-progress-text{
                    display: none;
                }
            }

            .languages-main-container {
                flex-direction: column;
                justify-content: center;
                gap: 20px;
                .languages-container {
                    flex-direction: row;
                    gap: 10px 40px;
                    height: 200px;
                    align-items: center;
                    width: 80%;
                    min-width: 280px;
                    margin: auto;

                    .lang-container {
                        width: 120px;
                        justify-content: flex-start;

                        .lang-info {
                            p {
                                span {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                .see-btn-container {
                    margin: 0;
                    justify-content: center;
                }

                .number-of-tests-text-container  {
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
}
