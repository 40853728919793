$baseurl: "";
.bookmark-word-note-modal {

    .modal-dialog {

        .modal-content {

            .modal-body {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                flex-direction: column;

                .input-container{
                    border: 1px solid #00000080;
                    border-radius: 10px;

                    input {
                        border-radius: 10px;
                        border: none;
                        padding: 5px 10px;
                        width: 100%;
                    }
                }

                p {
                    width: 48%;
                    text-align: center;
                }
            }

            .modal-footer {
                justify-content: space-between;
            }
        }
    }
}