$baseurl: "";
@import "theme/colors", "theme/variables";

.payment__card {
  font-family: $font-family-primary;
  margin-bottom: $spacing;
  .card {
    height: 31.5rem;
  }
  .card-title {
    font-family: $font-family-secondary;
    color: $color-secondary;
    font-size: 24px;
    text-align: center;
  }

  .highlight-text-info-card {
    font-family: "Lato", sans-serif !important;
    font-weight: bolder !important;
    text-align: center;
  }

  .price {
    font-weight: $font-weight-bold !important;
    font-size: 36px !important;
  }
  .extra {
    color: $color-secondary;
  }
  p {
    font-weight: $font-weight-medium !important;
  }
  a {
    color: inherit;
  }

  .hover {
    background: $color-primary !important;
    color: white;

    .card-title,
    .extra {
      color: white;
    }
  }

  ul {
    padding-left: 22px;
    li {
      padding-bottom: 14px;
    }
  }

  .disable {
    background-color: #00000080;

    * {
      color: #00000080!important;
    }
  }
}

.color-limit-time {
  a {
    font-weight: bolder;
    background: linear-gradient(to right, #ee1c34, #500b28 69.67%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.3rem;
  }
}

.active-text-limit-time {
  background-color: white;
  border: 1px solid white;
  border-radius: 10px;
}

@media screen and (max-width: 992px) {
  .payment__card .card {
    height: auto;
  }
}
