$baseurl: "";
.calfications-mobile-about-app-container {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin-bottom: 100px;

    a {
        color: #000;
    }

    a:hover {
        text-decoration: none;
    }

    .title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
            font-family: "SF-Compact-Rounded-Regular" !important;
            font-size: 1.25rem !important;
        }
    }
    .content-container {
        display: flex;
        gap: 20px;

        .calification-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .calification-number {
                margin: 0;
                font-size: 3rem;
            }

            .stars-container {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .ios-stars {
                    filter: invert(10%) sepia(45%) saturate(5673%) hue-rotate(265deg) brightness(127%) contrast(126%);
                }
            }

            .quantity-califications {
                font-size: 10px !important;
                color: #666666;
            }
        }

        .califications-bars-container {
            width: 100%;

            .calification-bar-container {
                width: 100%;
                display: flex;
                align-items: center;
                gap: 10px;

                .number {
                    margin: 0;
                    width: 10px;
                    text-align: center;
                }

                .calification-bar {
                    width: 100%;
                    background: #e9eaee;
                    border-radius: 10px;
                    height: 10px;

                    .calification-bar-progress {
                        height: 100%;
                        border-radius: 10px;
                        background: #00875f;
                    }

                    .blue-color {
                        background-color: #3478f6;
                    }
                }
            }
        }
    }
}

.obtain-mobile-btn {
    background-color: #3478f6;
    height: inherit;
    font-size: 14px !important;
    color: white;
    line-height: 10px;
    font-family: "SFProText-Regular-1" !important;
    padding: 0px;
    width: 100px;
}
