$baseurl: "";
.vjs-button>.vjs-icon-placeholder:before {
    font-size: 20px;
}

.video-js .vjs-control {
    width: 40px;
    padding: 0;
}

.video-js .vjs-big-play-button {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.video-js .vjs-quality-selector {
    button {
        padding: 0;
        span {
            font-size: 0.75rem;
        }
    }
}

.recordings-main-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title-container {
        h2 {
            font-size: 3rem !important;
            margin: 0;
            color: #ca5050;

            span {
                font-family: auto;
            }
        }
    }

    .next-seminary-container {
        display: flex;
        justify-content: space-between;
    }

    .parts-container {
        display: flex;
        gap: 20px;

        .part {
            border-radius: 10px;
            border: 1px solid #ca5050;
            text-align: center;
            background-color: white;
            padding: 5px;
            width: 75px;
            cursor: pointer;
            
            h3 {
                color: #ca5050;
                margin: 0;
                font-size: 1rem!important;
            }
        }

        .active {
            background-color: #ca5050;

            h3 {
                color: #ffffff;
            }
        }
    }

    .content-container {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;

        .video-card,
        .about-class-card {
            background: #ffffff;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            padding: 10px;
        }

        .video-card {
            flex: 1;
            min-width: 642px;
        }

        .about-class-card {
            width: 29%;
            padding: 20px 10px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            min-width: 300px;

            .title {
                h2 {
                    margin: 0;
                    font-size: 1.5rem !important;
                    color: #ca5050;
                    font-family: Lato !important;
                    font-weight: 400 !important;

                }

                img {
                    display: none;
                }
            }

            .info-container {
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding: 10px 0px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.25);

                .title {
                    h3 {
                        margin: 0;
                        font-size: 0.875rem !important;
                        font-weight: bolder !important;
                    }
                }

                .description {
                    display: flex;
                    justify-content: space-between;

                    p {
                        font-size: 0.875rem !important;
                        margin: 0;
                    }
                }

                .gen-name {
                    flex-direction: column;
                }
            }

            .notes-container {
                border-bottom: 1px solid rgba(0, 0, 0, 0.25);
                padding-bottom: 10px;
            }

            .materials-container,
            .notes-container {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .link-container {
                    padding: 5px 20px;
                    border: 1px solid #ca5050;
                    border-radius: 5px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: #ca5050;
                    white-space: nowrap;
                    width: 100%;
                    max-width: 300px;
                    min-width: 250px;

                    a {
                        margin: 0;
                        font-size: 0.875rem !important;

                        color: #ca5050;

                        img {
                            filter: invert(37%) sepia(17%) saturate(1992%) hue-rotate(312deg) brightness(109%) contrast(90%);
                            width: 0.875rem;
                        }
                    }

                    a:hover {
                        text-decoration: none;
                    }
                }

                .title {
                    h3 {
                        margin: 0;
                        font-size: 0.875rem !important;
                        font-weight: bolder !important;
                    }
                }

                p {
                    font-size: 0.875rem !important;
                    margin: 0;
                    word-break: break-word;
                }
            }

            .materials-container {
                flex-direction: row;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;

                .title {
                    width: 100%;
                    align-self: flex-start;
                }
            }
        }
    }

    .annotations-card {
        padding: 10px;
        padding-top: 20px;
        background: #ffffff;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        min-height: 200px;

        .consults-text {
            p {
                margin: 0;
                font-size: 1rem!important;
            }
        }

        .title {
            h2 {
                margin: 0;
                font-size: 1.5rem !important;
                color: #ca5050;
                font-family: Lato !important;
                font-weight: 400 !important;
            }

            h3 {
                font-size: 1rem !important;
                margin: 0;
            }
        }

        .input-row,
        .annotation-row {
            display: flex;
            justify-content: space-between;
            gap: 10px;

            .profile-pic {
                background-color: red;
                border-radius: 100px;
                width: 42px;
                height: 42px;
                position: relative;

                p {
                    color: white;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 0.875rem !important;
                }
            }

            .input-container,
            .annotation-text-container {
                background: #ffffff;
                box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
                border-radius: 10px;
                flex: 1;
                display: flex;

                .add-annotation-btn {
                    padding: 0;
                    height: auto;
                    font-size: 0.875rem !important;
                    color: white;
                    border-radius: 0px 10px 10px 0px !important;
                    background: linear-gradient(to right, #ee1c34, #500b28) !important;
                    padding: 10px;
                }

                input {
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                    border: none;
                    padding: 20px 10px;
                    font-size: 0.875rem !important;
                }

                p {
                    font-size: 0.875rem !important;
                    margin: 0;
                }
            }
        }

        .annotation-row {
            .annotation-text-container {
                padding: 10px;
                display: flex;
                justify-content: space-between;
                gap: 10px;

                .annotation-text {
                    flex: 1;
                }

                .annotation-time-text {
                    color: #ca5050;
                    font-weight: bolder !important;
                }
            }
        }
    }
}

.recordings-views-container {
    display: flex;
    gap: 10px;
    align-items: center;
    border-radius: 10px;
    background-color: red;
    justify-content: center;
    width: fit-content;
    padding: 5px 10px;
    cursor: pointer;

    img {
        width: 1rem;
        height: 1rem;
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(194deg) brightness(103%) contrast(102%);
    }

    p {
        margin: 0;
        font-size: 1rem!important;
        color: #ffffff;
    }
}

@media(max-width: 1291px) {
    .recordings-main-container {
        .content-container {
            .video-card {
                min-width: 0px;
            }

            .about-class-card {
                width: 100%;
                max-height: 68.8px;
                overflow: hidden;
                transition: .5s ease all;

                .title {
                    display: flex;
                    justify-content: space-between;

                    img {
                        display: block;
                        transform: rotate(90deg);
                        width: 1.5rem;
                        transition: .5s ease all;
                    }
                }
            }

            .about-class-card-open {
                max-height: 600px;
                transition: .5s ease all;

                .title {

                    img {
                        transform: rotate(-90deg);
                        transition: .5s ease all;
                    }
                }
            }
        }
    }
}

// @media (max-width: 991px) {
//     .recordings-main-container {
//         .content-container {
            
//         }
//     }
// }

@media (max-width: 671px) {
    .recordings-main-container {
        .next-seminary-container {
            gap: 20px;

            .action-btn {
                min-width: 150px;
            }
        }
    }
}