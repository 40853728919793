.close-btn-history-card {
    padding: 0;
    height: auto;
    height: 30px;
    width: 30px;
    border-radius: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    background-color: transparent;
}


.payment-history-card-container-box{
    display:flex ;
     width: 100%;
    justify-content: space-between;
    align-items: center;
}



@media (max-width: 611px){
    .payment-history-card-container-box{
        display:flex ;
         width: 100%;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }

}