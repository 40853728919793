$baseurl: "";
.edit-word-seminaries-modal {
  .modal-dialog {
    .modal-content {
      min-height: 300px;

      .modal-body {
        form {
          display: flex;
          flex-direction: column;
          gap: 20px;

          .c-row {
            .header {
              display: flex;
              label {
                flex: 1;
                font-weight: bolder !important;
                font-size: 1.125rem;
              }

              h4 {
                font-size: 1.125rem;
                text-decoration: underline;
              }
            }
            select {
              width: 100%;
              display: flex;
              border: 2px solid #c3c3c3;
              border-radius: 7px;
              padding: 10px;
              font-size: 1.25rem;
              overflow-y: auto;

              &::-webkit-scrollbar {
                display: block;
                width: 10px; /* Adjust width for vertical scrollbar */
              }
  
              /* Style the scrollbar thumb (actual scroll) */
              &::-webkit-scrollbar-thumb {
                background-color: #ca5050;
                border-radius: 10px;
              }
            }

          }

          .actual-selection-container {
            h2 {
              font-size: 1.125rem;
            }

            p {
              font-size: 1rem!important;
            }
          }
        }
      }
    }
  }
}
