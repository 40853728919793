$baseurl: "";
/* ShowReplyModal.scss */

.email-card {
	border-radius: 45px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	transition: 0.3s;

	&-header {
		background: #f0f0f0;
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;

		.grid-container {
			display: grid;
			grid-template-areas:
				"subject subject date"
				"info info date";
			grid-template-columns: 1fr 1fr auto;
			gap: 10px;

			@media (max-width: 600px) {
				grid-template-areas:
					"subject"
					"date"
					"info";
				grid-template-columns: 1fr;
			}

			.subject {
				grid-area: subject;
			}
			.date {
				grid-area: date;
			}
			.info {
				grid-area: info;
			}
		}
	}

	&-body {
		background: #fff;
		border-bottom-left-radius: 15px;
		border-bottom-right-radius: 15px;
		padding: 20px;

		b {
			color: #333;
		}

		.btn-link {
			color: #007bff;
		}

		.message-section,
		.reply-section {
			margin: 10px 0;
		}

		.divider {
			border: 0;
			border-top: 1px solid #dad5d5a5;
			margin: 10px 0;
		}
		.file-container {
			display: flex;
			flex-direction: column;

			.file-item {
				display: flex;
				align-items: center;
				gap: 10px;
				padding: 5px;
				cursor: pointer;

				span {
					font-size: 14px;
					color: #333;
				}
			}
		}
		.file-item {
			.btn-sm {
				padding: 0.25rem 0.5rem; /* Reducir el relleno del botón si es necesario */
				font-size: 0.875rem; /* Reducir el tamaño de la fuente del botón si es necesario */
			}

			.file-link {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 100px; /* Ajusta este valor según tus necesidades */
			}
		}
	}
}
