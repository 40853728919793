$baseurl: "";
.warning-type-selector {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .warning-type-circle {
        border: 5px solid #cacaca;
        border-radius: 100%;
        aspect-ratio: 1/1;
        padding: 5px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
            width: 1.5rem;
        }
    }
}