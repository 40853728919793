$baseurl: "";
@import "@theme/mixin.scss";

@mixin afterStyles {
  font-size: 144px;
  font-family: "inter black";
  position: absolute;
  right: -5px;
  top: 100%;
  transform: translate(0, -70%);
  color: #0000001a;
}

.program-main-info-main-container {
  margin-top: 60px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;

    h2 {
      font-size: 48px !important;
      font-family: "inter" !important;
      font-weight: bolder;
      text-transform: uppercase;
      margin: 0;

      span {
        color: #cf072c;
      }
    }

    .separator {
      height: 17px;
      width: 3px;
      background-color: #cf072c;
    }
  }

  .description {
    font-size: 19px;
    text-align: center;
    max-width: 900px;
    margin: auto;
    position: relative;
    margin-top: 60px;

    &::before {
      content: "¡Todos los idiomas en un mismo curso!";
      position: absolute;
      bottom: calc(100%);
      transform: translate(-50%, -50%);
      font-size: "inter";
      font-weight: 700;
      left: 50%;
      padding: 2px;
      text-transform: uppercase;
      width: 100%;
      background-color: #cf072c;
      color: #ffffff;
      text-align: center;
    }
  }

  .skills {
    margin: auto;
    margin-top: 40px;
    max-width: 850px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    width: 100%;
    flex-wrap: wrap;
  }

  @include respond-to(md) {
    .header {
      flex-direction: column;
      padding: 0px 20px;

      .separator {
        display: none;
      }

      h2 {
        background-color: #eef4f9;
        width: 100%;
        border-radius: 12px;
        text-align: center;
        padding: 35px 0px;
        position: relative;
        overflow: hidden;

        &.one::after {
          content: "1";
          @include afterStyles;
        }
        &.two::after {
          content: "8";
          @include afterStyles;
        }

        &.three::after {
          content: "60";
          @include afterStyles;
        }
      }
    }
  }

  @include respond-to(sm) {
    .skills {
      padding: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "item1 item2"
        "item3 item4"
        "item5 item5";
      align-items: center;
      justify-content: center;
      gap: 20px;

      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 24px;
        width: 100%;
        max-width: 250px;
        margin: auto;
      }

      .item1,
      .item2,
      .item3,
      .item4 {
        aspect-ratio: 1;
      }

      .item5 {
        aspect-ratio: 2/1;
        max-width: 500px;
      }

      .item1 {
        background-color: #f6727f1a;
        grid-area: item1;
      }
      .item2 {
        grid-area: item2;
        background-color: #f8b1951a;
      }
      .item3 {
        background-color: #6b7b8e1a;
        grid-area: item3;
      }
      .item4 {
        background-color: #0000001a;
        grid-area: item4;
      }
      .item5 {
        background-color: #6c567b1a;
        grid-area: item5;
      }
    }
  }

  @include respond-to(xs) {
    .header {
      h2 {
        font-size: 40px !important;
      }
    }

    .description {
      margin-top: 120px;
      padding: 0px 20px;
      font-size: 1rem !important;
    }
  }
}
