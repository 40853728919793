.next-class-card {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.text-youtube{
    white-space: nowrap;
}

.material-file-button {
    background-color: transparent;
    padding: 10px;
    width: auto;
    min-width: 250px;
    font-size: 1.375rem;
    height: auto;
    border: 2px solid #ca5050;
    color: #ca5050;
    /* font-size: 1rem!important; */
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 300px;
    font-size: 0.75rem!important;
}

.material-file-button img {
    width: 1.5rem;
    filter: invert(37%) sepia(17%) saturate(1992%) hue-rotate(312deg) brightness(109%) contrast(90%);
    margin-right: auto;
    margin-right: 10px;
}

.rfs-1{
    font-size: 1rem!important;
}

.total-sesions-text{
    text-decoration: none;
    font-weight: bolder;
	background: linear-gradient(to right, #ee1c34, #500b28 69.67%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
    text-align: center;
}

.total-sesions-text:hover {
    font-weight: bolder;
	background: linear-gradient(to right, #ee1c34, #500b28 69.67%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
    text-decoration: none;
}

.header-material-container {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.header-material-container h2 {
    width: 50%;
}

.header-material-container div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40%;
}

.material-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.block-next-seminary-card {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

.pastMaterialNumbersContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.date-next-material-container {
    display: flex;
    justify-content: space-between;
}

.seminaryNumberMateriales {
    color: #ca5050;
    font-size: 4rem!important;
    margin: 0;
    font-family: auto;
}

.see-recordings-btn {
    padding: 0px;
    background-color: #ffffff;
    border-radius: 10px;
    border: 2px solid #ca5050;
    font-size: 1rem!important;
    color: #ca5050;
    width: 250px;
}

.gen-selector-container .select-container {
    width: fit-content;
    border-radius: 10px;
    border: 1px solid black;
    width: 100%;
    max-width: 800px;
    min-width: 300px;
}

.gen-selector-container .select-container select {
    padding: 10px;
    border: none;
    width: 100%;

}

.gen-selector-container .select-container select option {
    width: 100%;
}

.posision-infobox{
    position:relative;
    left:125px;
    top:13px;
}
.regular-text{
    display: flex;
    gap: 5px;
}
.info-contain{
    display: flex;
    gap: 5px;
    align-items: center;
}

@media (max-width: 584px) {
    .material-container {
        justify-content: center;
    }

    .block-next-seminary-card {
        justify-content: center;
    }

    .block-next-seminary-card h2 {
        text-align: center;
    }

    .date-next-material-container {
        justify-content: center;
    }

    .next-pay-text-date {
        margin: 0;
        width: 100%;
        text-align: center;
        margin-top: 10px;
    }

    .regular-text.next-pay-text-limit {
        margin: 0;
        margin-bottom: 10px;
        font-size: 1.25rem!important;
        color: #00000080;
    }

    .next-class-card.pay-day {
        justify-content: center!important;
    }
    .contain-youtube{
        flex-direction: column;
        align-items: center;
    }
}



@media (max-width: 991px) {
    .header-material-container h2 {
        width: 100%;
        font-size: 1.5rem;
        text-align: center;
    }
    .header-material-container {
        justify-content: center;
    }

    .header-material-container div {
        width: 100%;
    }
}

@media(max-width: 469px) {
    .posision-infobox{
        left: 50px;
    }
}
