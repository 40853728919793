$baseurl: "";
.llave-img {
    width: 20px;
    padding-block: 8px;
    margin-right: 1.5%;
}

.edit-word-in-test-modal {
    .modal-body {
        .container {
            .row {
                .form-label {
                    height: 30px;
                    display: flex;
                    gap: 10px;
                    font-weight: 700;
                    font-size: 16px;
                    @media(max-width: 991px){
                        font-size: 14px;
                    }
                    @media(max-width: 575px){
                        font-size: 16px;
                    }
                    img {
                        width: 1.125rem;
                    }
                    
                    a {
                        margin-left:auto;
                        text-decoration: none;
                        color: #212529;
                    }
                    
                }
                input {
                    padding: 0.25rem;
                }
                .col-sm {
                    .ql-container{
                        max-height: 10rem;
                        overflow:scroll;
                    }
                    .extended-warning-btn{
                        padding: 10px 25px;
                        margin-bottom: 1rem;
                        p{
                            margin: 0;
                            font-weight: 700 !important;
                            font-size: 16px !important;
                        }
                    }
                    .extended-warning-btn-active{
                        padding: 10px 25px;
                        background: linear-gradient(to right, #ee1c34, #500b28);
                        margin-bottom: 1rem;
                        p{
                            margin: 0;
                            font-weight: 700 !important;
                            font-size: 16px !important;
                            color: #ffffff;
                        }
                    }
                    .extended-quill-container{
                        .ql-container{
                            height: auto;
                            max-height: 75vh;
                            min-height: 25rem;
                        }
                    }
                    .warning-note-inputs-container {
                        display: flex;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        padding: 0rem 0.25rem;
                        align-items: center;

                        img {
                            width: 1rem;
                            height: 1rem;
                        }
                        select {
                            width: 100%;
                            padding: 0.25rem;
                            border-radius: 7px 0px 0px 7px;
                            border: none;
                        }

                        input {
                            border-radius: 0px 7px 7px 0px;
                            border: none;
                            border-left: 1px solid #ced4da;
                        }

                        .form-control:focus {
                            box-shadow: none;
                        }
                    }
                    


                    .warning-note-inputs-container.readOnly {
                        border-color: transparent;

                        input:disabled {
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }
}
