$baseurl: "";
.personalized-test-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    height: calc(100vh - 64px); // Ajusta este valor según el header de tu app
    padding: 20px;

    input[type="checkbox"] {
        accent-color: #ca5050;
    }

    .header-container {
        // margin-bottom: 40px;
        width: 100%;
    }

    .personalized-test-card {
        width: 100%;
        background: #ffffff;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
        border-radius: 10px;

        .content {
            padding: 20px;

            .additional-options-row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                margin: 20px 0px;

                .container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 15px;
                    width: fit-content;
                    margin: 0;
                }

                @media (max-width: 992px) {
                    justify-content: center;
                }
            }

            .name-input-and-total-learned {
                margin-bottom: 20px;
            }

            .test-subview-2-categories-cards-container {
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                justify-content: center;
                gap: 2%;

                .category-card {
                    width: 23%;
                    border-radius: 10px;
                    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
                    min-width: 250px;
                    margin-bottom: 20px;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    border: 2px solid #ca5050;
                    height: fit-content;

                    sup {
                        color: black;
                    }

                    .header {
                        padding: 10px 20px;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        gap: 20px;
                        border-bottom: 2px solid #ca5050;

                        * {
                            margin: 0;
                        }

                        .number-of-words-category {
                            position: absolute;
                            right: 10px;
                            top: 5px;

                            color: #007cff;
                        }

                        .title {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            text-align: center;
                            h2 {
                                font-size: 1.5rem;
                                color: #ca5050;
                            }
                        }

                        .category-progress-bar-container {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            gap: 10px;

                            p {
                                margin: 0;
                                font-size: 0.875rem !important;
                                font-weight: 400 !important;
                            }

                            .category-progress-bar {
                                background: #ffffff;
                                box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
                                border-radius: 100px;
                                height: 10px;
                                flex: 1;

                                .category-progress {
                                    background: #007cff;
                                    border-radius: 100px;
                                    height: 100%;
                                    width: 50%;
                                }
                            }
                        }

                        .collapse-category-card-container {
                            button {
                                padding: 0;
                                height: min-content;
                                position: absolute;
                                left: 3%;
                                border: 2px solid lightblue;

                                img {
                                    transform: rotate(-180deg);
                                    transition: 0.5s ease all;
                                }

                                img.closed {
                                    transform: rotate(0deg);
                                    transition: 0.5s ease all;
                                }
                            }
                        }
                    }

                    .category-card-subcat-selector-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0px 20px;
                        gap: 5px;

                        label {
                            margin: 0;
                            font-size: 0.875rem;
                        }

                        select {
                            padding: 2px 10px;
                            border-radius: 10px;
                            max-width: 170px;
                        }
                    }

                    .difficult-selector-filter-container {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 10px;

                        .difficult-ball-filter {
                            width: 1rem;
                            height: 1rem;
                            border-radius: 1rem;
                            cursor: pointer;
                        }

                        .very-easy {
                            border: 2px solid #00ff00;
                        }

                        .very-easy.selected {
                            background-color: #00ff00;
                        }

                        .easy {
                            border: 2px solid #e3ff00;
                        }
                        .easy.selected {
                            background-color: #e3ff00;
                        }

                        .medium-low {
                            border: 2px solid #c6ce00;
                        }

                        .medium-low.selected {
                            background-color: #c6ce00;
                        }

                        .medium-high {
                            border: 2px solid #ffc000;
                        }

                        .medium-high.selected {
                            background-color: #ffc000;
                        }

                        .hard {
                            border: 2px solid #ff4000;
                        }

                        .hard.selected {
                            background-color: #ff4000;
                        }

                        .expert {
                            border: 2px solid #8b0000;
                        }

                        .expert.selected {
                            background-color: #8b0000;
                        }
                    }

                    .category-words-container {
                        * {
                            margin: 0;
                            font-family: sans-serif;
                        }

                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        padding: 10px 20px;
                        -moz-overflow: -moz-scrollbars-vertical;
                        overflow-y: scroll;
                        scroll-behavior: smooth;
                        height: 300px;

                        img {
                            width: 0.875rem;
                            height: 0.875rem;
                            position: absolute;
                            bottom: 10px;
                            right: 20px;
                            cursor: pointer;
                            animation-name: movingArrows;
                            animation-duration: 1.5s;
                            animation-iteration-count: infinite;
                        }

                        .scroll-up-icon {
                            top: 10px;
                            right: 20px;
                            transform: rotate(180deg);
                            animation-name: movingArrowsUp;
                            animation-duration: 1.5s;
                            animation-iteration-count: infinite;
                        }

                        .no-animation {
                            animation: none;
                        }

                        .category-selector-container {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;

                            label,
                            input {
                                cursor: pointer;
                            }

                            label {
                                padding-left: 7px;
                                flex: 1;

                                span {
                                    white-space: nowrap;
                                }
                            }

                            .difficult-color-circle {
                                width: 0.5rem;
                                height: 0.5rem;
                                border-radius: 0.5rem;
                            }
                        }
                    }

                    .category-words-container::-webkit-scrollbar {
                        display: block !important;
                        width: 20px;
                        padding-right: 5px;
                    }

                    .category-words-container::-webkit-scrollbar-thumb {
                        background-color: #ca5050;
                        border-radius: 10px;
                        border: 5px solid #ffffff;
                    }

                    .category-words-container::-webkit-scrollbar:vertical {
                        width: 20px;
                    }
                }

                .category-card.closed-card {
                    max-height: fit-content;
                    overflow: hidden;
                    .category-card-subcat-selector-container {
                        display: none;
                    }

                    .category-words-container {
                        height: fit-content;
                        max-height: 300px;
                    }
                }
            }

            .general-personalized-test-progress-bar-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 10px;
                max-width: 300px;
                margin: auto;

                p {
                    margin: 0;
                    font-size: 0.875rem !important;
                    font-weight: 400 !important;
                }

                .general-personalized-test-progress-bar {
                    background: #ffffff;
                    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 100px;
                    height: 10px;
                    flex: 1;

                    .general-personalized-test-progress {
                        background: #007cff;
                        border-radius: 100px;
                        height: 100%;
                        width: 50%;
                    }
                }
            }

            .name-input-and-total-learned {
                flex-wrap: wrap;
            }

            .test-row {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px 60px;

                h2 {
                    font-size: 1.125rem !important;
                    font-weight: 600 !important;
                }

                h3 {
                    font-size: 0.875rem !important;
                    font-weight: 400 !important;
                }

                .total-learned-text-container {
                    display: flex;
                    flex: 1;
                    justify-content: end;
                    gap: 5px;

                    h3 {
                        font-size: 2rem !important;
                    }
                }

                .subView-container {
                    display: flex;
                    width: 450px;
                    margin-left: auto;

                    p {
                        font-size: 0.875rem;
                        margin: 0;
                        text-align: center;
                        color: #ca5050;
                    }

                    div {
                        flex: 1;
                        padding: 5px 10px;
                        border: 1px solid #ca5050;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .subview-1 {
                        border-right: 1px solid #ca5050;
                        border-radius: 10px 0px 0px 10px;
                    }

                    .subview-2 {
                        border-right: 1px solid #ca5050;
                        border-radius: 0px 10px 10px 0px;
                    }

                    .active {
                        background-color: #ca5050;

                        p {
                            color: white;
                        }
                    }
                }

                .name-input-container {
                    border-radius: 10px;
                    overflow: hidden;
                    background: #ffffff;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 80%;
                    min-width: 400px;

                    input {
                        border: none;
                        padding: 5px 10px;
                        font-size: 2rem;
                        width: 100%;
                    }
                }

                .lang {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;

                    img {
                        width: 48px;
                        height: 48px;
                    }

                    div {
                        display: flex;
                        align-items: center;
                        gap: 5px;

                        label {
                            margin: 0;
                        }
                    }
                }

                .category {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;

                    label {
                        margin: 0;
                    }

                    .modal-quantity-words-label-categories {
                        cursor: pointer;
                        color: #00000080;
                    }

                    .modal-quantity-words-label-categories:hover {
                        text-decoration: underline;
                    }
                }

                .sub-category-container {
                    text-align: center;

                    span {
                        font-size: 0.875rem !important;
                        color: #00000067;
                    }
                }

                .selects {
                    display: flex;
                    flex-direction: column;

                    label {
                        font-family: "Poppins" !important;
                        font-weight: 600 !important;
                        font-size: 1rem !important;
                        margin: 0;
                    }
                     select {
                        &:not(.selectPeriod) {
                        width: 250px;
                        }
                        padding: 10px;
                        border-radius: 10px;
                        border: 1px solid black;
                        color: black;
                    }

                    .checkboxes-container {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;

                        div {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 5px;
                        }
                    }
                }
                .progress-container {
                    cursor: pointer;
                    border-radius: 100px;
                    width: 70px;
                    position: relative;

                    img {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        width: 60px;
                        height: 60px;
                        transform: translate(-50%, -50%);
                    }
                }
            }

            .option {
                padding: 0px 50px;
                margin: 20px 0px;
                flex-wrap: wrap;
            }

            .characteristics {
                padding-left: 20px;

                ul {
                    margin: 0;
                }
            }

            .number-of-words {
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 0px;

                .add-remove-words {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 20px;

                    img {
                        cursor: pointer;
                    }

                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    /* Firefox */
                    input[type="number"] {
                        -moz-appearance: textfield;
                    }

                    input {
                        margin: 0;
                        font-size: 4.375rem !important;
                        width: 100px;
                        height: 100px;
                        border-radius: 10px;
                        text-align: center;
                        color: #000000;
                        font-weight: bolder !important;
                        border: 1px solid rgba(0, 0, 0, 0.345);
                    }

                    .disabled {
                        opacity: 0.5;
                    }
                }

                h2 {
                    color: #636262;
                    font-weight: 300 !important;
                }
            }
        }
    }

    .create-test-card {
        .content {
            .name-input-container {
                border-radius: 10px;
                overflow: hidden;
                background: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;

                input {
                    border: none;
                    padding: 5px 10px;
                    font-size: 2rem;
                    width: 80%;
                    min-width: 300px;
                }
            }

            .option {
                justify-content: center;

                label {
                    font-weight: bolder;
                }
            }
        }
    }

    .button-test-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px;
        // margin-top: 40px;

        button {
            background: linear-gradient(90.31deg, #ee1c34 0.26%, #500b28 99.73%);
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            color: #ffffff;
            padding: 10px 20px;
            font-size: 1rem !important;
            min-width: 150px;
        }

        .change-test {
            border: 1px solid #ca5050;
            color: #ca5050;
            background: none;
        }
    }
}

@media (max-width: 991px) {
    .personalized-test-main-container {
        .personalized-test-card {
            width: 100vw;

            .content {
                .test-row {
                    gap: 15px;
                    justify-content: center;

                    .lang {
                        width: 100px;
                    }

                    .selects {
                        width: 100%;

                        select {
                            width: 100%;
                        }

                        label {
                            font-size: 0.875rem !important;
                        }
                    }
                }

                .option {
                    padding: 0px;
                }

                .name-input-and-total-learned {
                    .total-learned-text-container {
                        h3 {
                            font-size: 1.25rem !important;
                        }
                    }
                }

                .test-subview-2-categories-cards-container {
                    justify-content: center;
                }
            }
        }
    }
}

@media (max-width: 580px) {
    .personalized-test-main-container .personalized-test-card .content .test-row .name-input-container {
        width: 100%;
        min-width: 300px;

        input {
            font-size: 1.5rem;
        }
    }
}

@keyframes movingArrows {
    0% {
        bottom: 10px;
    }
    25% {
        bottom: 20px;
    }
    50% {
        bottom: 10px;
    }
    75% {
        bottom: 20px;
    }
    100% {
        bottom: 10px;
    }
}
@keyframes movingArrowsUp {
    0% {
        top: 10px;
    }
    25% {
        top: 20px;
    }
    50% {
        top: 10px;
    }
    75% {
        top: 20px;
    }
    100% {
        top: 10px;
    }
}
