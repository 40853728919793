$baseurl: "";
@import "@theme/mixin.scss";

.video-home-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 0px 20px;
  margin-bottom: 100px;

  h3 {
    font-size: 1.25rem;
    text-transform: uppercase;
    text-align: center;
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    @mixin arrows {
      background-image: url("../../../../assets/icons/down-arrow.svg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
    }

    &::after {
      content: "";
      @include arrows();
    }
    &::before {
      content: "";
      @include arrows();
    }
  }

  div {
    max-width: 1000px;
    border-radius: 24px;
    overflow: hidden;
  }

  @include respond-to(sm) {
    h3 {
      display: none;
    }
  }
}
