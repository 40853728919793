$baseurl: "";
.see-seminary-info-modal {

    .modal-dialog {

        .modal-content {

            .modal-body {
                padding: 1rem 0px;
                .container {
                    padding: 15px !important;
                }
                .row {
                    padding: 10px 0px;
                    p {
                        font-family: "Open Sans", sans-serif !important;
                    }

                    p {
						color: #000000;
						gap: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-wrap: wrap;
						span {
							word-break: keep-all;
                            white-space: wrap;
						}
					}

					.gray {
						color: #00000080 !important;
					}

					.black {
						color: #000000 !important;
					}

					.dominated {
						color: #ca5050 !important;
					}
                }
            }

            .modal-footer {
                justify-content: center;
            }
        }
    }
}