$baseurl: "";
@import 'theme/colors', 'theme/variables';

.section__counter {
  .container {
    border-radius: $spacing/2;
    span {
      font-size: $font-size-h3 - 5px !important;
    }
  }

  .red {
    background: $color-primary;
    color: $color-white;
  }

  .hide {
    display: none;
  }
}

.section__counter__personalStyle {
  width: 80%;
  margin: auto;
}

@media (max-width: 435px) {
  .section__counter__personalStyle {
    width: 95%;
  }
}

@media (min-width: 768px) {
  .counter-card-image-style {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .counter-card-image-style {
    position: relative;
  }
}
