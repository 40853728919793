$baseurl: "";
.detail-vocabulary-seminary-card {
	width: 340px;
	background: #ffffff;
	border-radius: 10px;
	height: 100%;
	min-height: 740px;

	.main-container {
		padding: 20px;
		height: 100%;
		display: flex;
		flex-direction: column;
		min-height: 740px;

		.header {
			display: flex;
			gap: 10px;
			margin-bottom: 20px;

			.image-container {
				min-width: 64px;
				height: 64px;
				border-radius: 100px;
				position: relative;
				overflow: hidden;

				img {
					position: absolute;
					height: 100%;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}

			.title-container {
				display: flex;
				flex-direction: column;
				justify-content: center;
				gap: 4px;

				h2 {
					font-size: 1.125rem !important;
					margin: 0;
					font-weight: bolder;
					color: #ca5050;

					span {
						font-family: initial;
					}
				}

				p {
					font-size: 0.875rem !important;
					margin: 0;
					font-weight: bolder;
				}
			}
		}

		.content-container {
			height: 600px;
			margin-bottom: 20px;
			overflow: scroll;

			.text-row {
				.searchtable {
					display: inline-grid;
					/* grid-template-columns: repeat(4, 1fr); */
					grid-template-columns: 5% 5% 40% auto;
					gap:10px;
					width: 100%;
					justify-items: center;
					font-size: 0.875rem;
					align-items: center;

					// @media screen and (max-width: 980px) {
					// 	grid-template-columns: auto auto;
					// }

					.difficult-color-circle {
						width: 0.875rem;
						height: 0.875rem;
						border-radius: 100%;
						aspect-ratio: 1/1;
					}
					p.cell-languages {
						margin: 0;
						width: 12px;
						img {
							width: 0.875rem;
							height: 0.875rem;
							aspect-ratio: 1/1;
						}
						justify-content: center;
					}
					span.dominated {
						color: #ca5050 !important;
					}
					span {
						width: 90px;
						text-align: center;
					}
					p{
						font-size: 0.875rem !important;
						margin: 0;
					}
					p.red{
						color: #ca5050 !important;
					}
					p.meaning-container {
						word-break: break-all;
						font-size: 0.875rem !important;
						margin: 0;
					}
				}
			}
			.detail-section-container {
				display: flex;
				flex-direction: column;
				gap: 10px;
				padding: 10px 0px;
				border-top: 1px solid rgba(0, 0, 0, 0.2);
				max-height: 525px;

				.subtitle-container {
					display: flex;
					justify-content: space-between;
					align-items: center;

					h3 {
						font-weight: 700 !important;
						font-size: 0.875rem !important;
						margin: 0;
					}

					img {
						width: 1rem;
						cursor: pointer;
					}

					img.active {
						transform: rotate(180deg);
					}
				}

				.words-container-main {
					overflow: scroll;
					.words-container {
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
						align-items: center;
						gap: 10px 0px;
						.text-row {
							width: 100%;
							height: fit-content;

							p {
								font-family: "Open Sans", sans-serif !important;
							}
						}
					}
				}

				.text-row {
					display: flex;
					align-items: center;
					justify-content: space-between;

					.difficult-color-circle {
						width: 0.5rem;
						height: 0.5rem;
						border-radius: 0.5rem;
					}

					p {
						margin: 0;
						font-size: 0.875rem !important;

						img {
							width: 0.875rem !important;
						}
					}

					.completed {
						color: #ca5050;
					}

					.detail:hover {
						text-decoration: underline;
						cursor: pointer;
					}
				}

				.detail-sub-menu {
					ul {
						display: flex;
						font-size: 0.875rem;
						gap: 10px;
						list-style: none;
						margin: 0;
						flex-wrap: wrap;

						li {
							cursor: pointer;
						}

						li:hover {
							text-decoration: underline;
						}

						.selected {
							color: #cd1719;
							font-weight: bolder;
							text-decoration: underline;
						}
					}
					.sorting {
						display: flex;
						justify-content: space-between;
						margin-top: 2%;

						.sorting-label {
							font-size: 0.95rem !important;
							margin: auto auto auto 0;
						}
						.dropdown-container {
							button {
								width: auto;
								height: auto;
								padding: 5px 10px;
								background: linear-gradient(to right, #ee1c34, #500b28);
								color: white;
								font-size: 0.875rem !important;
								font-weight: 500;
								//display:flex;
							}

							.dropdown-item {
								padding: 5px;
								display: flex;
								justify-content: space-between;
								align-items: center;
								img {
									width: 1.125rem!important;
									height: auto;
									aspect-ratio: 1;
								}
							}
						}
					}
					.select-lang-filter-container {
						label {
							font-size: 0.875rem;
							margin: 0;
							font-weight: bolder;
						}

						.select-container {
							width: 100%;

							select {
								width: 100%;
								padding: 2.5px 5px;
								border-radius: 10px;
							}
						}
					}
				}
			}

			.top-content {
				border-top: none;
			}

			.details {
				border-top: none;
				.text-row {
					p {
						color: #000000;
						display: flex;
						align-items: center;
						justify-content: space-between;
						flex-wrap: wrap;
						gap: 5px;
						span {
							word-break: keep-all;
						}
					}

					.gray {
						color: #00000080 !important;
					}

					.black {
						color: #000000 !important;
					}

					.dominated {
						color: #ca5050 !important;
					}
				}
			}
		}

		.go-back-container {
			margin-top: auto;
			p {
				margin: 0;
				font-size: 0.875rem !important;
				color: #ca5050;

				img {
					width: 0.875rem;
				}
			}
		}

		.footer {
			margin-top: auto;

			.title {
				display: flex;
				justify-content: space-between;
				align-items: center;

				h3 {
					font-size: 0.875rem !important;
					margin: 0;
				}
			}

			.bar-container {
				padding-top: 10px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 5px;

				p {
					font-size: 1rem !important;
				}

				* {
					margin: 0;
				}
				.words-progress-bar {
					background: #d9dadf;
					border-radius: 10px;
					width: 100%;
					height: 24px;
					overflow: hidden;

					.word-progress {
						background: linear-gradient(90.31deg, #ee1c34 0.26%, #500b28 99.73%);
						border-radius: 10px 0px 0px 10px;
						width: 80%;
						position: relative;
						height: 100%;

						.text-progress {
							position: absolute;
							display: flex;
							justify-content: space-between;
							align-items: center;
							top: 50%;
							transform: translate(50%, -50%);
							right: 0;
							gap: 5px;

							p {
								margin: 0;
								font-size: 0.875rem !important;
								width: 30px;
							}

							.words-completed {
								color: white;
							}

							.line-divisor {
								align-self: flex-end;
								margin-top: auto;
								border: 1px solid black;
								height: 30px;
								margin-top: 6px;
							}
						}
					}
				}
			}
		}
	}
}
.circle-progress-flags-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;

	.circle-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		h2 {
			font-size: 0.875rem !important;
		}
		.progress-container {
			cursor: pointer;
			border-radius: 100px;
			width: 70px;
			position: relative;

			img {
				position: absolute;
				left: 50%;
				top: 50%;
				width: 60px !important;
				transform: translate(-50%, -50%);
			}
		}
	}
}
@keyframes fill {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(135deg);
	}
}

@media (max-width: 991px) {
	.detail-vocabulary-seminary-card{
		.main-container{
			.searchWord-container{
				display: flex !important;
				justify-content: center;
				input {
					background-color: white;
					border: 2px solid #646464;
					border-radius: 7px;
					padding: 0px 4.5px;
				}
			}
		}
	}
}