$baseurl: "";
.recordings-section-main-container {
    // width: calc(100% - 300px);
    * {
        margin: 0;
    }

    .recordings-section-select-order-container {
        display: flex;
        justify-content: flex-end;

        select {
            padding: 5px 10px;
            border-radius: 10px;
        }
    }

    .main-container {
        display: flex;
        flex-direction: column;
        gap: 40px;

        .module-container {
            width: 100%;
            ::-webkit-scrollbar {
                display: block;
            }
    
            ::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                background-color: #f5f5f5;
                border-radius: 10px;
            }
    
            ::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background: linear-gradient(to right, #ee1c34, #500b28);
            }
    
            .title-module-container {
                h2 {
                    color: #ca5050;
                }
            }
    
            .cards-container-relative {
                position: relative;
    
                .show-arrow.rigth-arrow {
                    background: linear-gradient(-90deg, #ffffff 0%, rgba(255, 255, 255, 0.66) 40%, rgba(35, 31, 32, 0) 100%);
                    height: 100%;
                    display: flex;
                    width: 80px;
                    align-items: center;
                    justify-content: flex-end;
                    position: absolute;
                }
                .show-arrow.left {
                    background: linear-gradient(-90deg, #ffffff 0%, rgba(255, 255, 255, 0.66) 40%, rgba(35, 31, 32, 0) 100%);
                    height: 100%;
                    display: flex;
                    width: 80px;
                    align-items: center;
                    justify-content: flex-end;
                }
    
                .left {
                    display: none;
                    cursor: pointer;
                    position: absolute;
                    z-index: 1;
                    top: 50%;
                    transform: rotate(-180deg) translateY(50%);
                }
                .rigth-arrow {
                    display: none;
                    cursor: pointer;
                    z-index: 1;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                }
                .cards-container {
                    position: relative;
                    display: flex;
                    gap: 15px;
                    scroll-behavior: smooth;
                    overflow-x: scroll;
                    padding-bottom: 10px;
                    .recording-card {
                        border-radius: 10px;
                        background: #ffffff;
                        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
                        width: 300px;
                        min-width: 300px;
                        position: relative;
                        overflow: hidden;
    
                        .seminary-image {
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            height: 100%;
                            aspect-ratio: 16/9;
                            transform: translate(-50%, -50%);
                        }
    
                        a {
                            text-decoration: none;
                        }
                        
                        .image-container {
                            width: 100%;
                            padding-top: 56.25%;
                            background-color: gray;
                            border-radius: 10px 10px 0px 0px;
                            position: relative;
                            overflow: hidden;
    
                            img {
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                height: 100%;
                                transform: translate(-50%, -50%);
                            }
                        }
    
    
                        .card-content {
                            padding: 10px 20px;
    
                            .title {
                                h2 {
                                    font-size: 1.25rem !important;
                                    font-weight: bolder !important;
                                    text-align: center;
                                    color: #ca5050;
                                }
                            }
    
                            .date {
                                p {
                                    font-size: 0.875rem !important;
                                    text-align: center;
                                    color: black;
                                }
                            }
                        }
    
                        .footer {
                            border-top: 1px solid #00000052;
    
                            p {
                                font-size: 0.875rem !important;
                                color: #ca5050;
                                text-align: center;
                                padding: 5px;
                            }
                        }
    
                        .block-seminary {
                            position: absolute;
                            background: #00000099;
                            border-radius: 10px;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            top: 0;
                            left: 0;
                            backdrop-filter: blur(3px);
                            /* apply the blur */
    
                            p {
                                color: #ffffff;
                                font-size: 0.875rem !important;
                            }
    
                            img {
                                width: 64px;
                            }
                        }
                    }
                    .highlight {
                        border: 3px solid #ca5050; /* Cambia el borde a rojo */
                    }
                    
                }
            }
        }

    }
}

// @media (max-width: 644px) {
//     .recordings-section-main-container {
//         .main-container {
//             .cards-container {
//                 justify-content: center;
//             }
//         }
//     }
// }
