$baseurl: "";
.consult-text-editor-container {
    margin: auto;
    position: relative;

    .dropdown-lang-select {
        position: absolute;
        right: 12px;
        top: 7px;
        padding: 5px 10px;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        background: linear-gradient(90deg, #ee1c34 0.26%, #500b28 99.73%);;

        p {
            margin: 0;
            font-size: 1rem !important;
            color: #ffffff;
        }

        img {
            transform: rotate(0deg);
            transition: 0.5s ease-in;
            filter: invert(100%) sepia(95%) saturate(21%) hue-rotate(313deg) brightness(106%) contrast(104%);
        }
    }

    .dropdown-lang-checkbox-container {
        position: absolute;
        display: flex;
        flex-direction: column;
        gap: 8px;
        background: #ffffff;
        right: 10px;
        top: -300px;
        border-radius: 10px;
        padding: 20px;
        box-shadow: 0px 4px 20px #00000040;
        max-height: 0px;
        padding: 0px 20px;
        overflow: hidden;
        z-index: 1020;
        transition: 0.5s ease all;

        .checkbox-row {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 5px;

            label {
                margin: 0;
            }
        }
    }

    .down {
        top: 54px;
        z-index: 1020;
    }

    .consult-send-btn {
        position: absolute;
        bottom: 10px;
        right: 10px;
        padding: 0;
        font-size: 1rem !important;
        padding: 5px 15px;
        height: auto;
        color: white;
        background: linear-gradient(90.31deg, #ee1c34 0.26%, #500b28 99.73%);
        border-radius: 5px !important;
        z-index: 10;
    }

    .dropdown-lang-select-open {
        img {
            transform: rotate(180deg);
            transition: 0.5s ease all;
        }
    }

    .dropdown-lang-checkbox-container-open {
        max-height: 1000px;
        padding: 20px;
        transition: 0.5s ease all;
    }
    .quill {
        border-radius: 10px;

        .ql-toolbar {
            border-radius: 10px 10px 0px 0px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            background-color: rgba(255, 0, 0, 0.4);
        }

        .ql-container {
            // border-radius: 0px 0px 10px 10px;
            height: 250px;
            padding-bottom: 50px;
        }
    }
}

.hashtag-creator-container {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 10px;
    flex-wrap: wrap;

    .hashtags-container {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .hashtag {
            border-radius: 100px;
            border: 1px solid #ee1c34;
            color: #ee1c34;
            padding: 5px 10px;
            cursor: pointer;
            position: relative;

            .delete-hashtag {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                opacity: 0;
                font-size: 1.25rem;
            }
        }

        .hashtag:hover {
            background-color: #ee1c34;
            color: white;

            .hashtag-text {
                opacity: 0;
            }

            .delete-hashtag {
                opacity: 1;
            }
        }
    }

    .hashtag-input-container {
        height: 100%;
        border-radius: 10px;
        border: 1px solid #00000040;
        display: flex;
        overflow: hidden;
        min-width: 150px;
        max-width: 185px;

        input {
            border: none;
            padding: 10px;
            outline: none;
            height: 34px;
        }
    }

    .hashtag-btn-create {
        button {
            height: 100%;
            font-size: 1rem !important;
            background: linear-gradient(45deg, #ef1e35, #530a28);
            color: white;
            padding: 5px 10px;
            height: 34px;
        }
    }
}

.hashtags-container-selection {
    display: flex;
    flex-wrap: wrap;

    .checkbox-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
        width: 120px;

        label {
            margin: 0;
        }
    }
}

.hashtags-container-selection {
    gap: 10px;
    margin-bottom: 20px;

    .header {
        width: 100%;

        h2 {
            font-size: 1rem !important;
            margin: 0;
        }
    }

    .hashtag {
        border: 1px solid #ca5050;
        border-radius: 100px;
        color: #ca5050;
        font-size: 0.875rem;
        padding: 0px 5px;
        cursor: pointer;
    }

    .active {
        background-color: #ca5050;
        color: white;
    }
}

.consult-text-editor-container {
    .quill {
        .ql-toolbar {
            .ql-font {
                span[data-value="cirilico"]::before {
                    font-family: "Open Sans";
                    content: "Open Sans";
                }
            }
        }
    }
}
.pre {
    white-space: pre-wrap !important;
    font-family: "Open Sans" !important;
}

.ql-size-small {
    font-size: 1rem !important;
}

.ql-size-normal {
    font-size: 1.25rem !important;
}

.ql-size-large {
    font-size: 1.5rem !important;
}

.ql-size-huge {
    font-size: 1.75rem !important;
}

.ql-font-cirilico {
    font-family: "Open sans";
}

@media (max-width: 1185px) {
    .consult-text-editor-container {
        .dropdown-lang-select {
            left: 50%;
            right: auto;
            top: 57px;
            transform: translate(-50%, 0px);
        }

        .down {
            top: 104px;
            left: 50%;
            right: auto;
            transform: translate(-50%, 0px);
        }

        .quill {
            .ql-toolbar {
                height: 100px;
                align-items: flex-start;
                justify-content: center;
                flex-wrap: wrap;
            }
        }
    }
}

@media (max-width: 709px) {
    .consult-text-editor-container {
        .dropdown-lang-select {
            top: 107px;
            width: 80%;
        }

        .down {
            top: 154px;
        }

        .quill {
            .ql-toolbar {
                height: 150px;
            }

            .ql-container {
                // border-radius: 0px 0px 10px 10px;
                height: 500px;
            }
        }

        .answers-editor {
            .quill {
                .ql-toolbar {
                    max-height: 150px;
                    height: auto;
                }
            }
        }
    }
}
@media (max-width: 398px) {
    .consult-text-editor-container {
        .dropdown-lang-select {
            top: 140px;
            width: 80%;
        }


        .quill {
            .ql-toolbar {
                height: 180px;
            }

            .ql-container {
                // border-radius: 0px 0px 10px 10px;
                height: 500px;
            }
        }
    }
}
