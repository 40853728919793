$baseurl: "";
@import 'theme/colors', 'theme/variables';

.module__container {
  h4 {
    color: $color-secondary;
    font-size: 24px !important;
  }
  p {
    margin: $spacing 0;
    color: $color-black;
  }
  ul {
    padding: $spacing;
  }
  .first__column-b {
    margin-left: $spacing * 1.2;
  }
}
