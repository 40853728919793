$baseurl: "";
.vocabulary-languages-card{
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;

    .title {
        display: flex;
        flex-direction: column;

        h2 {
            font-size: 1.375rem!important;
            margin: 0;
            font-weight: bolder!important;
            color: #ca5050;
        }

        h3 {
            font-size: 1rem!important;
            font-weight: 500!important;
        }
    }

    .action {
        button {
            border: 1px solid #ca5050;
            border-radius: 10px;
            color: #ca5050;
            background-color: #ffffff;
            padding: 10px 20px;
            height: auto;
        }
    }
}