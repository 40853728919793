.custom-modal {
	width: 80%;
	border-radius: 10px;
}

.custom-modal .modal-content {
	border-radius: 10px;
}

.custom-modal p {
	width: 434px;
	height: 48px;
	left: 20px;
	top: 95px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #000000;
}
@media (max-width: 600px) {
	.custom-modal p  {
		width: 250px;
	}
  }
  
.form-modal {
	border: 1px solid rgba(0, 0, 0, 0.3);
	border-radius: 30px;
}

.custom-modal .form-group {
	margin-bottom: 15px;
	margin-left: 8%;
	display: inline-table;
}

.custom-modal .form-group label {
	margin-bottom: 2px;
	width: 186px;
	height: 24px;
	left: 20px;
	top: 163px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
}

.span-collaborator {
	text-decoration-line: underline;
	color: #bc4e4e;
}

.label_activitie {
	color: #bc4e4e;
	font-weight: normal;
}

.image_class {
	height: auto;
	width: auto;
	transform: translateY(-15%);
}

.custom-modal .form-group .form-control {
	border-radius: 10px;
	width: 100%;
	height: 100%;
	padding: 10px;
}

.custom-modal .form-group .form-control:focus {
	box-shadow: none;
}

.label-dark {
	color: #000000;
}

.label-dark,
.fa {
	margin-right: 10px;
	font-weight: bold;
}

@media (max-width: 768px) {
	.label-dark {
		font-size: 14px;
	}
}

.seccions-modal-container {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.modal-body-scroll {
	flex-grow: 1;
	overflow-y: auto;
}

.user-profile-modal .header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.image-containerModal {
	display: flex;
	gap: 5%; /* Ajusta el espacio entre las imágenes para dispositivos móviles */
	margin-left: 25%; /* Ajusta el margen izquierdo para dispositivos móviles */
	margin-top: 15px;
	align-items: center;
}

.small-Text1 {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	color: #323232;
}
.small-Text2 {
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 52px;
	color: #6b6b6b;
}

.row > .col {
	padding: 0; /* Ajusta el espaciado entre las columnas según sea necesario */
}

.card_sessions {
	display: flex;
	justify-content: flex-start;
	margin-left: auto;
	margin-right: auto;
}

@media (max-width: 600px) {
	/* Estilos para dispositivos móviles con ancho máximo de 600px */
	.card_sessions {
		width: 100%; /* El ancho ocupa todo el ancho disponible */
		margin-top: 3%; /* Reducir el margen superior */
	}
}

.date-range-picker-dropdown {
	padding: 0;
	width: 600px; /* Ajusta el ancho según tus necesidades */
	max-height: 650px; /* Ajusta la altura máxima según tus necesidades */
	overflow-y: auto;
}

