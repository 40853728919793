$baseurl: "";
@import 'theme/colors', 'theme/variables';

.header__FAQ {
  padding: $spacing-2x 0 2px 0;
  background: $color-primary;
  color: $color-white !important;

  h1 {
    color: $color-white !important;
  }
  p {
    margin-top: $spacing-2x;
  }
}

.wave_container {
  position: relative;
  img {
    top: -16px;
    height: 140px;
  }
}

.section__FAQ {
  margin-top: $spacing-4x * 1.5 !important;
  margin-bottom: $spacing-4x !important;
}

@media screen and (max-width: 414px) {
  .wave_container {
    img {
      height: 110px;
    }
  }
}
