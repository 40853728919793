$baseurl: "";
@import "@theme/_colors.scss";
@import "@theme/mixin.scss";

nav.navbar-main-container {
	position: sticky;
	z-index: 1000;
	display: flex;
	top: 0px;
	background-color: #ffffff;

	.user-nav-icon {
		display: none;
	}

	.logo-container {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0px 70px;
		max-width: 200px;
	}

	.logo-container-no-user {
		max-width: 120px;
	}

	.flag-logo-no-user {
		width: 63px;
		height: 49px;
		object-fit: contain;
	}

	.notifications-container {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 17px;
		margin-left: auto;
		cursor: pointer;

		.number-icon {
			position: absolute;
			background-color: white;
			width: 20px;
			height: 20px;
			border-radius: 100px;
			border: 2px solid #500b28;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 0.75rem;
			color: #500b28;
			transform: translate(7px, -6px);
		}
	}

	.menus-container {
		flex-grow: 1;

		.contact-container {
			display: flex;
			justify-content: flex-end;
			padding: 0px 0px 0px 70px;

			.phones-container {
				display: flex;
				justify-content: center;
				align-items: center;

				> div {
					padding: 0px 20px;
				}
				.phone-number {
					border-right: 2px solid red;
					font-size: 1rem;
					line-height: 1rem;
					color: #7e7e7e;
				}

				.whatsapp {
					svg {
						cursor: pointer;
					}
				}
			}

			.register-btn {
				border-radius: 0px !important;
				background-color: #eef4f9;
				font-size: 1rem !important;
				padding: 25px 30px;
				text-decoration: none;
				color: #000000;
				font-weight: bolder;
			}
		}

		.contact-container-no-generation {
			display: none;
		}
	}

	ul {
		list-style: none;
		margin: 0px;
		padding: 0px;
		display: flex;
		justify-content: center;
		width: 100%;
		margin-left: 0px;
		background-color: $primary-color;

		.white-container {
			height: 54px;
			width: 8px;
			margin-right: auto;
			margin-left: 8px;
			background-color: #ffffff;
		}

		.white-container-myaccount-admin {
			display: none;
		}
		a {
			//flex: 1;
			display: flex;
			text-decoration: none;
			display: flex;
			justify-content: center;
			align-items: center;
			&:hover {
				background-color: $primary-color-hover;
			}
			li {
				color: #ffffff;
				width: 100%;
				text-align: center;
				font-weight: bolder;
				vertical-align: middle;
				white-space: nowrap;
			}

			&.active {
				background-color: $primary-color-hover;
			}

			&.auth {
				background-color: #001036;
				max-width: fit-content;
				padding: 0px 20px;
			}
			&.normal-home-container {
				flex: 2; /* Ajustar el ancho según sea necesario */
			}
		}
		.home-container {
			width: 96px; /* Ajustar el ancho según sea necesario */
		}

		.notifications-container {
			padding: 0px 20px;
		}

		.responsive-auth-options {
			display: none;
		}
	}

	.notifications-modal {
		max-width: 430px;
		position: fixed;

		max-height: 0px;
		background-color: white;
		z-index: 200;

		right: 0px;
		box-shadow: 0px 4px 20px #00000040;
		transition: 0.5s ease all;
		width: 100%;
		min-width: 310px;
		.notifications-container-main-component > div > h1 {
			font-size: 2rem !important;
			padding: 10px 0 0 15px;
		}
		.notifications-container-main-component > .translate-input-container {
			padding-left: 15px;
		}
		.translate-input-container {
			display: flex;
			width: 100%;
			justify-content: flex-start;
			align-items: center;
			gap: 7px;

			.form-check {
				padding: 0;
			}

			input {
				display: none;
			}
		}
	}

	.notifications-modal-open {
		transition: 0.5s ease all;
	}

	.navbar-without-white-container {
		height: 54px;
	}

	.disable-scroll {
		overflow: hidden;
	}

	
	@include respond-to(md) {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		padding: 10px 24px;
		align-items: center;
		justify-content: center;

		.notifications-modal {
			height: calc(100% - 80px) !important;
			top: 80px !important;
		}

		.notifications-container {
			margin-left: 0%;
		}
		.navbar-left{
			display:flex;
		}

		.user-nav-icon {
			grid-column: 1;
			display: block;
			margin: auto 0;
		}

		.logo-container {
			grid-column: 2;
			width: 90px;
			height: 60px;
			padding: 0px;
			margin: auto;
			img {
				width: 100%;
			}
		}

		.menus-container {
			grid-column: 3;
			display: flex;
			justify-content: flex-end;
			.contact-container {
				display: none;
			}
			ul {
				padding-top: 80px;
				left: 0px;
				position: fixed;
				flex-direction: column;
				justify-content: center;
				background-color: #ffffff;
				z-index: 100;
				width: 100vw;
				height: calc(100% - 80px);
				gap: 20px;
				top: 0px;
				transform: translate(0, -100%);
				transition: 0.5s ease transform;

				&.open {
					transform: translate(0, calc(0% + 80px));
				}

				.white-container {
					display: none;
				}
				a {
					flex: none;
					li {
						color: #000000;
					}
					&:hover {
						background-color: transparent;
					}

					&.active {
						background-color: transparent;

						li {
							text-decoration: underline;
							color: #cf072c;
						}
					}

					&.auth {
						display: none;
					}
				}
				.responsive-auth-options {
					display: block;
					margin-top: auto;

					a {
						padding: 20px;
						width: 100%;
						font-family: "inter";
						font-weight: 600;

						&.login {
							color: #000000;
							background-color: #eef4f9;
						}

						&.sign-up {
							background-color: #cf072c;
							color: #ffffff;
						}
					}
				}
			}
		}
	}
}
