$baseurl: "";
.signup-main-container {
	display: flex;
	flex-direction: column;
}

.signup-header-container {
	position: absolute;
}

.signup-header-container h2 {
	color: white;
	font-weight: 700;
	top: 25px;
	left: 32px;
}

.signup-content-container {
	display: flex;
	height: 100%;
	justify-content: space-between;
	margin-top: 60px;
	margin-bottom: 20px;
}

.signup-form-container {
	width: 100%;
	height: 100%;
	width: 50%;
	box-shadow: 1px 1px 10px 4px rgba(0, 0, 0, 0.2);
	padding: 70px;
	padding-top: 130px;
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 730px;
}

.signup-form-container Form {
	width: 100%;
}

.signup-students-image-container {
	width: 50%;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	position: relative;
}

.signup-students-image-container img {
	width: 100%;
	max-width: 480px;
	position: absolute;
	bottom: -35px;
}

.signup-students-image-container .bigben-image {
	position: absolute;
	max-width: 100px;
	right: 120px;
	z-index: -1;
}

.signup-api-btn {
	width: 90%;
	padding: 0;
	max-width: 500px;
	margin-bottom: 1rem;
}

@media (max-width: 1100px) {
	.signup-content-container {
		margin-top: 30px;
		margin-bottom: 10px;
	}

	.signup-header-container h2{
		top: 5px;
	}

}

@media (max-width: 799px) {
	.signup-students-image-container {
		display: none;
	}

	.signup-header-container h2 {
		top: 60px;
		left: 30px;
		color: black;
	}

	.signup-content-container {
		margin-top: 20px;
		justify-content: center;
	}

	.signup-form-container {
		width: 100%;
	}

	.signup-form-container {
		padding-left: 30px;
		padding-right: 30px;
	}
}

@media (max-width: 425px) {
	.signup-header-container {
		top: 60px;
	}
}

@media (max-width: 375px) {
	.signup-header-container .wave-2-signup {
		margin-top: -17px!important;
	}
}
