$baseurl: "";
input[type="checkbox"] {
    accent-color: #ca5050;
}

.vocabulary-quiz-container-admin {
    display: flex;
    flex-direction: column;
    gap: 20px;
    select {
        background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
            no-repeat;
        background-position: calc(100% - 0.75rem) center !important;
        -moz-appearance: none !important;
        -webkit-appearance: none !important;
        appearance: none !important;
        padding-right: 2rem !important;
    }
    .title {
        h1 {
            font-size: 2.25rem !important;
            margin: 0;
        }
    }
    .right {
        align-self: flex-end;
    }
    .quiz-container {
        background: #ffffff;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 20px;
        h2 {
            font-weight: 600;
            font-size: 18px !important;
        }
        p {
            font-size: 14px !important;
        }

        .subView-container {
            display: flex;
            width: 300px;
            margin-left: auto;

            p {
                font-size: 0.875rem;
                margin: 0;
                text-align: center;
                color: #ca5050;
            }

            div {
                flex: 1;
                padding: 5px 10px;
                border: 1px solid #ca5050;
                cursor: pointer;
            }

            .subview-1 {
                border-right: 1px solid #ca5050;
                border-radius: 10px 0px 0px 10px;
            }
            .subview-2 {
                border-right: 1px solid #ca5050;
                border-radius: 0px 10px 10px 0px;
            }

            .active {
                background-color: #ca5050;

                p {
                    color: white;
                }
            }
        }

        .test-subview-2-categories-cards-container {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            justify-content: center;
            gap: 2%;

            .category-card {
                width: 23%;
                border-radius: 10px;
                box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
                min-width: 250px;
                margin-bottom: 20px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                border: 2px solid #ca5050;

                .header {
                    padding: 10px 20px;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    gap: 20px;
                    border-bottom: 2px solid #ca5050;

                    * {
                        margin: 0;
                    }

                    .number-of-words-category {
                        position: absolute;
                        right: 10px;
                        top: 5px;

                        color: #007cff;
                    }

                    .title {
                        width: 100%;
                        display: flex;
                        justify-content: center;

                        h2 {
                            font-size: 1.5rem;
                            color: #ca5050;
                        }
                    }

                    .category-progress-bar-container {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        gap: 10px;

                        p {
                            margin: 0;
                            font-size: 0.875rem !important;
                            font-weight: 400 !important;
                        }

                        .category-progress-bar {
                            background: #ffffff;
                            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
                            border-radius: 100px;
                            height: 10px;
                            flex: 1;

                            .category-progress {
                                background: #007cff;
                                border-radius: 100px;
                                height: 100%;
                                width: 50%;
                            }
                        }
                    }
                }

                .category-card-subcat-selector-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0px 20px;
                    gap: 5px;

                    label {
                        margin: 0;
                        font-size: 0.875rem;
                    }

                    select {
                        padding: 2px 10px;
                        border-radius: 10px;
                    }
                }

                .category-words-container {
                    * {
                        margin: 0;
                    }

                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    padding: 10px 20px;
                    overflow: scroll;
                    height: 300px;

                    .category-selector-container {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        label,
                        input {
                            cursor: pointer;
                        }

                        label {
                            padding-left: 7px;
                            flex: 1;
                        }
                    }
                }
            }
        }

        .test-row {
            .name-input-container {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50%;
                max-width: 400px;
                min-width: 250px;

                input {
                    flex: 1;
                    border: none;
                }
            }
        }
        .languages-container {
            display: flex;
            justify-content: space-around;
            .language-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                .language-row {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }
            }
        }
        .second-section-quiz,
        .third-section-quiz {
            display: flex;
            flex-direction: column;
            gap: 20px;
            p {
                margin: 0;
            }
            .top {
                display: flex;
                justify-content: space-between;
            }
            .categories-container,
            .subcategories-container {
                display: flex;
                justify-content: center;
                gap: 30px;
                .category-row,
                .subcategory-row {
                    display: flex;
                    gap: 10px;
                    p {
                        margin: 0;
                    }
                }
            }
            .pagination-categories,
            .pagination-subcategories {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                p {
                    font-size: 14px !important;
                }
                img {
                    cursor: pointer;
                }
            }
        }
        .fourth-section-quiz {
            display: flex;
            flex-direction: column;
            .difficult-container {
                display: flex;
                gap: 60px;
                .difficult {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 15px;
                    div {
                        display: flex;
                        gap: 15px;
                        align-items: center;
                        label {
                            margin: 0;
                        }
                    }
                }
            }
        }
        .fifth-section-quiz {
            .options {
                display: flex;
                flex-direction: column;
                select {
                    border: 1px solid rgba(0, 0, 0, 0.3);
                    border-radius: 10px;
                    width: 300px;
                    padding: 9px 13px;
                }
            }
        }
    }
    .hide {
        display: none !important;
    }
    .admin-quiz-btn {
        align-self: center;
        color: white;
        background: linear-gradient(90.31deg, #ee1c34 0.26%, #500b28 99.73%);
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
    }
    .counter-container {
        display: flex;
        flex-direction: column;
        .counter {
            display: flex;
            align-items: center;
            align-self: center;
            img {
                cursor: pointer;
            }
            input {
                border: 1px solid rgba(0, 0, 0, 0.3);
                border-radius: 10px;
                width: 98px;
                height: 73px;
                font-family: "Lato", sans !important;
                font-weight: 700;
                font-size: 60px;
                text-align: center;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }
}
.modal-vocabulary-admin-quiz,
.modal-vocabulary-admin-quiz-words {
    .modal-dialog {
        max-width: 700px;
        max-height: 500px;
        .modal-content {
            border-radius: 10px;
            .modal-body {
                .word-quiz-container {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 5%;
                    overflow: scroll;
                    max-height: 600px;

                    .word-quiz-input {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        gap: 5px;
                        flex: 0 30%;
                        min-width: 150px;
                        width: 200px;
                        max-width: 200px;
                        padding: 0px 10px;

                        p {
                            margin: 0;
                        }
                    }

                    .bg-1 {
                        background-color: white;
                    }

                    .bg-2 {
                        background-color: rgba(0, 0, 0, 0.15);
                    }
                }
            }
            .modal-footer {
                .buttons {
                    display: flex;
                    gap: 20px;
                    .close {
                        border: 1px solid #ca5050;
                        border-radius: 10px !important;
                        padding-inline: 18px;
                        color: #bc4e4e;
                        font-size: 16px !important;
                    }
                    .continue {
                        background: linear-gradient(90.31deg, #ee1c34 0.26%, #500b28 99.73%);
                        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
                        border-radius: 10px !important;
                        color: white;
                        font-size: 16px !important;
                    }
                }
            }
        }
    }
}
