$baseurl: "";
@import "@theme/_variables.scss";

.vocabulary-module-card {
  border-radius: 10px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2509803922);

  .main-card-container {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;

    .info-container {
      padding: 15px 0px;
      grid-column: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 15px;

      * {
        margin: 0;
      }

      h2 {
        color: ($color-primary);
        text-align: center;
      }

      h3 {
        font-size: 1.25rem;
        font-weight: 400;
        font-family: "Times New Roman", Times, serif;
      }

      p {
        font-size: 1.25rem;
        margin-top: 15px;
      }
    }

    .progress-container {
      grid-column: 3;
      display: flex;
      justify-content: end;
      padding: 0px 15px;

      > div {
        position: relative;
        flex: 1;

        h3.progress-label {
          position: absolute;
          font-size: 15px;
          color: ($color-primary);
          text-align: center;
          width: 100%;
          transform: translate(0%, -100%);
        }
      }
    }
  }

  .seminaries-cards-container {
    // grid column span for 3 columns
    height: auto;
    padding: 15px;
    padding-top: 20px;
    grid-column: 1 / span 3;
    transition: height 0.5s ease;
    overflow: hidden;
  }

  &.closed {
    .seminaries-cards-container {
      min-height: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }

  &.open {
    // I want to animate the height of the container with a transition and dynamic height, because items inside the container are dynamic
    .seminaries-cards-container {
      padding-top: 20px;
    }
  }
}

@keyframes noPadding {
  0% {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  50% {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  100% {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
