$baseurl: "";
.streak-container {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	cursor: pointer;

	.streak-icon {
		width: 3rem;
	}

	h2 {
		position: absolute;
		margin: 0;
		left: 50%;
		bottom: 0%;
		transform: translate(-40%, -20%);
		font-size: 1.25rem;
	}
}
.vocabulary-main-container {
	.header-container {
		.title-and-time {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			flex-wrap: wrap;
			.time-container {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-items: center;

				h3 {
					margin: 0;
					font-size: 1rem !important;
					display: flex;
					align-items: center;
					justify-content: center;

					img {
						width: 24px;
						height: 24px;
					}
				}

				p {
					font-size: 0.75rem !important;
					margin: 0;
					color: #ca5050;
				}
			}
		}
	}
	.last-quizzes-main-container {
		.header {
			h2 {
				color: #ca5050;
				text-align: center;
			}
		}
	}

	.vocabulary-modules-container {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	.vocabulary-seminary-cards-container {
		border-radius: 10px;
		box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2509803922);
		display: flex;
		flex-direction: column;
		padding: 13px;
		.infinite-scroll-container {
			overflow: initial !important;
		}
		h2 {
			color: #ca5050;
			text-align: center;
		}

		.arrow-icon {
			margin-left: auto;
			transform: rotate(180deg);
			transition: 0.25s ease all;
		}
		.container-info-modules {
			display: flex;
			flex-direction: row;
			min-height: 100%;

			.container-info-modules > div {
				flex: 1;
				display: flex;
			}
		}
	}
}

@media (max-width: 991px) {
	.vocabulary-seminary-main-container {
		.header-container {
			.title-and-time {
				margin-bottom: 20px;

				.time-container {
					align-items: center;

					h3 {
						gap: 6px;
					}
				}
			}
		}
	}
	.Info-card-modules {
		justify-content: flex-start !important;
		align-items: flex-start !important;
	}
}

@media (max-width: 500px) {
	.vocabulary-main-container {
		.last-quizzes-main-container {
			.header {
				h2 {
					font-size: 1.5rem;
				}
			}
		}
	}
	
}
