$baseurl: "";
.see-words-corrects-incorrects-modal-test {

    .modal-dialog {

        .modal-content {

            .modal-body {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                flex-direction: column;

            }

            .modal-footer {
                justify-content: center;
            }
        }
    }
}